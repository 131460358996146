/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationType {
  id: number;
  description: string;
  user_id: number;
  seen: boolean;
  navigation_route: string;
  created_on: string;
  updated_at: string;
}

export interface NotificationsSliceState {
  notifications: NotificationType[];
}

const notificationsInitialState: NotificationsSliceState = {
  notifications: [],
};

const notifications = createSlice({
  name: "notifications",
  initialState: notificationsInitialState,
  reducers: {
    addNotifications(state, { payload }: PayloadAction<NotificationType[]>) {
      state.notifications = payload;
    },
    removeNotification(
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== id
      );
    },
    removeNotifications(state) {
      state.notifications = [];
    },
  },
});

export const { addNotifications, removeNotification, removeNotifications } = notifications.actions;

export default notifications.reducer;
