/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "./auth";

export interface UsersSliceState {
    users: User[];
}

const authInitialState: UsersSliceState = {
    users: [],
};

const users = createSlice({
    name: "users",
    initialState: authInitialState,
    reducers: {
        addUsersProfiles(state, { payload }: PayloadAction<User[]>) {
            state.users = payload;
        },
    },
});

export const { addUsersProfiles } = users.actions;

export default users.reducer;
