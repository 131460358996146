import { useTranslation } from 'react-i18next';

import { Wrapper, Row, Column } from './styles';
import ButtonHighlight from 'components/common/buttonHighlit';

export const BoardItem = ({ item, onClick }) => {
  const { name } = item;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row>
        <Column>{t("kanban-boards-screen.item.title")}:</Column>
        <Column className="center">{name}</Column>
      </Row>
      <Row>
        <Column>
          <Row>
            <ButtonHighlight width={200} height={36} onClick={onClick} theme={'primaryButtonPlain'}>{t("kanban-boards-screen.item.button.label")}</ButtonHighlight>
          </Row>
        </Column>
      </Row>
    </Wrapper>
  );
};
