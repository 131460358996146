import { darken, lighten } from "polished";

export const DarkTheme = ({
    primary_color,
    secondary_color,
    affirmative_color,
    attention_color,
    error_color,
}: {
    primary_color?: string;
    secondary_color?: string;
    affirmative_color?: string;
    attention_color?: string;
    error_color?: string;
}) => ({
    palette: {
        primary: "#1b1b1b",
        secondary: secondary_color || "rgba(255, 255, 255, 0.12)",
        error: error_color ? lighten(0.1, error_color) : "#CF6679",
        subtitle: "rgba(255, 255, 255, 0.6)",
        button: primary_color || "#1E8EE7",
        buttonDisabled: "rgba(255, 255, 255, 0.38)",
        secondaryButton: affirmative_color || "rgb(60, 168, 118)",
        green: affirmative_color || "#3CA876",
        link: "rgba(255, 255, 255, 0.6)",
        text: "rgba(255, 255, 255, 0.87)",
        white: "rgba(255, 255, 255, 1)",
        border: "#808080",
        input: "#373737",
        modalProfileHighlight: "#242424",
        primarySoft: "#2f2f2f",
        primarySoftHover: "#383838",
        primaryHighlight: "#222222",
        menuItem: "rgba(240, 240, 240, 1)",
        darkBlack: "rgba(0, 0, 0, 0.4)",
        menu: "#1e1e1e",
        red: error_color || "rgb(255, 0, 0)",
        darkRed: error_color ? darken(0.1, error_color) : "rgb(255, 0, 0, 0.6)",
        menuBorder: "#363636",
        darkerBlack: "rgba(0, 0, 0, 1)",
        modalProfile: "#2b2b2b",
        black: "#222222",
        hover: "#2D2D2D",
        table: "#2B2B2B",
        boxShadow: "0 0 30px 0 rgba(0, 0, 0, 1)",
        darkGrey: "#454545",
        lightGrey: "#e5e7eb",
        primaryButton: {
            background: primary_color || "#1E8EE7",
            borderRadius: "10px",
        },
        primaryButtonDark: {
            background: primary_color ? darken(0.1, primary_color) : "#0A4F86",
            borderRadius: "10px",
        },
        primaryButtonPlain: {
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212",
            borderRadius: "10px",
            shadow: "linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212",
        },
    },
    typography: {
        normal: {
            inter: "Inter",
        },
        semiBold: {
            inter: "Inter SemiBold",
        },
    },
});

export const LigthTheme = ({
    primary_color,
    secondary_color,
    affirmative_color,
    attention_color,
    error_color,
}: {
    primary_color?: string;
    secondary_color?: string;
    affirmative_color?: string;
    attention_color?: string;
    error_color?: string;
}) => ({
    palette: {
        primary: "#f4f4f4",
        primaryHighlight: "#FFFFFF",
        secondary: secondary_color || "#FFFFFF",
        error: error_color ? lighten(0.1, error_color) : "#CF6679",
        red: error_color || "rgb(255, 0, 0)",
        darkRed: error_color ? darken(0.1, error_color) : "rgb(255, 0, 0, 0.6)",
        subtitle: "rgba(34, 34, 34, 0.6)",
        button: primary_color || "#1E8EE7",
        buttonDisabled: "rgb(212 212 212)",
        secondaryButton: affirmative_color || "rgb(60, 168, 118)",
        green: affirmative_color || "#3CA876",
        link: "rgba(34, 34, 34, 0.6)",
        text: "#222222",
        white: "rgba(255, 255, 255, 1)",
        border: "#c7c7c7",
        input: "#f4f4f4",
        primarySoft: "#efefef",
        primarySoftHover: "#dbdbdb",
        menu: "#FFFFFF",
        menuBorder: "#363636",
        black: "#222222",
        hover: "#efefef",
        table: "#2B2B2B",
        modalProfile: "#FFF",
        modalProfileHighlight: "rgba(33, 33, 33, 0.07)",
        menuItem: "rgba(240, 240, 240, 1)",
        darkBlack: "rgba(0, 0, 0, 0.4)",
        darkerBlack: "rgba(0, 0, 0, 1)",
        boxShadow: "0 0 30px 0 rgba(0, 0, 0, 1)",
        darkGrey: "#454545",
        lightGrey: "#e5e7eb",
        primaryButton: {
            background: primary_color || "#1E8EE7",
            borderRadius: "10px",
        },
        primaryButtonDark: {
            background: primary_color ? darken(0.1, primary_color) : "#0A4F86",
            borderRadius: "10px",
        },
        primaryButtonPlain: {
            background: "#FFFFFF",
            borderRadius: "10px",
            shadow: "0 0 0 1px #c7c7c7",
        },
    },
    typography: {
        normal: {
            inter: "Inter",
        },
        semiBold: {
            inter: "Inter SemiBold",
        },
    },
});
