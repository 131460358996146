import styled from "styled-components";

export const Background = styled.div`
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px 0px;
`;
