import styled from 'styled-components';

export const Image = styled.img`
    margin-right: 6px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: ${({theme}) => theme.palette.white};
    padding: 10px 56px;
    border: .5px solid ${({theme}) => theme.palette.border};
    border-radius: 8px;
    cursor: pointer;
    margin-top: 26px;
`;

export const Text = styled.div`
    color: ${({theme}) => theme.palette.text};
    font-size: 16px;
    font-family: ${({theme}) => theme.typography.normal.inter};
    font-weight: 500;
`;