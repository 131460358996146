import { useTranslation } from "react-i18next";

import { Text, Container, Input, Label, Textarea } from "./styles";

export const InputComponent = ({
    label,
    value,
    handleChange,
    type,
    required,
    multiline = false,
    placeholder,
    styles,
    disabled,
    customStyle,
}: {
    label?: string;
    value: string;
    required?: boolean;
    type?: string;
    multiline?: boolean;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    placeholder?: string;
    styles?: any;
    disabled?: boolean;
    customStyle?: React.CSSProperties;
}) => {
    const { t } = useTranslation();

    return (
        <Container styles={styles}>
            {label && <Text>{label}</Text>}
            {required && <Label multiline>{t("button.required")}</Label>}
            {multiline ? (
                <Textarea
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                />
            ) : (
                <Input
                    type={type}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    placeholder={placeholder}
                    style={customStyle}
                />
            )}
        </Container>
    );
};
