import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Module } from "pages/Dashboard/TrainingModules";
import {
  Text,
  Date,
  Title,
  TitleContainer,
  Wrapper,
  Link,
  Circle,
  Row,
  Footer,
  AvatarRow,
  Pointer,
} from "./styles";
import { UserDark, UserIcon } from "assets";
import { formatDateTime } from "utils";
import { Avatar } from "components/Avatar";
import ButtonHighlight from "components/common/buttonHighlit";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

interface CardParams {
  module: Module;
  onEditClick: () => void;
  onArchiveClick: () => void;
}

export const RequiredTrainingCard = ({
  module,
  onEditClick,
  onArchiveClick,
}: CardParams) => {
  const { t } = useTranslation();
  const { app } = useSelector((state: RootState) => state.app);
  const history = useHistory();

  const handleClick = () => {
    window.open(module.url, "_blank");
  };

  const handleNavigation = () =>
    history.push(`/manage/trainings/${module.id}/module`);

  return (
    <Wrapper isActive={module.is_active}>
      <TitleContainer onClick={handleNavigation}>
        <div>
          <Title>{module.title}</Title>
          <Text>{module.description}</Text>
        </div>
        <div>
          <Row>
            <Circle isActive={module.is_active} />
            <Text status>
              {module.is_active
                ? t("required-training-screen.active")
                : t("required-training-screen.archived")}
            </Text>
          </Row>
          <Date>{`${t("required-training-screen.posted")} ${formatDateTime(
            module.created_at
          )}`}</Date>
          {module.archived_on && (
            <Date>{`${t(
              "required-training-screen.archived-date"
            )} ${formatDateTime(module.archived_on)}`}</Date>
          )}
        </div>
      </TitleContainer>
      <br />
      <Pointer onClick={handleNavigation}>
        <Text small>
          {t("required-training-screen.link")}
          <Link onClick={handleClick}>{module.url}</Link>
        </Text>
        <Text small>{t("required-training-screen.completed")}</Text>
      </Pointer>
      <Footer>
        <AvatarRow onClick={handleNavigation}>
          {module.completed_by?.map((user) => (
            <Avatar
              key={user.id}
              src={user.profile_image || app.darkMode ? UserIcon : UserDark}
              name={`${user.first_name} ${user.last_name.charAt(0)}`}
            />
          ))}
        </AvatarRow>
        {module?.is_active && (
          <Row>
            <ButtonHighlight
              width={90}
              height={36}
              onClick={onEditClick}
              theme='primaryButtonPlain'
            >
              {t("required-training-screen.edit")}
            </ButtonHighlight>
            <ButtonHighlight
              width={90}
              height={36}
              onClick={onArchiveClick}
              theme='primaryButtonPlain'
            >
              {t("required-training-screen.archive")}
            </ButtonHighlight>
          </Row>
        )}
      </Footer>
    </Wrapper>
  );
};
