import { Inner, Wrapper } from "./styles";

export default function ButtonHighlight({
  height = 36,
  width,
  minWidth = 36,
  children,
  disabled,
  onClick,
  style,
  theme
}: {
  height?: number,
  width?: number,
  minWidth?: number,
  children?: any,
  underlayBackground?: string,
  foregroundColor?: string,
  disabled?: boolean,
  borderRadius?: string,
  onClick: () => void,
  alwaysHighlighted?: boolean,
  style?: any,
  theme: string
}) {
  return (
    <Wrapper
      disabled={disabled}
      height={height}
      width={width}
      minWidth={minWidth}
      onClick={!disabled ? onClick : undefined}
      style={style}
      themeField={theme}
    >
      <Inner>
        {children}
      </Inner>
    </Wrapper>
  );
}