import styled from "styled-components";

export const Background = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15%;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  font-size: 72px;
  color: ${({ theme }) => theme.palette.text};
`;

export const Subtitle = styled.div`
  font-size: 18px;
  margin-bottom: 32px;
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.subtitle};
`;
