import styled from "styled-components";
import MuiChatIcon from "@mui/icons-material/Chat";
import TableChartIcon from "@mui/icons-material/TableChart";
import { Avatar, IconButton, ListItemButton, Tab, Tabs } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1;
`;

export const ChatContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    max-height: 90vh;
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;
    max-width: calc(100vw - 250px);
`;

export const MessageContainer = styled.div`
    margin: 10px;
    padding: 8px;
    border-radius: 8px;
    align-self: flex-start;
    color: white;
`;

export const Text = styled.div`
    font-family: ${({ theme }) => theme.typography.normal.inter};
    font-size: 24px;
    color: ${({ theme }) => theme.palette.text};
    padding: 24px 0 24px 24px;
`;

export const MessageText = styled.div`
    word-wrap: break-word;
    padding-left: 36px;
    color: ${({ theme }) => theme.palette.text};
`;

export const MessageSender = styled.div`
    font-weight: 900;
    font-family: ${({ theme }) => theme.typography.semiBold.inter};
    margin-bottom: 8px;
    flex-direction: row;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text};
`;

export const ScrollButtonsContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    left: 50%;
    top: 110px;
`;

export const Title = styled.div`
    font-weight: 900;
    font-family: ${({ theme }) => theme.typography.semiBold.inter};
    margin-bottom: 8px;
    color: ${({ theme }) => theme.palette.text};
    font-size: 40px;
`;

export const ChatIcon = styled(MuiChatIcon)`
    && {
        width: 40px;
        height: 40px;
        color: ${({ theme }) => theme.palette.text};
        margin-right: 8px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
`;

export const MessageAvatar = styled(Avatar)`
    && {
        margin-right: 8px;
        background-color: ${({ theme }) => theme.palette.primarySoft};
        width: 24px;
        height: 24px;
        color: ${({ theme }) => theme.palette.text};
    }
`;

export const Sidebar = styled.div`
    border-right: 1px solid ${({ theme }) => theme.palette.text};
    display: flex;
    flex: 1;
    height: 100%;
    max-width: 250px;
    min-width: 250px;
    margin-top: 80px;
    flex-direction: column;
`;

export const AddIconButton = styled(IconButton)`
    && {
        border: 1px solid ${({ theme }) => theme.palette.text};
        border-radius: 100px;
        height: 32px;
        width: 32px;
        margin-top: 24px;
        margin-right: 16px;
        :hover {
            background-color: ${({ theme }) => theme.palette.white};
        }
    }
`;

export const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.palette.text};
`;

export const ChartIcon = styled(TableChartIcon)`
    && {
        color: ${({ theme }) => theme.palette.text};
        margin-right: 8px;
    }
`;

export const SmallText = styled.div`
    font-family: ${({ theme }) => theme.typography.normal.inter};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text};
`;

export const ItemButton = styled(ListItemButton)<{
    darkMode?: boolean;
}>`
    &.MuiListItemButton-root {
        &.Mui-selected {
            background-color: ${({ theme, darkMode }) =>
                darkMode ? theme.palette.darkGrey : theme.palette.darkBlack};
            :hover {
                background-color: ${({ theme, darkMode }) =>
                    darkMode
                        ? theme.palette.darkGrey
                        : theme.palette.darkBlack};
            }
        }
        :hover {
            background-color: ${({ theme, darkMode }) =>
                darkMode ? theme.palette.darkGrey : theme.palette.darkBlack};
        }
        :focus {
            background-color: ${({ theme, darkMode }) =>
                darkMode ? theme.palette.darkGrey : theme.palette.darkBlack};
        }
    }
`;

export const StyledTab = styled(Tab)`
    &.MuiButtonBase-root {
        &.MuiTab-root.Mui-selected {
            color: ${({ theme }) => theme.palette.white};
        }
        color: ${({ theme }) => theme.palette.white};
    }
`;

export const StyledTabs = styled(Tabs)`
    & .MuiTabs-indicator {
        background-color: ${({ theme }) => theme.palette.white};
    }
    margin-top: 32px;
    width: max-content;
    background-color: ${({ theme }) => theme.palette.darkBlack};
`;

export const StyledDiv = styled.div`
`

export const TableContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
`;

export const AddIconStyled = styled(AddIcon)`
    && {
        color: ${({ theme }) => theme.palette.black};
    }
`;

export const ChatbotIcon = styled(AutoAwesomeIcon)`
    && {
        color: ${({ theme }) => theme.palette.text};
        font-size: 18px;
    }
`;

export const LoaderContainer = styled.div`
    margin: 16px;
    padding: 8px;
    display: flex;
    justify-content: center;
`;
