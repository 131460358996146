import { Text, Button, Icon, Contianer, ButtonIcon } from "./styles";

export const ButtonComponent = ({
  label,
  secondary,
  onClick,
  customStyle,
  disabled,
  icon,
  width,
  imageIcon,
  error,
  styles,
  success
}: {
  label: string;
  onClick: (e?: any) => void;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  secondary?: boolean;
  icon?: () => any;
  width?: string;
  imageIcon?: string;
  error?: boolean;
  styles?: any;
  success?: boolean;
}) => {
  return (
    <Contianer styles={styles}>
      {icon && (
        <ButtonIcon error={error} disabled={disabled} secondary={secondary}>
          {icon()}
        </ButtonIcon>
      )}
      {imageIcon && <Icon src={imageIcon} />}
      <Button
        error={error}
        hasIcon={!!icon}
        success={success}
        secondary={secondary}
        disabled={disabled}
        style={{ ...customStyle, width }}
        onClick={onClick}
      >
        <Text disabled={disabled}>{label}</Text>
      </Button>
    </Contianer>
  );
};
