import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CloseDiv,
  Form,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
} from "./styles";
import CloseSvg from "assets/svgs/CloseSvg";
import { Button, Input } from "components";
import PlusSvg from "assets/svgs/PlusSvg";
import { RootState } from "redux/reducers";

const BoardModalView = ({
  onSubmit,
  onClose,
}: {
  onSubmit: (name: string) => void;
  onClose: () => void;
}) => {
  const { app } = useSelector((state: RootState) => state.app);
  const [boardName, setBoardName] = useState<string>("");
  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(boardName);
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>{t("kanban-screen.modal.title")}</h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={app.darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <Input
            value={boardName}
            label={t("kanban-screen.modal.input")}
            handleChange={(e) => setBoardName(e.target.value)}
            required
            placeholder={`${t("kanban-screen.modal.input")}...`}
          />
          <Button
            disabled={!boardName?.trim()}
            width='230px'
            icon={() => (
              <PlusSvg
                fill={!boardName?.trim() || !app.darkMode ? undefined : "#222"}
              />
            )}
            onClick={handleSubmit}
            label={t("kanban-screen.modal.button")}
          />
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default BoardModalView;
