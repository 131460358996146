const SunSvg = ({ width = 21, height = 21, fill = "#CDA932", ...props }) => (
  <div style={{ width, height }}>
    <svg
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 21 21'
      {...props}
    >
      <g clipPath='url(#prefix__a)'>
        <path
          d='M10.96 14.64a4.167 4.167 0 100-8.333 4.167 4.167 0 000 8.333z'
          fill={fill}
        />
        <path
          d='M10.96 1.307v1.666m0 15.001v1.666M4.477 3.99L5.66 5.174m10.602 10.599l1.183 1.184M1.793 10.474H3.46m15 0h1.668M4.477 16.957l1.183-1.184M16.262 5.174l1.183-1.184'
          stroke={fill}
          strokeWidth={2}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='prefix__a'>
          <path fill='#fff' transform='translate(.96 .474)' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  </div>
);
export default SunSvg;
