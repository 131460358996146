import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import {
  Arrow,
  BadgeStyled,
  Button,
  Container,
  LeftContainer,
  RightContainer,
} from "./styles";
import MenuSvg from "assets/svgs/MenuSvg";
import { handleDrawer } from "redux/slices/app";
import { useApi } from "hooks/useApi";
import { signoutUser } from "api/auth";
import { ROUTES } from "utils/routes";
import { User } from "redux/slices/auth";
import NotificationsMenu from "../../notificationsMenu";
import { RootState } from "../../../redux/reducers";

export const Header = ({
  darkMode,
  drawerOpen,
  user,
}: {
  darkMode: boolean;
  drawerOpen: boolean;
  user: User;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { notifications } = useSelector(
    (state: RootState) => state.notifications
  );
  const { request }: any = useApi({
    apiFunc: signoutUser,
    onSuccess: () => history.push(ROUTES.login),
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container>
      <LeftContainer>
        <Button onClick={() => dispatch(handleDrawer(!drawerOpen))}>
          <MenuSvg fill={darkMode ? undefined : "#222"} />
        </Button>
      </LeftContainer>
      <RightContainer>
        <BadgeStyled
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={notifications.length}
          max={999}
        >
          <Button onClick={handleClick}>
            {`${t("header.welcome")}, ${user.role} ${user.first_name} |`}
          </Button>
        </BadgeStyled>
        {anchorEl && <Arrow />}

        <Button onClick={request}>{t("header.button")}</Button>
      </RightContainer>
      <NotificationsMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        notifications={notifications}
      />
    </Container>
  );
};
