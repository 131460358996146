/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Checkbox, MenuItem, SelectChangeEvent } from "@mui/material";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

import MainLayout from "../../layouts/main";
import {
    Cell,
    Container,
    HeaderText,
    Row,
    StyledSelect,
    Subtitle,
    Title,
    TableContainer,
    HeaderContainer,
} from "./styles";
import { ROUTES } from "../../utils/routes";
import { ButtonWithIconComponent } from "../../components/buttonWithIcon";
import { InputComponent } from "../../components/common/input";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers";

const ReviewTableStructure = () => {
    const {
        app: { darkMode },
    } = useSelector((state: RootState) => state.app);
    const { t } = useTranslation();
    const history = useHistory();
    // TODO: get from backend
    const [tableColumn, setTableColumn] = useState("");
    const [columnType, setColumnType] = useState("");

    const handleChange = (event: SelectChangeEvent) =>
        setColumnType(event.target.value as string);

    // TODO: change to backend data
    const mockData = [
        {
            column: "industry",
            sampleData: "Accounting/Finance",
            columnName: "industry",
            type: "TEXT",
            include: true,
        },
    ];

    return (
        <MainLayout
            styles={{
                width: "100%",
                padding: "48px",
                flexDirection: "column",
                alignItems: "center",
            }}
            showFabButton={false}
        >
            <HeaderContainer>
                <ButtonWithIconComponent
                    customStyle={{
                        width: "116px",
                        height: "40px",
                        backgroundColor: "rgba(38,38,38,.75)",
                        borderRadius: "6px",
                    }}
                    labelStyle={{
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "400",
                    }}
                    onClick={() => history.push(ROUTES.chat)}
                    label={t("review-table-structure.cancel")}
                    iconComponent={
                        <CloseIcon
                            style={{ color: "white", marginRight: "4px" }}
                        />
                    }
                />
            </HeaderContainer>
            <Container>
                <Title>{t("review-table-structure.title")}</Title>
                <Row>
                    <Subtitle>{t("review-table-structure.subtitle")}</Subtitle>
                    <ButtonWithIconComponent
                        customStyle={{
                            width: "102px",
                            height: "40px",
                            backgroundColor: darkMode ? "white" : "black",
                            borderRadius: "6px",
                        }}
                        labelStyle={{
                            color: darkMode ? "black" : "white",
                            fontSize: "12px",
                        }}
                        onClick={() => {}}
                        label={t("review-table-structure.button-label")}
                    />
                </Row>

                <TableContainer>
                    <Row>
                        <Cell>
                            <HeaderText>
                                {t("review-table-structure.column")}
                            </HeaderText>
                        </Cell>
                        <Cell>
                            <HeaderText>
                                {t("review-table-structure.sample-data")}
                            </HeaderText>
                        </Cell>
                        <Cell>
                            <HeaderText>
                                {t("review-table-structure.column-name")}
                            </HeaderText>
                        </Cell>
                        <Cell>
                            <HeaderText>
                                {t("review-table-structure.type")}
                            </HeaderText>
                        </Cell>
                        <Cell>
                            <HeaderText>
                                {t("review-table-structure.include")}
                            </HeaderText>
                        </Cell>
                    </Row>
                    {mockData.map(({ column, sampleData, columnName }) => (
                        <Row>
                            <Cell>
                                <HeaderText>{column}</HeaderText>
                            </Cell>
                            <Cell>
                                <HeaderText>{sampleData}</HeaderText>
                            </Cell>
                            <Cell>
                                <InputComponent
                                    handleChange={(event) => {
                                        setTableColumn(event.target.value);
                                    }}
                                    value={columnName}
                                    customStyle={{
                                        height: "40px",
                                        fontSize: "14px",
                                        marginTop: "0px",
                                    }}
                                    styles={{
                                        marginBottom: "0px",
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <StyledSelect
                                    value={columnType}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>TEXT</MenuItem>
                                    <MenuItem value={20}>INTEGER</MenuItem>
                                    <MenuItem value={30}>REAL</MenuItem>
                                    <MenuItem value={40}>BLOB</MenuItem>
                                </StyledSelect>
                            </Cell>
                            <Cell>
                                <Checkbox
                                    style={{
                                        color: darkMode ? "white" : "black",
                                    }}
                                    defaultChecked
                                />
                            </Cell>
                        </Row>
                    ))}
                </TableContainer>
            </Container>
        </MainLayout>
    );
};

export default ReviewTableStructure;
