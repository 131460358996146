/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
    BoldText,
    Container,
    ContentContainer,
    DropzoneContainer,
    DropzoneText,
    InfoIconStyled,
    Row,
    RowContainer,
    SelectFileButton,
    Subtitle,
    Title,
    UploadFileIconStyled,
} from "./styles";
import { useApi } from "hooks/useApi";
import { csvUpload } from "api/chat";

interface Props {
    callback?: () => void;
    tableName: string;
}

const UploadCSVComponent = ({ callback = () => { }, tableName }: Props) => {
    const { t } = useTranslation();
    const [openAlert, setOpenAlert] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const { request }: any = useApi({
        apiFunc: csvUpload, onSuccess: (res) => {
            setOpenAlert(false);
            if (!res) setOpenErrorAlert(true)
            else {
                setOpenSuccessAlert(true);
                setTimeout(callback, 500);
            }
        }
    });

    const onDrop = useCallback(
        (acceptedFiles) => {
            setOpenAlert(true)
            const formData = new FormData();
            formData.append('file', acceptedFiles[0]);
            formData.append('tableName', tableName);
            request(formData);
        },
        [callback]
    );

    const { getRootProps, open } = useDropzone({
        noClick: true,
        onDrop,
        accept: { "text/csv": [".csv"] },
    });

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") return;
        setOpenAlert(false);
        setOpenSuccessAlert(false)
    };

    return (
        <Container>
            <Title>{t("upload-csv.title")}</Title>
            <Subtitle>{t("upload-csv.subtitle")}</Subtitle>
            <RowContainer>
                <InfoIconStyled />
                {/* TODO: change max file size */}
                <BoldText>{t("upload-csv.restriction")}</BoldText>
            </RowContainer>
            <DropzoneContainer {...getRootProps()}>
                <ContentContainer>
                    <UploadFileIconStyled />
                    <Row>
                        <SelectFileButton type="button" onClick={open}>
                            {t("upload-csv.select-file")}
                        </SelectFileButton>
                        <DropzoneText>
                            {t("upload-csv.upload-file")}
                        </DropzoneText>
                    </Row>
                </ContentContainer>
            </DropzoneContainer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openAlert}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant="filled"
                    severity="info"
                    sx={{ width: "100%" }}
                >
                    {t("upload-csv.alert")}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSuccessAlert}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {t("upload-csv.success")}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openErrorAlert}
                autoHideDuration={5000}
                onClose={() => setOpenErrorAlert(false)}
            >
                <Alert
                    onClose={() => setOpenErrorAlert(false)}
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {t("upload-csv.error")}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UploadCSVComponent;
