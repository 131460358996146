const PlusNavSvg = ({
  width = 10,
  height = 10,
  fillOpacity = 0.5,
  fill = "#fff",
  ...props
}) => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 10 10'
    width={width}
    height={height}
    {...props}
  >
    <path
      opacity={fillOpacity}
      d='M5.671 4.227V1.385A.632.632 0 005.04.754h-.632a.632.632 0 00-.631.631v2.842H.935a.632.632 0 00-.631.631v.632c0 .348.282.63.631.631h2.842v2.842c0 .348.282.63.631.631h.632a.632.632 0 00.631-.631V6.12h2.842a.632.632 0 00.631-.631v-.632a.632.632 0 00-.631-.631H5.67z'
      fill={fill}
    />
  </svg>
);

export default PlusNavSvg;
