import { Text, Button, Icon } from "./styles";

export const ButtonWithIconComponent = ({
    label,
    secondary,
    onClick,
    customStyle,
    disabled,
    icon,
    labelStyle,
    children,
    iconComponent,
}: {
    label?: string;
    onClick: () => void;
    customStyle?: React.CSSProperties;
    disabled?: boolean;
    secondary?: boolean;
    icon?: string;
    labelStyle?: React.CSSProperties;
    children?: React.ReactNode;
    iconComponent?: React.ReactNode;
}) => {
    return (
        <Button
            secondary={secondary}
            disabled={disabled}
            style={customStyle}
            onClick={onClick}
        >
            {iconComponent}
            {icon && <Icon src={icon} />}
            <Text style={labelStyle}>{label}</Text>
            {children}
        </Button>
    );
};
