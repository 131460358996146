import styled from "styled-components";

export const Wrapper = styled.div<{
  disabled?: boolean;
  height: number;
  width: number;
  minWidth: number;
  themeField?: string;
}>`
  display: flex;
  user-select: none;
  cursor: pointer;
  position: relative;
  margin: 0 0 0 10px;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  min-width: ${({ minWidth }) => minWidth}px;
  border-radius: ${({ theme, themeField }) =>
    theme.palette[themeField].borderRadius};
  background: ${({ theme, themeField }) =>
    theme.palette[themeField].background};
  box-shadow: ${({ theme, themeField }) => theme.palette[themeField].shadow};
`;

export const Inner = styled.div<{ themeField?: string }>`
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  height: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.25px;
`;
