import styled from "styled-components";

export const Drawer = styled.div`
  transition-duration: 400ms;
  top: 8px;
  bottom: 8px;
  width: 348px;
  left: -348px;
  opacity: 1;
  content: "";
  z-index: 100;
  position: absolute;
  box-shadow: ${({ theme }) => theme.palette.boxShadow};
  background: ${({ theme }) => theme.palette.menu};
  &.open {
    left: 0px;
    transform: translate3d(0, 0, 0);
  }
`;

export const Header = styled.div`
  height: 75px;
  display: flex;
  padding: 0 23px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primaryHighlight};
`;

export const Button = styled.button`
  height: 48px;
  transition: opacity 300ms;
  user-select: none;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
`;

export const Body = styled.div`
  max-height: 753px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Footer = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.menu};
  border-radius: 0 0 7px 7px;
  border-top: 1px solid ${({ theme }) => theme.palette.menuBorder};
  box-shadow: ${({ theme }) => ` 0px -7px 27px ${theme.palette.darkBlack}`};
  padding: 0px 10px;
`;

export const Signout = styled.div`
  height: 60px;
  width: 100%;
  min-width: 36px;
  margin-bottom: 8px;
  font-size: 24px;
  padding: 0 3px 0 13px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text};
  letter-spacing: 1.25px;
  align-items: center;
  cursor: pointer;
`;

export const CopyRight = styled.div`
  height: 12px;
  margin: 18px 8px 8px 8px;
  display: flex;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text};
`;

export const Link = styled.a`
  padding: 0 2px;
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  background-color: transparent;
`;

export const FeedbackButton = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.secondaryButton};
  height: 36px;
  width: 100%;
  min-width: 36px;
  border-radius: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  border: none;
  align-items: center;
  position: relative;
`;

export const Navigation = styled.div`
  padding-bottom: 190px;
`;

export const Icon = styled.img`
  position: absolute;
  left: 12px;
`;
