import ClipLoader from 'react-spinners/ClipLoader';

import styled from 'styled-components';

export const Loader = styled(ClipLoader)`
  all: unset;
  appearance: none;
  background: none;
`;

export const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
`;
