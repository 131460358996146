import { useSelector } from "react-redux";

import { Text, Icon, Container } from "./styles";
import { UserIcon, UserDark } from "assets";
import { RootState } from "redux/reducers";

type AvatarProps = {
  name?: string;
  src?: string;
  width?: number;
  height?: number;
  radius?: number;
};

export const Avatar = ({
  src,
  name,
  width = 50,
  height = 50,
  radius = 50,
}: AvatarProps) => {
  const { app } = useSelector((state: RootState) => state.app);

  return (
    <Container>
      <Icon
        width={width}
        height={height}
        radius={radius}
        src={src || app.darkMode ? UserIcon : UserDark}
      />
      {name && <Text>{name}</Text>}
    </Container>
  );
};
