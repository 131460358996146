import styled from "styled-components";

export const Wrapper = styled.div<{ isCompleted?: boolean }>`
  position: relative;
  border-radius: 7px;
  background: ${({theme}) => theme.palette.primaryHighlight};
  padding: 24px;
  margin-bottom: 8px;
  width: 49%;
  box-shadow: ${({ isCompleted }) =>
    isCompleted ? "none" : `0 0 0 2px ${({theme}) => theme.palette.button};`};
`;

export const Title = styled.div`
  font-size: 20px;
  padding: 0 0 10px 0;
  color: ${({theme}) => theme.palette.text};
  font-family: ${({theme}) => theme.typography.semiBold.inter};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Text = styled.div`
  font-size: 18px;
  color: ${({theme}) => theme.palette.subtitle};
  margin-bottom: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Date = styled.div`
  font-size: 18px;
  color: ${({theme}) => theme.palette.buttonDisabled};
`;
