const MoonSvg = ({
  width = 17,
  height = 17,
  fill = '#fff',
  ...props
}) => (
  <div style={{width, height}}>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      {...props}
    >
      <path
        d="M16.51 9.453A7.816 7.816 0 118.007.95a6.08 6.08 0 008.503 8.503z"
        fill={fill}
      />
    </svg>
  </div>
);

export default MoonSvg;
