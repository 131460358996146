const MenuSvg = ({
  width = 17,
  height = 12,
  fillOpacity = 0.87,
  fill = "#fff",
}) => (
  <div style={{ width, height }}>
    <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 12'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M.803 12h15.383v-2H.803v2zm0-5h15.383V5H.803v2zm0-7v2h15.383V0H.803z'
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  </div>
);

export default MenuSvg;
