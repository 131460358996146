/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Company {
    affirmative_color: string;
    attention_color: string;
    created_at: string;
    dark_logo_url: string;
    error_color: string;
    light_logo_url: string;
    name: string;
    primary_color: string;
    secondary_color: string;
    updated_at: string;
}

interface CompanySliceState {
    company: Company
}

const companyInitialState: CompanySliceState = {
    company: {
        affirmative_color: "",
        attention_color: "",
        created_at: "",
        dark_logo_url: "",
        error_color: "",
        light_logo_url: "",
        name: null,
        primary_color: "",
        secondary_color: "",
        updated_at: "",
    }
};

const company = createSlice({
    name: "company",
    initialState: companyInitialState,
    reducers: {
        saveCompany(state, { payload }: PayloadAction<Company>) {
            state.company = payload;
        },
    },
});

export const { saveCompany } = company.actions;

export default company.reducer;
