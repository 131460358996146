import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "redux/store";
import { Router } from "Router";
import StyledThemeProvider from "styles/styledTheme";
import "translations";
import "./index.css";
import "./socket";

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <StyledThemeProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </StyledThemeProvider>
    </Provider>
  );
}

export default App;
