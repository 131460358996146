import styled from 'styled-components';

export const Wrapper = styled.div<{isActive?: boolean}>`
  margin: 10px 0 15px 0;
  background: ${({theme}) => theme.palette.primaryHighlight};
  border-radius: 7px;
  padding: 15px 0 4px 0;
  box-shadow: ${props => props.isActive ? `0 0 0px 2px ${props.theme.palette.green}` : 'none'};
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  padding: 6px 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Column = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 4px 0;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.buttonDisabled};

  &.left {
    width: 209px;
    padding-left: 20px;
  }

  &.center {
    flex-grow: 2;
    font-size: 18px;
    line-height: 1.3;
    color: ${({theme}) => theme.palette.link};
  }

  &.right {
    width: 400px;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
    align-items: flex-end;
    flex-direction: column;
  }
`;

export const Chip = styled.div<{isActive?: boolean}>`
  display: flex;
  font-size: 17px;
  margin-left: 25px;
  align-items: center;
  font-weight: normal;
  color: ${({theme}) => theme.palette.text};
  font-size: ${props => props.isActive ? '20px' : '17px'};
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  color: ${props => props.theme.palette.text};
`;

export const Dot = styled.div<{isActive?: boolean}>`
  width: 13px;
  height: 13px;
  margin-right: 9px;
  border-radius: 50%;
  background: ${props => props.isActive ? props.theme.palette.green : 'none'};
  border: 1px solid ${props => props.isActive ? props.theme.palette.green : props.theme.palette.secondary};
`;

export const Timestamp = styled.div<{isActive?: boolean}>`
  opacity: ${props => props.isActive ? '1' : '0.2'};
  font-size: 18px;
  font-weight: normal;
  text-transform: initial;
  color: ${({theme}) => theme.palette.link};
`;
