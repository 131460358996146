/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface App {
    drawerOpen: boolean;
    darkMode: boolean;
}
export interface AppSliceState {
  app: App;
}

const appInitialState: AppSliceState = {
  app: {
    drawerOpen: false,
    darkMode: true
  },
};

const app = createSlice({
  name: "app",
  initialState: appInitialState,
  reducers: {
    handleDrawer(state, { payload }: PayloadAction<boolean>) {
      state.app.drawerOpen = payload;
    },
    setTheme(state, { payload }: PayloadAction<boolean>) {
      state.app.darkMode = payload;
    },
  },
});

export const { handleDrawer, setTheme } = app.actions;

export default app.reducer;
