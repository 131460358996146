import styled, { css } from "styled-components";

export const Container = styled.div<{ styles: any }>`
    display: flex;
    flex-direction: column;
    margin: 0 0 32px 0;
    position: relative;
    width: 100%;
    ${(props) =>
        props.styles &&
        css`
            ${props.styles}
        `}
`;

export const Text = styled.div`
    color: ${({ theme }) => theme.palette.text};
    font-size: 18px;
    font-weight: 600;
    font-family: ${({ theme }) => theme.typography.normal.inter};
`;

export const Input = styled.input<{ error?: boolean }>`
    margin-top: 5px;
    font-family: ${({ theme }) => theme.typography.normal.inter};
    color: ${({ theme }) => theme.palette.text};
    -webkit-text-fill-color: ${({ theme }) => theme.palette.text};
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.input} inset !important;
    outline: none;
    width: 100%;
    height: 54px;
    font-size: 18px;
    border-radius: 3px;
    padding: 0px 17px;
    caret-color: ${({ theme }) => theme.palette.text} !important;
    border: 1px solid ${({ theme }) => theme.palette.border};
`;

export const Textarea = styled.textarea<{ error?: boolean }>`
    margin-top: 5px;
    font-family: ${({ theme }) => theme.typography.normal.inter};
    color: ${({ theme }) => theme.palette.text};
    -webkit-text-fill-color: ${({ theme }) => theme.palette.text};
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.input} inset !important;
    outline: none;
    width: 100%;
    height: 200px;
    font-size: 18px;
    border-radius: 3px;
    padding: 15px 17px;
    font-size: 18px;
    background: ${({ theme }) => theme.palette.input};
    caret-color: ${({ theme }) => theme.palette.white} !important;
    border: 1px solid ${({ theme }) => theme.palette.border};
`;

export const Label = styled.span<{ multiline: boolean }>`
    top: 24px;
    right: 13px;
    z-index: 2;
    background: ${({ theme }) => theme.palette.input};
    font-size: 12px;
    line-height: 12px;
    color: ${({ theme }) => theme.palette.text};
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    padding: 1px 5px 1px 5px;
    ${(props) =>
        props.multiline &&
        `
  top: 24px;
  position: absolute;
  right: 30px;
  z-index: 3;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.palette.white};
  pointer-events: none;
  text-transform: uppercase;
  padding: 0px 5px 0px 5px;
  background: transparent;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    z-index: -1;
  }
  
  &::after {
    top: initial;
    bottom: 1px;
    background: ${({ theme }) => theme.palette.input};
  }
  `}
`;
