import axios, { AxiosRequestHeaders } from 'axios';

console.log(`API URL: ${process.env.REACT_APP_API_URL}`);

export const HOST = {
  Host: process.env.REACT_APP_API_URL,
};

const api = axios.create({
  baseURL: HOST.Host,
  headers: {
    Accept: 'application/json',
  },
});

api.interceptors.request.use((req) => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    const headers = {
      ...req.headers,
      Authorization: token,
    };
    req.headers = headers as AxiosRequestHeaders;
  }
  return req;
});

const UNAUTHORIZED = 401;
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === UNAUTHORIZED) {
      localStorage.removeItem('accessToken');
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default api;
