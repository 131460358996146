import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrap = styled.div`
  display: flex;
`;

export const Bar = styled.div`
  width: 2px;
  content: "";
  height: 35px;
  margin: auto 0;
  background: ${({theme}) => theme.palette.button};
`;

export const Title = styled.div`
  color: ${({theme}) => theme.palette.text};
  font-family: ${({theme}) => theme.typography.normal.inter};
  margin-left: 16px;
  font-size: 50px;
  font-weight: 300;
  text-transform: uppercase;
`;

export const ActionsDiv = styled.div`
  margin: 10px 30px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
