import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const SectionContent = styled.div`
  padding: 10px;
`;

export const PanelContainer = styled.div`
flex-grow: 2;
    width: 0;
}
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Spacer = styled.div`
  width: 8px;
`;

export const CenteredFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;