import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Wrapper, Row, Column, Chip, Dot, Timestamp } from './styles';
import ButtonHighlight from 'components/common/buttonHighlit';

export const AnnouncementItem = ({ item, onEditClick, onArchiveClick }) => {
  const { is_active, created_on, archived_on, announcement } = item;
  const { t } = useTranslation();
  
  return (
    <Wrapper isActive={is_active}>
      <Row>
        <Column className="left">{t("announcements-screen.item.title")}:</Column>
        <Column className="center">{announcement}</Column>
        <Column className="right">
          <Chip isActive={is_active}>
            <Dot isActive={is_active} />
            {is_active ? t("announcements-screen.item.active") : t("announcements-screen.item.expired")}
          </Chip>
          <Timestamp isActive={is_active}>
            {t("announcements-screen.item.posted")}:&nbsp;{dayjs(created_on).format('MM/DD/YYYY - h:mma')}
          </Timestamp>
          {!is_active && (
            <Timestamp>
              {t("announcements-screen.item.expired")}:&nbsp;{dayjs(archived_on).format('MM/DD/YYYY - h:mma')}
            </Timestamp>
          )}
        </Column>
      </Row>
      <Row>
        <Column className="left">&nbsp;</Column>
        <Column className="center">
        </Column>
        <Column className="right">
          {is_active && (
            <Row>
              <ButtonHighlight width={90} height={36} onClick={onEditClick} theme={'primaryButtonPlain'}>{t("announcements-screen.item.button.label")}</ButtonHighlight>
              <ButtonHighlight width={90} height={36} onClick={onArchiveClick} theme={'primaryButtonPlain'}>{t("announcements-screen.item.button.expire")}</ButtonHighlight>
            </Row>
          )}
        </Column>
      </Row>
    </Wrapper>
  );
};
