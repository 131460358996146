import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.darkBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalTopContainter = styled.div`
  height: 72px;
  min-height: 72px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0 0px 0 24px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.text};
  border-bottom: 1px solid ${({ theme }) => theme.palette.input};
`;

export const CloseDiv = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.input};
  height: 100%;
  align-items: center;
  justify-content: end;
  align-content: center;
  display: flex;
  width: 80px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 6px ${({ theme }) => theme.palette.darkBlack};
  width: 70%;
  max-width: 800px;
  color: white;
  background: ${({ theme }) => theme.palette.primaryHighlight};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonsRightContainer = styled.div`
  display: flex;
`;

export const AssignToContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const DropdownContainer = styled.div<{ fixed?: boolean }>`
  left: 0;
  right: 0;
  top: 100%;
  z-index: 5;
  margin: 8px 0 0 0;
  position: absolute;
  max-height: 405px;
  border-radius: 7px;
  background: ${(props) => props.theme.palette.primary};
  box-shadow: 0 0 20px 0 ${(props) => props.theme.palette.link};
  ${(props) => props.fixed && "height: 405px;"}
`;

export const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 8px 15px;
  color: ${(props) => props.theme.palette.buttonDisabled};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.text};
  }
  &.active {
    color: ${(props) => props.theme.palette.text};
  }
`;

export const DropdownItemNoMatch = styled(DropdownItem)`
  pointer-events: none;
`;

export const Title = styled.div``;

export const UsersRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
  justify-content: space-between;
`;
