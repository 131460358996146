/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CloseDiv,
  Form,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
  Row,
} from "./styles";
import CloseSvg from "assets/svgs/CloseSvg";
import { Button, FormSelect, Input } from "components";
import PlusSvg from "assets/svgs/PlusSvg";
import RepeatSvg from "assets/svgs/RepeatSvg";
import { Roles } from "utils";
import { RootState } from "redux/reducers";

const checkRequiredData = (data) => {
  return !!(data.firstName && data.lastName && data.userEmail && data.role);
};

type UserModalProps = {
  edit?: boolean;
  getInitialState: any;
  onSubmit: ({ user }: { user: any }) => void;
  onClose: () => void;
  action?: string;
  handleRemove?: () => void;
  resentSuccess?: boolean;
  updateSuccess?: boolean;
  createSuccess?: boolean;
  cancelSuccess?: boolean;
  error: boolean;
  roleDisabled?: boolean;
}

const UserModalView = ({
  edit,
  getInitialState,
  onSubmit,
  onClose,
  action,
  handleRemove,
  resentSuccess,
  updateSuccess,
  createSuccess,
  cancelSuccess,
  roleDisabled,
  error
}: UserModalProps) => {
  const initialState = getInitialState();
  const { app } = useSelector((state: RootState) => state.app);

  const [user, setUser] = useState(initialState.user || "");
  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit({ user });
  };

  const getLabel = () => {
    if (edit) {
      if (action === "edit_user")
        return updateSuccess
          ? t("user-management-screen.item.buttons.updated")
          : t("user-management-screen.item.buttons.update");
      else
        return resentSuccess
          ? t("user-management-screen.item.buttons.resent")
          : t("user-management-screen.item.buttons.resend");
    } else
      return createSuccess
        ? t("user-management-screen.item.buttons.added")
        : t("user-management-screen.item.buttons.add");
  };

  const onDataChangeHandler = (key, value) =>
    setUser((prev) => ({ ...prev, [key]: value }));

  useEffect(() => {
    if (cancelSuccess) onClose();
  }, [cancelSuccess]);

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>
            {edit
              ? t("user-management-screen.item.manage")
              : t("user-management-screen.item.create")}
          </h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={app.darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Input
              disabled={edit}
              styles={{ marginRight: 10 }}
              value={user.firstName}
              label={t("user-management-screen.item.inputs.first-name.label")}
              handleChange={(e) =>
                onDataChangeHandler("firstName", e.target.value)
              }
              required
              placeholder={`${t(
                "user-management-screen.item.inputs.first-name.label"
              )}...`}
            />
            <Input
              disabled={edit}
              value={user.lastName}
              label={t("user-management-screen.item.inputs.last-name.label")}
              handleChange={(e) =>
                onDataChangeHandler("lastName", e.target.value)
              }
              required
              placeholder={`${t(
                "user-management-screen.item.inputs.last-name.label"
              )}...`}
            />
          </Row>
          <Row>
            <Input
              disabled={edit}
              styles={{ marginRight: 10 }}
              value={user.userEmail}
              label={t("user-management-screen.item.inputs.email.label")}
              handleChange={(e) =>
                onDataChangeHandler("userEmail", e.target.value)
              }
              required
              placeholder={`${t(
                "user-management-screen.item.inputs.email.label"
              )}...`}
            />
            <FormSelect
              disabled={roleDisabled || action === "edit_pending_user"}
              label={t("user-management-screen.item.inputs.role.label")}
              required
              placeholder={`${t(
                "user-management-screen.item.inputs.role.label"
              )}...`}
              items={Roles.getRoles().map(role => ({ id: role, value: role }))}
              onSelect={(role) => onDataChangeHandler("role", role)}
              selected={{ id: user.role, value: user.role }}
            />
          </Row>
          <Row>
            {edit && (
              <Button
                width='175px'
                error
                styles={{ marginRight: 10 }}
                icon={() => (
                  <CloseSvg fill={app.darkMode ? undefined : "#222"} />
                )}
                onClick={(e) => { e.preventDefault(); handleRemove?.() }}
                label={
                  action === "edit_user"
                    ? t("user-management-screen.item.buttons.remove")
                    : t("user-management-screen.item.buttons.cancel")
                }
              />
            )}
            <Button
              width='175px'
              icon={() =>
                edit ? (
                  <RepeatSvg fill={app.darkMode ? undefined : "#222"} />
                ) : (
                  <PlusSvg fill={app.darkMode ? undefined : "#222"} />
                )
              }
              error={error}
              onClick={handleSubmit}
              success={
                (edit && action === "edit_user" && updateSuccess) ||
                (edit && resentSuccess) ||
                (!edit && createSuccess)
              }
              disabled={!checkRequiredData(user)}
              label={getLabel()}
            />
          </Row>
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default UserModalView;
