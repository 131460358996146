const CloseSvg = ({
  width = 16,
  height = 16,
  fillOpacity = 1,
  fill = '#fff',
  ...props
}) => (
  <div style={{width, height}}>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={width}
      height={height}
      {...props}
    >
      <path
        d="M10.87 7.539l4.265-4.265a1.34 1.34 0 000-1.895L14.187.43a1.34 1.34 0 00-1.895 0L8.027 4.695 3.763.431a1.34 1.34 0 00-1.895 0l-.948.948a1.34 1.34 0 000 1.895L5.184 7.54.92 11.803a1.34 1.34 0 000 1.895l.948.948a1.34 1.34 0 001.895 0l4.264-4.264 4.265 4.264a1.34 1.34 0 001.895 0l.948-.948a1.34 1.34 0 000-1.895L10.87 7.539z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  </div>
)

export default CloseSvg;
