/* eslint-disable object-curly-newline */
import { useState } from "react";

interface IUseApi {
    apiFunc: any;
    initialLoading?: boolean;
    onSuccess?: (data: any) => void;
    onFailure?: (error: any) => void;
}

export function useApi<T>({
    apiFunc,
    initialLoading = false,
    onSuccess,
    onFailure,
}: IUseApi) {
    const [data, setData] = useState<T>();
    const [error, setError] = useState<null | any>(null);
    const [loading, setLoading] = useState(initialLoading);
    const [isSuccess, setIsSuccess] = useState(null);

    const request = async (...args: any) => {
        setLoading(true);
        setIsSuccess(false);
        try {
            const response = await apiFunc(...args);
            setLoading(false);
            setIsSuccess(true);
            setError(null);
            setData(response?.data);
            onSuccess?.(response?.data);
        } catch (e: any) {
            setLoading(false);
            console.log("Error on [useApi]:", e);
            setError(e?.response?.data);
            onFailure?.(e);
        }
    };

    const clear = () => {
        setError(null);
        setData(undefined);
        setLoading(false);
        setIsSuccess(null);
    };

    return { data, error, loading, request, setError, clear, isSuccess };
}
