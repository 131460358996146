import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Module } from "pages/Dashboard/TrainingModules";
import { Text, Date, Title, TitleContainer, Wrapper } from "./styles";
import { Button } from "components";
import { DoubleArrow } from "assets";
import {LigthTheme, DarkTheme} from "styles/theme";
import { useApi } from "hooks/useApi";
import { markTrainingAsComplete } from "api/trainings";
import { formatDate } from "utils";
import { RootState } from "redux/reducers";

export const TrainingModule = ({
  module,
  userId,
}: {
  module: Module;
  userId: number;
}) => {
  const { t } = useTranslation();
  const { app } = useSelector((state: RootState) => state.app);
  const { request }: any = useApi({
    apiFunc: markTrainingAsComplete,
    onSuccess: () => setIsCompleted(true),
  });
  const [isCompleted, setIsCompleted] = useState(
    module.completed_by?.some((user) => user.id === userId)
  );
  const theme = app.darkMode ? DarkTheme({}) : LigthTheme({});

  const completedDate = useMemo(
    () => module.completed_by?.find((user) => user.id === userId)?.completed_at,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCompleted, module]
  );
  const handleClick = () => {
    request({ user_id: userId, training_module_id: module.id });
    window.open(module.url, "_blank");
  };
  return (
    <Wrapper isCompleted={isCompleted}>
      <TitleContainer>
        <Title>{module.title}</Title>
        {isCompleted && (
          <Date>{`${t("training-screen.completed")} ${formatDate(
            completedDate
          )}`}</Date>
        )}
      </TitleContainer>
      <Text>{module.description}</Text>
      <Button
        label={
          isCompleted ? t("training-screen.again") : t("training-screen.take")
        }
        customStyle={{
          width: "fit-content",
          paddingLeft: 42,
          paddingRight: 16,
          backgroundColor: isCompleted
            ? theme.palette.buttonDisabled
            : theme.palette.button,
        }}
        imageIcon={DoubleArrow}
        onClick={handleClick}
      />
    </Wrapper>
  );
};
