import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Text = styled.div`
  margin-left: 10px;
`

export const Header = styled.div`
  width: 100%;
  padding: 10px 20px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.text};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primarySoft};
`;

export const Body = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.text};
`;

export const LogoZone = styled.div<{ darkMode?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 7px;
  background: ${({ darkMode }) => darkMode ? '#222' : '#fff'};
  border: 1px solid ${({ darkMode }) => darkMode ? '#2F2F2F' : '#c7c7c7'};
  width: 100%;
`;

export const Placeholder = styled.div<{ darkMode?: boolean }>`
  font-style: italic;
  display: flex;
  padding: 10px 20px;
  color: ${({ darkMode, theme }) => darkMode ? theme.palette.white : theme.palette.black};
`;

export const RemoveButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 6px 0 7px;
  background-color: ${({ theme }) => theme.palette.secondary};;
  height: 35px;
  width: 35px;
  min-width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomerName = styled.div`
  margin-top: 20px;
`;