import styled from "styled-components";

export const Section = styled.div`
  position: relative;
  border-radius: 7px;
  background-color: ${({theme}) => theme.palette.primary};
  padding: 20px 31px;
  margin-top: 8px;
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 20px;
  padding: 0 0 10px 0;
  color: ${({theme}) => theme.palette.text};
`;

export const Subtitle = styled.div`
  font-size: 16px;
  color: ${({theme}) => theme.palette.subtitle};
  padding-bottom: 20px;
`;
