import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Container, Icon, InputAutosize } from "./styles";
import { useDispatch } from "react-redux";
import { addMessage } from "../../../redux/slices/chat";

interface Props {
    handleSendMessage: (question: string) => void;
    tableName: string;
}

const ChatInput = ({ handleSendMessage, tableName }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [input, setInput] = useState("");
    const [isTyping, setIsTyping] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (!isTyping && event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();

            onClickSendMessage();
        }
    };

    const onClickSendMessage = () => {
        if (!input) return;

        handleSendMessage(input);
        dispatch(
            addMessage({
                tableName: tableName || t("chat.tab"),
                message: {
                    text: input,
                    sender: "User",
                    id: uuidv4(),
                }
            })
        );
        setInput("");
    };

    return (
        <Container>
            <InputAutosize
                onChange={(event) => {
                    setInput(event.target.value);
                }}
                value={input}
                placeholder={t("chat.input-placeholder")}
                multiline
                minRows={2}
                maxRows={2}
            />
            <IconButton
                style={{ marginBottom: "32px" }}
                onClick={onClickSendMessage}
                disabled={!input}
                onKeyDown={handleKeyDown}
                onCompositionStart={() => setIsTyping(true)}
                onCompositionEnd={() => setIsTyping(false)}
            >
                <Icon />
            </IconButton>
        </Container>
    );
};

export default ChatInput;
