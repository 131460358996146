import styled from "styled-components";

export const Container = styled.div`
  height: 126px;
  display: flex;
  z-index: 2;
  margin: 0 0 -1px 0;
  position: relative;
  align-items: center;
  background: ${({theme}) => theme.palette.primarySoft};
  border-bottom: 1px solid ${({theme}) => theme.palette.primaryHighlight};
`;

export const Image = styled.img<{ avatar?: boolean }>`
  width: 70px;
  height: 70px;
  margin: 0 22px 0 28px;
  background-color: ${({theme}) => theme.palette.table};
  object-fit: cover;
  border-radius: 35px;
  opacity: ${({ avatar }) => (avatar ? 0.5 : 1)};
`;

export const Content = styled.div`
  width: 0;
  flex-grow: 2;
  line-height: 1.3;
  padding-right: 28px;
`;

export const Label = styled.div`
  font-family: ${({theme}) => theme.typography.normal.inter};
  font-size: 14px;
  color: ${({theme}) => theme.palette.subtitle};
  text-transform: capitalize;
`;

export const Title = styled.div`
  font-family: ${({theme}) => theme.typography.semiBold.inter};
  font-weight: 600;
  font-size: 20px;
  color: ${({theme}) => theme.palette.text};
  text-transform: capitalize;
`;

export const Link = styled.div`
  font-size: 14px;
  padding: 5px 0;
  cursor: pointer;
  font-family: "Helvetica Neue", "Helvetica", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: ${({theme}) => theme.palette.button};
  text-decoration: underline;
`;
