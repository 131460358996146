import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "components";
import {
  Background,
  Board,
  ButtonsContainer,
  Column,
  Task,
  TaskName,
  Space,
  Icon,
} from "./styles";
import ColumnModalView from "components/modalViews/columnModalView";
import TaskModalView from "components/modalViews/taskModalView";
import TaskDetailsModalView from "components/modalViews/taskDetailsModalView";
import { UserIcon, UserDark } from "assets";
import { RootState } from "redux/reducers";
import PlusSvg from "assets/svgs/PlusSvg";
import { ModalCollaborators } from "components/modalCollaborator";
import { LetterCircle } from "components/modalCollaborator/styles";
import ColumnTitle from "./columnTitle";

export type TaskType = {
  id: number;
  name: string;
  description: string;
  column_id: number;
  assigned_to: string;
};

export type ColumnType = {
  id: number;
  name: string;
  index: number;
};

export interface Collaborator {
  first_name: string;
  last_name: string;
  profile_image: string;
  collaborator_id: string;
  board_collaborator_id: string;
}

export interface BoardType {
  id: string;
  name: string;
  tasks: TaskType[];
  columns: ColumnType[];
  collaborators: Collaborator[];
}
const KanbanBoard = ({
  board,
  request,
}: {
  board: BoardType;
  request: any;
}) => {
  const { t } = useTranslation();
  const { app } = useSelector((state: RootState) => state.app);
  const [modalVisible, setModalVisible] = useState(false);
  const [collaboratorModalVisible, setCollaboratorModalVisible] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [taskModalDetailsVisible, setTaskModalDetailsVisible] = useState(false);
  const [task, setTask] = useState(null);

  const handleTaskClick = (id: number) => {
    setTask(board.tasks.find((task) => task.id === id));
    setTaskModalDetailsVisible(true);
  };

  const getUserName = (userId: string) => {
    const user = board.collaborators?.find(u => String(u.collaborator_id) === String(userId))
    return user ? `${user.first_name} ${user.last_name}` : '-'
  }

  return (
    <Background>
      <ButtonsContainer>
        <Button
          width='180px'
          label={t("kanban-screen.board.button")}
          onClick={() => setModalVisible(true)}
        />
        <Space />
        <Button
          width='230px'
          icon={() => <PlusSvg />}
          label={t("kanban-screen.board.collaborator")}
          onClick={() => setCollaboratorModalVisible(true)}
        />
      </ButtonsContainer>
      <Board>
        {board?.columns?.map((column) => (
          <Column key={column.id}>
            <ColumnTitle
              column={column}
              handleAddTask={() => setTaskModalVisible(true)}
              board={board}
              updateColumnSuccess={() => request({ id: board.id })}
            />
            {board.tasks.reduce((acc, task) => {
              if (task.column_id === column.id)
                acc.push(
                  <Task key={task.id} onClick={() => handleTaskClick(task.id)}>
                    <div>
                      <TaskName>{task.name}</TaskName>
                      {task.description}
                    </div>
                    {task.assigned_to ? (
                      <LetterCircle>
                        {getUserName(task.assigned_to).charAt(0)}
                      </LetterCircle>
                    ) : (
                      <Icon src={app.darkMode ? UserIcon : UserDark} />
                    )}
                  </Task>
                );
              return acc;
            }, [])}
          </Column>
        ))}
      </Board>
      {modalVisible && (
        <ColumnModalView
          board={board}
          onSuccess={() => {
            setModalVisible(false);
            request({ id: board.id });
          }}
          onClose={() => setModalVisible(false)}
        />
      )}
      {collaboratorModalVisible && (
        <ModalCollaborators
          handleClose={() => setCollaboratorModalVisible(false)}
          boardId={board.id}
          collaborators={board.collaborators}
        />
      )}
      {taskModalVisible && (
        <TaskModalView
          board={board}
          onSuccess={() => {
            setTaskModalVisible(false);
            request({ id: board.id });
          }}
          boardId={board.id}
          onClose={() => setTaskModalVisible(false)}
        />
      )}
      {taskModalDetailsVisible && (
        <TaskDetailsModalView
          task={task}
          hasNext={
            board.columns.find((col) => col.id === task.column_id).index <
            board.columns.length
          }
          hasPrev={
            board.columns.find((col) => col.id === task.column_id).index > 1
          }
          onClose={() => setTaskModalDetailsVisible(false)}
          board={board}
          onSuccess={() => request({ id: board.id })}
        />
      )}
    </Background>
  );
};

export default KanbanBoard;
