import styled, { DefaultTheme, css } from "styled-components";

type ButtonProps = {
  disabled?: boolean;
  secondary?: boolean;
  error?: boolean;
  hasIcon?: boolean;
  success?: boolean;
  theme: DefaultTheme
}
const getBackgroundColor = ({ disabled, secondary, error, success, theme }: ButtonProps) => {
  if (disabled) return theme.palette.buttonDisabled
  if (success) return theme.palette.primaryButtonDark.background
  if (secondary) return theme.palette.secondaryButton
  if (error) return theme.palette.red
  return theme.palette.button
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  background-color: ${({ disabled, secondary, error, success, theme }) =>
    getBackgroundColor({ disabled, secondary, error, success, theme })};
  height: 36px;
  width: 100%;
  min-width: 36px;
  border-radius: 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.85 : 1)};
  border: none;
  align-items: center;
  position: relative;
  ${(props) =>
    props.hasIcon &&
    `
  height: 36px;
  width: 214px;
  min-width: 36px;
  border-radius: 0px 10px 10px 0px;`}
`;

export const Text = styled.div<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.text : theme.palette.white};
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  font-weight: 600;
  letter-spacing: 1.25px;
`;

export const Icon = styled.img`
  top: 10px;
  left: 12px;
  position: absolute;
  z-index: 10;
`;

export const ButtonIcon = styled.button<{
  disabled?: boolean;
  secondary?: boolean;
  error?: boolean
}>`
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette.buttonDisabled
      : props.secondary
      ? props.theme.palette.secondaryButton
      : props.error 
        ? props.theme.palette.darkRed
        : props.theme.palette.primaryButtonDark.background};
  height: 36px;
  width: 100%;
  min-width: 36px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 10px;
  border: none;
  align-items: center;
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 10px 0px 0px 10px;
`;

export const Contianer = styled.div<{styles?: any}>`
  display: flex;
  ${props => props.styles && css`${props.styles}`}
`
