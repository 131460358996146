/* eslint-disable react-hooks/exhaustive-deps */
import { Redirect, Route, Switch } from "react-router-dom";

import { ROUTES } from "utils/routes";
import { PublicRoute } from "hoc/PublicRoute";
import { PrivateRoute } from "hoc/PrivateRoute";
import Login from "pages/Auth/Login";
import SignUpInvitation from "pages/Auth/Signup";
import ForgotPassword from "pages/Auth/ForgotPassword";
import UserManagment from "./pages/UserManagment";
import Dashboard from "pages/Dashboard";
import TrainingModules from "pages/Dashboard/TrainingModules";
import Announcements from "pages/Dashboard/Announcements";
import ManageTrainings from "pages/Dashboard/ManageTrainings";
import ManageTraining from "pages/Dashboard/ManageTraining";
import AdminControls from "pages/ManageAdminControls";
import NotFoundPage from "pages/404";
import ComingSoon from "pages/ComingSoon";
import Kanban from "pages/Kanban";
import Chat from "./pages/Chat";
import UploadCsv from "./pages/UploadCSV";
import ReviewTableStructure from "./pages/ReviewTableStructure";
import KanbanBoards from "pages/KanbanBoards";
import { useEffect } from "react";
import socket from "./socket";
import store from "./redux/store";
import { getNotifications } from "./api/notifications";

export const Router = () => {
  const { getState } = store;
  const accessToken = localStorage.getItem("accessToken");
  const getNotificationsCall = async () => await getNotifications();

  useEffect(() => {
    if (accessToken) {
      getNotificationsCall();

      socket.on("connect", () => {
        console.log("Connected!");

        const userId = getState().auth.user.id;
        if (!!userId) socket.emit("register", userId);
      });

      socket.on("disconnect", () => {
        console.log("Disconnected from server.");
      });

      socket.on("notification", (data) => {
        console.log("New notification: ", data);
        getNotificationsCall();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, []);

  return (
    <Switch>
      <PublicRoute path={ROUTES.login} element={Login} />
      <PublicRoute path={ROUTES.signup} element={SignUpInvitation} />
      <PublicRoute path={ROUTES.forgotPassword} element={ForgotPassword} />
      <PrivateRoute path={ROUTES.dashboard} element={Dashboard} />
      <PrivateRoute path={ROUTES.trainingModules} element={TrainingModules} />
      <PrivateRoute path={ROUTES.announcements} element={Announcements} />
      <PrivateRoute path={ROUTES.manageTraining} element={ManageTraining} />
      <PrivateRoute path={ROUTES.kanbanBoards} element={KanbanBoards} />
      <PrivateRoute path={`${ROUTES.kanban}/:id`} element={Kanban} />
      <PrivateRoute path={ROUTES.manageTrainings} element={ManageTrainings} />
      <PrivateRoute path={ROUTES.manageUsers} element={UserManagment} />
      <PrivateRoute path={ROUTES.manageAdminControls} element={AdminControls} />
      <PrivateRoute path={ROUTES.kanban} element={Kanban} />
      <PrivateRoute path={ROUTES.notifications} element={ComingSoon} />
      <PrivateRoute path={ROUTES.reports} element={ComingSoon} />
      <PrivateRoute path={ROUTES.uploadCsv} element={UploadCsv} />
      <PrivateRoute
        path={ROUTES.reviewTableStructure}
        element={ReviewTableStructure}
      />
      <PrivateRoute path={ROUTES.chat} element={Chat} />
      <Route
        path={ROUTES.home}
        strict
        exact
        render={() => <Redirect to={ROUTES.dashboard} />}
      />
      <Route path="/*" component={NotFoundPage} />
    </Switch>
  );
};
