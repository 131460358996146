import { Loader, Div } from './styles';

interface LoadingProps {
  color: string;
  loading: boolean;
  size?: number;
}
const Loading = ({ color, loading, size = 50 }: LoadingProps) => {
  return (
    <Div>
      <Loader
        color={color}
        loading={loading}
        size={size}
        data-testid="loader"
      />
    </Div>
  );
};

export default Loading;
