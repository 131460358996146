import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  border-radius: 3px;
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  flex-direction: column;
  margin: 0 0 32px 0;
  position: relative;
  width: 100%;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.palette.text};
  font-size: 20px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.typography.normal.inter};
`;

export const SelectInput = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.palette.input};
  margin-top: 5px;
  font-family: ${({ theme }) => theme.typography.normal.inter};
  color: ${({ theme }) => theme.palette.text};
  -webkit-text-fill-color: ${({ theme }) => theme.palette.text};
  -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.input} inset !important;
  outline: none;
  width: 100%;
  height: 54px;
  font-size: 18px;
  border-radius: 3px;
  padding: 0px 17px;
  caret-color: ${({ theme }) => theme.palette.white} !important;
  border: 1px solid ${({ theme }) => theme.palette.border};
`;

export const Icon = styled.span<{ isOpen?: boolean }>`
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

export const Dropdown = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.input};
  border-radius: 0 0 3px 3px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  top: 83px;
  z-index: 10;
`;

export const DropdownItem = styled.div`
  padding: 8px 15px;
  cursor: pointer;
  font-size: 17px;
  color:${({ theme }) => theme.palette.text};
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary};
  }
`;

export const RequiredLabel = styled.span<{ multiline: boolean }>`
  top: ${({ multiline }) => (multiline ? "24px" : "10px")};
  right: 13px;
  z-index: 2;
  background: ${({ theme }) => theme.palette.input};
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.palette.text};
  pointer-events: none;
  position: absolute;
  text-transform: uppercase;
  padding: 1px 5px;
`;
