import classnames from 'classnames';

const ErrorSvg =({
    width = 21,
    height = 20,
    fill = '#fff',
    className = undefined,
    ...props
}) => (
    <div style={{ width, height }} className={classnames("svg-wrapper", className)}>
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 20"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.49 13h2v2h-2v-2zm0-8h2v6h-2V5zm.99-5C4.96 0 .49 4.48.49 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10S16.01 0 10.48 0zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                fill={fill}
                fillOpacity={0.87}
            />
        </svg>
    </div>
)

export default ErrorSvg;