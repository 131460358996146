import api from ".";
import store from "../redux/store";
import { addUsersProfiles } from "../redux/slices/users";

const { dispatch } = store;

export const getProfiles = (offset?: number) => {
    api.get(`/users${offset ? `?offset=${offset}` : ""}`)
        .then((res) => {
            dispatch(addUsersProfiles(res?.data));
            return res;
        })
        .catch((e) => {
            console.log("Error: ", e);
        });
};

export function editUserProfile(data) {
    return api
        .post(`/users/update-user`, data)
        .then((res) => {
            return res;
        })
        .catch((e) => {
            console.log("Error: ", e);
        });
}

export function deleteProfile(data) {
    const { id } = data;
    return api
        .delete(`/users/delete/${id}`, data)
        .then((res) => {
            return res;
        })
        .catch((e) => {
            console.log("Error: ", e);
        });
}
