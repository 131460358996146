import api from "api"
import store from "../redux/store";
import { saveCompany } from "redux/slices/company";

const { dispatch } = store;

export function uploadCompanyAsset(data) {
    api.post('/company/upload', data).then((res) => {
        return res;
    }).catch((e) => {
        console.log("Error: ", e);
    });
};

export function updateCompany(data) {
    api.post('/company/update', data).then((res) => {
        dispatch(saveCompany(res.data));
        return res;
    }).catch((e) => {
        console.log("Error: ", e);
    });
}


export function getCompanyInfo() {
    api.get('/company').then((res) => {
        dispatch(saveCompany(res.data));
        return res;
    }).catch((e) => {
        console.log("Error: ", e);
    });
}