import React, { useState } from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
    ButtonRow,
    ChartIcon,
    Container,
    GridIcon,
    Header,
    LabelRow,
    TitleContainer,
    Text,
    Label,
} from "./styles";
import { InputComponent } from "../common/input";
import { ButtonWithIconComponent } from "../buttonWithIcon";
import { RootState } from "../../redux/reducers";

interface Props {
    handleClose: () => void;
    open: boolean;
    onClickButton: (tableName: string) => void;
}

const TableNameModal = ({ handleClose, open, onClickButton }: Props) => {
    const {
        app: { darkMode },
    } = useSelector((state: RootState) => state.app);
    const { t } = useTranslation();
    const [tableName, setTableName] = useState("");

    return (
        <Modal open={open} onClose={handleClose}>
            <Container>
                <Header>
                    <TitleContainer>
                        <ChartIcon darkMode={darkMode} />
                        <Text
                            id="modal-modal-title"
                            variant="h6"
                            darkMode={darkMode}
                        >
                            {t("chat.modal-title")}
                        </Text>
                    </TitleContainer>
                    <IconButton
                        onClick={handleClose}
                        style={{ alignSelf: "flex-end" }}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                </Header>
                <div style={{ marginTop: "8px" }}>
                    <LabelRow>
                        <Label darkMode={darkMode}>
                            {t("chat.input-label")}
                        </Label>
                        <div style={{ color: "red" }}>*</div>
                    </LabelRow>
                    <InputComponent
                        handleChange={(event) => {
                            setTableName(event.target.value);
                        }}
                        value={tableName}
                        customStyle={{ height: "36px", boxShadow: "black" }}
                    />
                </div>
                <ButtonRow>
                    <ButtonWithIconComponent
                        customStyle={{
                            width: "116px",
                            height: "40px",
                            backgroundColor: "#171717",
                            borderRadius: "6px",
                        }}
                        labelStyle={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "400",
                        }}
                        onClick={() => onClickButton(tableName)}
                        label={t("chat.modal-button")}
                        disabled={!tableName}
                    >
                        <GridIcon />
                    </ButtonWithIconComponent>
                </ButtonRow>
            </Container>
        </Modal>
    );
};

export default TableNameModal;
