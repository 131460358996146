import api from ".";
import { addUsersInvitations } from "../redux/slices/invitations";
import store from "../redux/store";

const { dispatch } = store;

export const getInvitations = () => {
    api.get("/invitations")
        .then((res) => {
            dispatch(addUsersInvitations(res?.data));
            return res;
        })
        .catch((e) => {
            console.log("Error: ", e);
        });
};

export const resendUserInvitation = (data) => {
    const { id } = data;
    return api
        .post(`/invitations/${id}/resend`, data)
        .then((res) => {
            return res;
        })
        .catch((e) => {
            console.log("Error: ", e);
        });
};

export function postUserInvitation(data) {
    return api
        .post(`/invitations`, data)
        .then((res) => {
            return res;
        })
        .catch((e) => {
            console.log("Error: ", e);
        });
}

export function cancelUserInvitation(data) {
    const { id } = data;
    return api
        .post(`/invitations/${id}/cancel`, data)
        .then((res) => {
            return res;
        })
        .catch((e) => {
            console.log("Error: ", e);
        });
}
