import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useApi } from "hooks/useApi";
import { Btn, Clickable, ButtonContainer, CloseDiv, DropdownItem, Footer, LetterCircle, ModalBackground, ModalTopContainter, Scroll, Title, UsersRow, Wrapper } from "./styles";
import { FormSelect } from "components/common/formSelect";
import { RootState } from "redux/reducers";
import { SendIcon, TrashIcon } from "assets";
import { deleteCollaboratorReq, getCollaboratorsReq, inviteCollaboratorsReq, inviteNewCollaboratorsReq } from "api/boards";
import { Collaborator } from "components/kanbanBoard";
import { User } from "redux/slices/auth";
import { userRoleType } from "utils";
import { ModalContainer } from "components/signupForm /styles";
import { Button } from "components";
import CloseSvg from "assets/svgs/CloseSvg";
import UserModalView from "components/userModalView";


interface ModalType {
    handleClose: () => void;
    boardId: string;
    collaborators: Collaborator[]
}

interface CollaboratorType {
    id: string;
    value: string;
    role: string;
}

interface NewUserType {
    firstName: string;
    lastName: string;
    userEmail: string;
    role: string;
}

export const ModalCollaborators = ({ handleClose, boardId }: ModalType) => {
    const profile = useSelector((state: RootState) => state.auth);
    const { t } = useTranslation();
    const { users } = useSelector((state: RootState) => state.users);

    const [userOptions, setUserOptions] = useState([]);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [addedUsers, setAddedUsers] = useState([]);

    const { data: collaborators, request: fetchCollaborators }: { data: Collaborator[], request: any } = useApi({ apiFunc: getCollaboratorsReq });
    const { request: deleteCollaborator } = useApi({ apiFunc: deleteCollaboratorReq, onSuccess: () => fetchCollaborators({ boardId }) });
    const { request: inviteCollaborators, isSuccess } = useApi({ apiFunc: inviteCollaboratorsReq });
    const { request: inviteNewCollaborators, isSuccess: newCollaboratorIsSuccess, error: newCollaboratorError } = useApi({ apiFunc: inviteNewCollaboratorsReq, onSuccess: () => { fetchCollaborators({ boardId }); setIsUserModalOpen(false) } });


    const getCollaborateOptions = ({ users }: { users: User[] }) => users
        .filter((user) => user.invitation_status === "verified" && (user.role === userRoleType.ADMINISTRATOR || user.role === userRoleType.COLLABORATOR))
        .map((item) => ({
            id: item.id,
            value: `${item.first_name} ${item.last_name}`,
            role: item.role,
        }));

    useEffect(() => {
        if (users && collaborators) setUserOptions(
            getCollaborateOptions({ users }).filter(
                (user) => !collaborators?.some((collab) => String(collab.collaborator_id) === String(user.id) || profile.user.id === user.id)
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, collaborators]);

    useEffect(() => {
        fetchCollaborators({ boardId })
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [])

    const onInviteToChange = (value: CollaboratorType) => setAddedUsers((prev) =>
        prev.some((user) => user?.id === value?.id) ? prev : [...prev, value]
    );

    const renderItem = (item: CollaboratorType, onSelectItem: (item: any) => any) => <DropdownItem
        key={item.id}
        onClick={() => {
            onInviteToChange({ id: item.id, value: item.value, role: item.role });
            onSelectItem(item);
        }}
    >
        <UsersRow>
            <LetterCircle>{item.value.charAt(0)}</LetterCircle>
            {item.value}
        </UsersRow>
    </DropdownItem>

    const handleSubmit = () => inviteCollaborators(boardId, {
        boardId,
        users: addedUsers.map((addedUser) => ({
            collaboratorId: addedUser.id,
        })),
    });

    const handleInviteUser = (newUser: NewUserType) => inviteNewCollaborators(boardId, {
        boardId,
        newUser
    });

    return (
        <ModalBackground>
            <ModalContainer>
                <ModalTopContainter>
                    <h2>{t("collaborators-screen.title")}</h2>
                    <CloseDiv onClick={handleClose}>
                        <CloseSvg />
                    </CloseDiv>
                </ModalTopContainter>
                <Wrapper>
                    <FormSelect
                        label={t("collaborators-screen.label")}
                        placeholder={t("collaborators-screen.placeholder")}
                        items={userOptions}
                        renderButton={() => <ButtonContainer> <Button label={t("collaborators-screen.invite")} onClick={() => setIsUserModalOpen(true)} /></ButtonContainer>}
                        renderItem={renderItem} />
                    <Title>{t("collaborators-screen.subtitle")}</Title>
                    <Scroll>
                        {collaborators?.map((collaborator) => (
                            <UsersRow key={collaborator.collaborator_id}>
                                <UsersRow>
                                    <LetterCircle>{collaborator.first_name.charAt(0)}</LetterCircle>
                                    {collaborator.first_name} {collaborator.last_name}
                                </UsersRow>
                                <Clickable onClick={() => deleteCollaborator({ id: collaborator.board_collaborator_id })}>
                                    <img src={TrashIcon} alt={t("collaborators-screen.delete")} />
                                </Clickable>
                            </UsersRow>
                        ))}
                        {addedUsers?.map((addedUser) => (
                            <UsersRow key={addedUser.id}>
                                <UsersRow>
                                    <LetterCircle>{addedUser.value.charAt(0)}</LetterCircle>
                                    {addedUser.value}
                                </UsersRow>
                                <Clickable
                                    onClick={() =>
                                        setAddedUsers((prev) =>
                                            prev.filter((user) => user?.id !== addedUser?.id)
                                        )
                                    }
                                >
                                    <img src={TrashIcon} alt={t("collaborators-screen.delete")} />
                                </Clickable>
                            </UsersRow>
                        ))}
                    </Scroll>
                </Wrapper>
                <Footer>
                    <Btn>
                        <Button disabled={!addedUsers?.length} width="280px"
                            icon={() => <img src={SendIcon} alt={t("collaborators-screen.send")} />}
                            onClick={handleSubmit}
                            label={isSuccess ? t("collaborators-screen.success-button") : t("collaborators-screen.button")} />
                    </Btn>
                </Footer>
                {isUserModalOpen && (
                    <Modal open={isUserModalOpen} onClose={() => setIsUserModalOpen(false)}>
                        <UserModalView
                            getInitialState={() => ({
                                user: {
                                    firstName: "",
                                    lastName: "",
                                    userEmail: "",
                                    role: "collaborator",
                                },
                            })}
                            roleDisabled
                            onSubmit={(userData) => handleInviteUser(userData.user)}
                            error={newCollaboratorError}
                            onClose={() => setIsUserModalOpen(false)}
                            createSuccess={newCollaboratorIsSuccess}
                        />
                    </Modal>
                )}
            </ModalContainer>
        </ModalBackground>

    );
}
