import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";

import { Title } from "components";
import MainLayout from "layouts/main";
import {
  Amount,
  ButtonsRow,
  CardsContainer,
  Circle,
  Date,
  Label,
  LeftPanel,
  Link,
  RightPanel,
  Role,
  Row,
  Subtitle,
  SText,
  TitleContainer,
  Wrapper,
} from "./styles";
import { useApi } from "hooks/useApi";
import {
  CreateTrainingParams,
  archiveTraining,
  getTrainingById,
  updateTraining,
} from "api/trainings";
import { Avatar } from "components/Avatar";
import { UserIcon, UserDark } from "assets";
import { formatDate } from "utils";
import TrainingModalView from "components/trainingModalView";
import ButtonHighlight from "components/common/buttonHighlit";
import { RootState } from "redux/reducers";

export type Module = {
  completed_by: {
    completed_at: string;
    first_name: string;
    id: number;
    last_name: string;
    profile_image: string;
    role: string;
  }[];
  created_at: string;
  description: string;
  id: number;
  is_active: boolean;
  title: string;
  url: string;
};

const ManageTraining = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { app } = useSelector((state: RootState) => state.app);
  const id = window.location.pathname.split("/")[3];

  const [modalVisible, setModalVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(null);

  const { request, data }: any = useApi({ apiFunc: getTrainingById });
  const { request: performUpdate } = useApi({
    apiFunc: updateTraining,
    onSuccess: () => request({ id }),
  });
  const { request: performArchive } = useApi({
    apiFunc: archiveTraining,
    onSuccess: () => request({ id }),
  });

  useEffect(() => {
    request({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    window.open(data.url, "_blank");
  };

  const handleEdit = (module: CreateTrainingParams) => {
    setItem(module);
    setEdit(true);
    setModalVisible(true);
  };

  const onSubmitHandler = async (formData: CreateTrainingParams) => {
    performUpdate({
      ...formData,
      id: item?.id,
    });
    setModalVisible(false);
  };

  return (
    <MainLayout>
      <TitleContainer>
        <Title title={data?.title} />
        <Subtitle>
          <Row name>
            <Circle isActive={data?.is_active} />
            <Label>
              {data?.is_active
                ? t("required-training-screen.active")
                : t("required-training-screen.archived")}
            </Label>
          </Row>
          <div>
            {t("manage-training-screen.added")}
            {formatDate(data?.created_at)}
          </div>
        </Subtitle>
      </TitleContainer>
      <CardsContainer>
        <Wrapper active archived={!data?.is_active}>
          <LeftPanel>
            <div>{t("manage-training-screen.description")}</div>
            <div>{data?.description}</div>
            <div>
              {t("manage-training-screen.link")}
              <Link onClick={handleClick}>{data?.url}</Link>
            </div>
          </LeftPanel>
          <RightPanel>
            {data?.is_active && (
              <ButtonsRow>
                <ButtonHighlight
                  width={90}
                  height={36}
                  onClick={() => handleEdit(data)}
                  theme='primaryButtonPlain'
                >
                  {t("required-training-screen.edit")}
                </ButtonHighlight>
                <ButtonHighlight
                  width={90}
                  height={36}
                  onClick={() =>
                    performArchive({ id: data.id, user_id: user.id })
                  }
                  theme='primaryButtonPlain'
                >
                  {t("required-training-screen.archive")}
                </ButtonHighlight>
              </ButtonsRow>
            )}
            <div>{t("manage-training-screen.completed")}</div>
            <Amount>
              <Amount white>{data?.completed_by?.length || 0}</Amount>/
              {(data?.completed_by?.length || 0) +
                (data?.not_completed_by?.length || 0)}
            </Amount>
          </RightPanel>
        </Wrapper>
        <Wrapper>
          <SText big>
            {`${t("manage-training-screen.agents-not-completed")} ${
              data?.not_completed_by?.length || 0
            }`}
          </SText>
          <Row start wrap>
            {data?.not_completed_by?.map((user) => (
              <Row start>
                <Avatar
                  key={user.id}
                  src={user.profile_image || app.darkMode ? UserIcon : UserDark}
                />
                <SText>{`${user.first_name} ${user.last_name} `}</SText>
                <Role>{user.role}</Role>
              </Row>
            ))}
          </Row>
        </Wrapper>
        <Wrapper>
          <SText big>
            {`${t("manage-training-screen.agents-completed")} ${
              data?.completed_by?.length || 0
            }`}
          </SText>
          <Row start wrap>
            {data?.completed_by?.map((user) => (
              <Row start>
                <Avatar
                  key={user.id}
                  src={user.profile_image || app.darkMode ? UserIcon : UserDark}
                />
                <div>
                  <Row start name>
                    <SText>{`${user.first_name} ${user.last_name} `}</SText>
                    <Role>{user.role}</Role>
                  </Row>
                  <Date>
                    {t("manage-training-screen.completed-at")}{" "}
                    {formatDate(user.completed_at)}
                  </Date>
                </div>
              </Row>
            ))}
          </Row>
        </Wrapper>
      </CardsContainer>
      {modalVisible && (
        <Modal
          open={modalVisible}
          onClose={() => setModalVisible((prev) => !prev)}
        >
          <TrainingModalView
            edit={edit}
            getInitialState={() => ({
              title: item?.title || "",
              description: item?.description || "",
              url: item?.url || "",
            })}
            onSubmit={onSubmitHandler}
            onClose={() => setModalVisible((prev) => !prev)}
          />
        </Modal>
      )}
    </MainLayout>
  );
};
export default ManageTraining;
