import { v4 as uuidv4 } from "uuid";

import api from ".";
import { addMessage } from "../redux/slices/chat";
import store from "../redux/store";

const { dispatch } = store;

export const askChatQuestion = (body: { question: string; tableName: string; }) =>
  api
    .post("/llm/chat-completion", body)
    .then((res) => {
      dispatch(
        addMessage({
          message: {
            id: uuidv4(),
            sender: "Chatbot",
            response: res?.data,
          },
          tableName: body.tableName
        })
      );
      return res;
    })
    .catch((e) => {
      console.log("Error: ", e);
    });

export const csvUpload = (formData: any) =>
  api
    .post("/llm/csv-upload", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log("Error: ", e);
    });

export const getTables = () =>
  api
    .get("/llm/tables")
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log("Error: ", e);
    });

export const askChatTableQuestion = (body: { question: string; tableName: string; }) =>
  api
    .post("/llm/chat-completion-table", body)
    .then((res) => {
      dispatch(
        addMessage({
          tableName: body.tableName,
          message: {
            id: uuidv4(),
            sender: "Chatbot",
            response: res?.data,
          }
        })
      );
      return res;
    })
    .catch((e) => {
      console.log("Error: ", e);
    });