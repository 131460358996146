import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";

import { Container } from "./styles";
import MainLayout from "layouts/main";
import { Button, Title } from "components";
import { getPermissions, userRoleType } from "utils";
import { RootState } from "redux/reducers";
import { useEffect, useState } from "react";
import BoardModalView from "components/modalViews/boardModalView";
import { createBoard, getBoards } from "api/boards";
import { useApi } from "hooks/useApi";
import { BoardItem } from "components/boardItem";
import { ROUTES } from "utils/routes";

const KanbanBoards = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.auth);
  const [modalVisible, setModalVisible] = useState(false);
  const hasPermission = getPermissions([userRoleType.ADMINISTRATOR], user.role);
  const { request, data, loading }: any = useApi({ apiFunc: getBoards });
  const hasBoards = data?.boards?.length > 0;
  const { request: postBoard }: any = useApi({
    apiFunc: createBoard,
    onSuccess: () => {
      setModalVisible(false);
      request();
    },
  });

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      {!loading && (
        <Title
          title={t("kanban-boards-screen.title")}
          actions={
            hasPermission &&
            <Button
              width='230px'
              onClick={() => setModalVisible(true)}
              label={t("kanban-screen.button")}
            />
          }
        />
      )}
      {!loading && hasBoards ? (
        data?.boards?.map((board: { id: any; }) => <BoardItem item={board} onClick={() => history.push(`${ROUTES.kanban}/${board.id}`)} />)
      ) : (
        !loading && <Container>{t("kanban-screen.subtitle")}</Container>
      )}
      {modalVisible && (
        <Modal
          open={modalVisible}
          onClose={() => setModalVisible((prev) => !prev)}
        >
          <BoardModalView
            onSubmit={(name) => postBoard({ name })}
            onClose={() => setModalVisible(false)}
          />
        </Modal>
      )}
    </MainLayout>
  );
};
export default KanbanBoards;
