import styled from "styled-components";

export const Wrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  border-radius: 7px;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  padding: 24px;
  margin-bottom: 8px;
  width: 100%;
  box-shadow: ${({ isActive }) =>
    !isActive
      ? "none"
      : `0 0 0 2px ${({ theme }) => theme.palette.secondaryButton}`};
`;

export const Title = styled.div`
  font-size: 20px;
  padding: 0 0 10px 0;
  color: ${({ theme }) => theme.palette.text};
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Text = styled.div<{ small?: boolean; status?: boolean }>`
  font-size: ${({ small }) => (small ? "15px" : "18px")};
  color: ${({ theme, status }) =>
    status ? theme.palette.text : theme.palette.subtitle};
  margin-bottom: ${({ status }) => (status ? "8px" : "12px")};
`;

export const TitleContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`;

export const Date = styled.div`
  font-size: 18px;
  text-align: end;
  color: ${({ theme }) => theme.palette.buttonDisabled};
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const Circle = styled.div<{ isActive?: boolean }>`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.palette.secondaryButton : "transparent"};
  border: ${({ isActive, theme }) =>
    isActive ? "none" : `1px solid ${theme.palette.border}`};
  height: 10px;
  width: 10px;
  border-radius: 25px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const AvatarRow = styled.div`
  display: flex;
  gap: 16px;
  cursor: pointer;
`;

export const Pointer = styled.div`
  cursor: pointer;
`;
