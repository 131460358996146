import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CloseDiv, Form, ModalBackground, ModalContainer, ModalTopContainter } from './styles';
import CloseSvg from 'assets/svgs/CloseSvg';
import { Button, Input } from 'components';
import PlusSvg from 'assets/svgs/PlusSvg';
import { RootState } from 'redux/reducers';

const AnnouncementModalView = ({ edit, getInitialState, requestId, onSubmit, onClose }) => {
    const { app } = useSelector((state: RootState) => state.app);
    const initialState = getInitialState();
    const [announcement, setAnnouncement] = useState(initialState.announcement || "");
    const { t } = useTranslation();

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onSubmit({ announcement, requestId });
    };

    return (
        <ModalBackground>
            <ModalContainer>
                <ModalTopContainter>
                    <h2>{edit ? t("announcements-screen.item.edit") : t("announcements-screen.item.new")}</h2>
                    <CloseDiv onClick={onClose}><CloseSvg fill={app.darkMode ? undefined : "#222"} /></CloseDiv>
                </ModalTopContainter>
                <Form onSubmit={handleSubmit}>
                    <Input value={announcement} label={t("announcements-screen.item.button.add")} handleChange={(e) => setAnnouncement(e.target.value)} required multiline placeholder={`${t("announcements-screen.item.button.add")}...`} />
                    <Button disabled={!announcement.trim()} width="230px"
                        icon={() => <PlusSvg fill={!announcement.trim() ? undefined : '#222222'} />}
                        onClick={handleSubmit}
                        label={edit ? t("announcements-screen.item.button.update") : t("announcements-screen.item.button.post")} />
                </Form>
            </ModalContainer>
        </ModalBackground>
    );
};

export default AnnouncementModalView;

