import { Wrapper, Event, Text } from "./styles";

export const OutlineButton = ({
  label,
  secondary,
  onClick,
  customStyle,
}: {
  label: string;
  onClick: () => void;
  customStyle?: React.CSSProperties;
  secondary?: boolean;
}) => {
  return (
    <Wrapper onClick={onClick} style={{ ...customStyle }}>
      <Event>{secondary}</Event>
      <Text>{label}</Text>
    </Wrapper>
  );
};
