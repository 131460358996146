/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";

import MainLayout from "../../layouts/main";
import ChatInput from "./chatInput";
import { useScroll } from "../../hooks/useScroll";
import {
  ChatContainer,
  ChatIcon,
  Container,
  MessageAvatar,
  MessageContainer,
  MessageSender,
  MessageText,
  ScrollButtonsContainer,
  Sidebar,
  Title,
  TitleContainer,
  Text,
  AddIconButton,
  HeaderRow,
  ChartIcon,
  SmallText,
  ItemButton,
  StyledTab,
  StyledTabs,
  StyledDiv,
  AddIconStyled,
  ChatbotIcon,
  LoaderContainer,
} from "./styles";
import ChatScrollButtons from "./chatScrollButtons";
import TableNameModal from "../../components/tableNameModal";
import { ROUTES } from "../../utils/routes";
import { RootState } from "../../redux/reducers";
import { useApi } from "../../hooks/useApi";
import { askChatQuestion, getTables } from "../../api/chat";
import { objectToString } from "../../utils";
import { addTab, clearMessages, clearTab, resetInitialState } from "../../redux/slices/chat";

const Chat = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    app: { darkMode },
  } = useSelector((state: RootState) => state.app);
  const { chats } = useSelector((state: RootState) => state.chat);
  const {
    messagesEndRef,
    messagesStartRef,
    handleScroll,
    isAtBottom,
    isAtTop,
    isOverflowing,
    scrollToTop,
    scrollToBottom,
  } = useScroll({});
  const { request, loading }: any = useApi({ apiFunc: askChatQuestion });
  const [open, setOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState(-1);
  const [selectedTableName, setSelectedTableName] = useState(t("chat.tab") || '');
  const [tabValue, setTabValue] = useState(0);
  const { request: tablesRequest, data }: any = useApi({ apiFunc: getTables });

  const handleChange = (tableName: string, index: number) => {
    setTabValue(index);
    setSelectedTableName(tableName);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isChatbot = (sender: string) => sender === "Chatbot";

  const onClickListItem = (index: number, text: string) => {
    setSelectedTable(index);
    dispatch(addTab({ tableName: text }));
    const tabIndex = Object.keys(chats)?.indexOf(text);
    setTabValue(tabIndex !== -1 ? tabIndex : (Object.keys(chats).length));
    setSelectedTableName(text);
  };

  const handleSendMessage = async (question: string) => {
    await request({ question, tableName: selectedTableName });
  };

  useEffect(() => {
    if (chats?.[selectedTableName]?.messages?.length) scrollToBottom();
    // eslint-disable-next-line
  }, [chats?.[selectedTableName]?.messages, scrollToBottom]);

  useEffect(() => {
    // eslint-disable-next-line
    tablesRequest();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!chats) dispatch(resetInitialState())
  }, [chats]);

  return (
    <MainLayout
      backgroundStyles={{ paddingBottom: "0px" }}
      styles={{
        overflow: "clip",
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        marginTop: "0px",
        padding: "0px",
      }}
      showFabButton={false}
    >
      <Sidebar>
        <HeaderRow>
          <Text>{t("chat.sidebar-title")}</Text>
          <AddIconButton
            onClick={handleOpen}
            sx={{
              backgroundColor: darkMode ? "white" : "black",
            }}
          >
            <AddIconStyled
              sx={{
                fill: darkMode ? "black" : "white",
              }}
            />
          </AddIconButton>
        </HeaderRow>
        <List sx={{ overflowY: "auto", paddingBottom: "50px" }}>
          {data?.tables?.filter?.((t: string) => t !== 'db_version')?.map((text: string, index: number) => (
            <ListItem key={text} disablePadding>
              <ItemButton
                darkMode={darkMode}
                selected={selectedTable === index}
                onClick={() => onClickListItem(index, text)}
              >
                <ListItemIcon>
                  <ChartIcon />
                </ListItemIcon>
                <SmallText>{text}</SmallText>
              </ItemButton>
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <Container>
        {tabValue !== -1 && !!chats?.[selectedTableName]?.messages?.length && (
          <ScrollButtonsContainer>
            <ChatScrollButtons
              isAtTop={isAtTop}
              isAtBottom={isAtBottom}
              isOverflowing={isOverflowing}
              scrollToTop={scrollToTop}
              scrollToBottom={scrollToBottom}
            />
          </ScrollButtonsContainer>
        )}
        <StyledTabs value={tabValue}>
          {chats ? Object.keys(chats)?.map((tableName, index) => (
            <StyledDiv onClick={() => handleChange(tableName, index)}>
              <StyledTab label={tableName} key={index} />
              <Tooltip title="Clear Chat" arrow>
                <IconButton onClick={() => { dispatch(tableName === t("chat.tab") ? clearMessages(tableName) : clearTab({ tableName })); handleChange(t("chat.tab"), 1) }}>
                  <ClearIcon style={{ color: "white" }} />
                </IconButton>
              </Tooltip>
            </StyledDiv>
          )) : <></>}

        </StyledTabs>
        {tabValue !== -1 && (
          <>
            <ChatContainer onScroll={handleScroll}>
              <div ref={messagesStartRef} />
              {!!chats?.[selectedTableName]?.messages.length ? (
                chats?.[selectedTableName].messages.map((message) => (
                  <MessageContainer key={message.id}>
                    <MessageSender>
                      <MessageAvatar>
                        {isChatbot(message.sender) && <ChatbotIcon />}
                      </MessageAvatar>
                      {isChatbot(message.sender)
                        ? t("chat.tab")
                        : message.sender}
                    </MessageSender>
                    {isChatbot(message.sender) ? (
                      message.response?.map((t) => (
                        <MessageText>{objectToString(t)}</MessageText>
                      ))
                    ) : (
                      <MessageText>{message.text}</MessageText>
                    )}
                  </MessageContainer>
                ))
              ) : (
                <TitleContainer>
                  <ChatIcon />
                  <Title>{t("chat.chatbot")}</Title>
                </TitleContainer>
              )}
              {loading && (
                <LoaderContainer>
                  <CircularProgress
                    sx={{ color: "white" }}
                    style={{ width: 34, height: 34 }}
                    thickness={6}
                  />
                </LoaderContainer>
              )}
              <div ref={messagesEndRef} />
            </ChatContainer>
            <ChatInput handleSendMessage={handleSendMessage} tableName={selectedTableName} />
          </>
        )}
      </Container>
      <TableNameModal
        open={open}
        handleClose={handleClose}
        onClickButton={(tableName: string) =>
          history.push(ROUTES.uploadCsv, {
            tableName
          })
        }
      />
    </MainLayout>
  );
};


export default Chat;

