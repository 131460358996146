import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.border};
    box-shadow: 24;
    padding: 32px;
    border-radius: 20px;
`;
