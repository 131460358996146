import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";

import { Input } from "components";
import {
  ActionButton,
  ActionButtons,
  PlusButton,
  ColumnActionButtonRight,
  ColumnActionButtonLeft,
  Title,
} from "./styles";
import { useApi } from "hooks/useApi";
import { updateColumnName } from "api/boards";
import {
  CheckIcon,
  CrossIcon,
  CheckDarkIcon,
  CrossDarkIcon,
  ArrowLeftIcon,
  ArrowLeftDarkIcon,
  ArrowRightIcon,
  ArrowRightDarkIcon,
} from "assets";
import { RootState } from "redux/reducers";
import { BoardType, ColumnType } from ".";

const ColumnTitle = ({
  column,
  handleAddTask,
  board,
  updateColumnSuccess,
}: {
  column: ColumnType;
  handleAddTask: () => void;
  board: BoardType;
  updateColumnSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const { app } = useSelector((state: RootState) => state.app);
  const [columnEdit, setColumn] = useState(null);
  const [columnName, setColumnName] = useState("");
  const [columnSort, setColumnSort] = useState(null);
  const { request: putColumn }: any = useApi({
    apiFunc: updateColumnName,
    onSuccess: () => {
      setColumn(null);
      updateColumnSuccess();
    },
  });

  const getColumnIdByIndex = (i) =>
    board.columns.find((col) => col.index === i).id;

  const handleMoveColumn = (option: "left" | "right", column: ColumnType) => {
    const coef = option === "left" ? -1 : 1;
    putColumn({ index: column.index + coef }, board.id, column.id);
    putColumn(
      { index: column.index },
      board.id,
      getColumnIdByIndex(column.index + coef)
    );
  };

  return (
    <Title
      onMouseEnter={() => setColumnSort(column)}
      onMouseLeave={() => setColumnSort(false)}
    >
      {columnSort && !columnEdit && columnSort.id === column.id && (
        <>
          {column.index > 1 && (
            <ColumnActionButtonLeft
              onClick={() => handleMoveColumn("left", column)}
            >
              <img
                src={app.darkMode ? ArrowLeftIcon : ArrowLeftDarkIcon}
                alt={t("kanban-screen.board.alt-check")}
              />
            </ColumnActionButtonLeft>
          )}
          {column.index < board.columns.length && (
            <ColumnActionButtonRight
              onClick={() => handleMoveColumn("right", column)}
            >
              <img
                src={app.darkMode ? ArrowRightIcon : ArrowRightDarkIcon}
                alt={t("kanban-screen.board.alt-check")}
              />
            </ColumnActionButtonRight>
          )}
        </>
      )}
      {!!columnEdit && columnEdit.id === column.id ? (
        <>
          <Input
            styles={{ marginBottom: 0 }}
            value={columnName}
            handleChange={(e) => setColumnName(e.target.value)}
          />
          <ActionButtons>
            <ActionButton
              onClick={() =>
                putColumn({ name: columnName }, board.id, column.id)
              }
            >
              <img
                src={app.darkMode ? CheckIcon : CheckDarkIcon}
                alt={t("kanban-screen.board.alt-check")}
              />
            </ActionButton>
            <ActionButton onClick={() => setColumn(null)}>
              <img
                src={app.darkMode ? CrossIcon : CrossDarkIcon}
                alt={t("kanban-screen.board.alt-cross")}
              />
            </ActionButton>
          </ActionButtons>
        </>
      ) : (
        <div
          onClick={() => {
            setColumn(column);
            setColumnName(column.name);
          }}
        >
          {column.name}
        </div>
      )}
      {column.index === 1 && !columnEdit && (
        <PlusButton onClick={handleAddTask}>+</PlusButton>
      )}
    </Title>
  );
};

export default ColumnTitle;
