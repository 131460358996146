import styled from "styled-components";

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    margin-top: 53px;
    width: 100%;
    padding: 8px;
    background-color: ${({ theme }) => theme.palette.primary};
    &.open {
        opacity: 0.7;
        transition-duration: 400ms;
    }
`;

export const Background = styled.div`
    background-color: ${({ theme }) => theme.palette.primary};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 20px 0px;
`;
export const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: #000;
    transition: 200ms;
    transition-duration: 400ms;
    opacity: 0.7;
`;

export const FABContainer = styled.div<{ onHover?: boolean }>`
    position: fixed;
    height: 60px;
    bottom: 16px;
    right: 40px;
    width: ${({ onHover }) => (onHover ? "120px" : "60px")};
`;
