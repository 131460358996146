import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper, SelectInput, Icon, Dropdown, DropdownItem, Text } from './styles';
import { Label } from '../input/styles';
import { capitalize } from 'utils';

interface FormSelectProps {
  label?: string;
  items: { id: string; value: string }[];
  onSelect?: (item: any) => void;
  selected?: any;
  readOnly?: boolean;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  renderItem?: (item: any, onSelectItem: any) => any;
  renderButton?: () => any;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  label,
  items,
  onSelect,
  selected,
  readOnly = false,
  placeholder,
  disabled = false,
  required = false,
  renderItem,
  renderButton
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && setIsOpen(false);

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    if (!readOnly && !disabled) setIsOpen(prev => !prev);
  };

  const handleSelectItem = (item: any) => {
    onSelect?.(item);
    setIsOpen(false);
  };

  return (
    <Wrapper ref={wrapperRef}>
      {label && <Text>{label}</Text>}
      {required && <Label multiline>{t("button.required")}</Label>}
      <SelectInput onClick={toggleDropdown}>
        {selected ? capitalize(selected.value) : placeholder || t("button.placeholder")}
        <Icon isOpen={isOpen} />
      </SelectInput>
      <Dropdown isOpen={isOpen}>
        {items?.length ? items.map((item, index) => (
          renderItem ? renderItem(item, handleSelectItem) : <DropdownItem key={index} onClick={() => handleSelectItem(item.id)}>
            {capitalize(item.value)}
          </DropdownItem>
        )) : <DropdownItem>{t("button.desc")}</DropdownItem>}
        {renderButton?.()}
      </Dropdown>
    </Wrapper >
  );
};
