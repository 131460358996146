import { useState, useRef } from "react";
import ReactCrop, {
  type Crop,
  centerCrop,
  PixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import { useTranslation } from "react-i18next";
import "react-image-crop/dist/ReactCrop.css";

import {
  ButtonsContainer,
  CloseDiv,
  Container,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
} from "./styles";
import ButtonHighlight from "components/common/buttonHighlit";
import CloseSvg from "assets/svgs/CloseSvg";
import { canvasPreview } from "utils/cropImage";

const ImageCropperModalView = ({ src, onContinue, darkMode, onClose }) => {
  const { t } = useTranslation();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [crop, setCrop] = useState<Crop>();

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  };

  const onCropAndContinueClickHandler = async () => {
    const uri = await canvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      1,
      0
    );
    onContinue(uri);
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>{t("profile-screen.crop.title")}</h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Container>
          <ReactCrop
            crop={crop}
            onChange={(crop) => setCrop(crop)}
            aspect={1}
            onComplete={(c) => setCompletedCrop(c)}
            minHeight={100}
          >
            <img
              ref={imgRef}
              alt={t("profile-screen.crop.alt")}
              src={src}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Container>
        {completedCrop && (
          <canvas ref={previewCanvasRef} style={{ display: "none" }} />
        )}
        <ButtonsContainer>
          <ButtonHighlight
            height={36}
            width={230}
            theme='primaryButton'
            onClick={onCropAndContinueClickHandler}
          >
            {t("profile-screen.crop.button")}
          </ButtonHighlight>
        </ButtonsContainer>
      </ModalContainer>
    </ModalBackground>
  );
};

export default ImageCropperModalView;
