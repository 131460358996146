import { Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Column,
  Form,
  Text,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
  Row,
  Image,
  Li,
  Error,
  Icon,
  FileInput,
  ImageContainer,
} from "./styles";
import { Button, Input } from "components";
import { RootState } from "redux/reducers";
import { CheckDarkIcon, CheckIcon, UploadPhotoIcon } from "assets";
import RepeatSvg from "assets/svgs/RepeatSvg";
import { checkPassword, uploadFile } from "utils";
import ImageCropperModalView from "components/ImageCropperModalView";
import ErrorSvg from "assets/svgs/ErrorSvg";

interface ProfileParams {
  password: string;
  userEmail: string;
  photoUri: string;
  error: any;
}

const SignupForm = ({ getInitialState, onSubmit, error }) => {
  const initialState = getInitialState();
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const { app } = useSelector((state: RootState) => state.app);
  const [profile, setProfile] = useState<ProfileParams>(initialState);
  const [photoUri, setPhotoUri] = useState<string | ArrayBuffer>("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (photoUri) setModalVisible(true);
  }, [photoUri]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(profile);
  };

  const onFileChange = (e) => uploadFile(e, fileInputRef, setPhotoUri);

  const onContinue = (uri) => {
    setModalVisible(false);
    setProfile({ ...profile, photoUri: uri });
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>{t("signup-screen.title")}</h2>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Column photo>
              <ImageContainer>
                <FileInput
                  ref={fileInputRef}
                  type='file'
                  accept='image/*'
                  onChange={onFileChange}
                />
                {profile.photoUri ? (
                  <Image src={profile.photoUri} />
                ) : (
                  <Image avatar src={UploadPhotoIcon} />
                )}
              </ImageContainer>
              <Text>{t("signup-screen.input.photo")}</Text>
            </Column>
            <Column>
              <Input
                value={profile.userEmail}
                type='string'
                disabled
                label={t("signup-screen.input.email")}
                handleChange={(e) =>
                  setProfile({ ...profile, userEmail: e.target.value })
                }
                placeholder={`${t("signup-screen.input.email")}...`}
              />
              <Input
                value={profile.password}
                type='password'
                label={t("signup-screen.input.password")}
                handleChange={(e) =>
                  setProfile({ ...profile, password: e.target.value })
                }
                placeholder={`${t("signup-screen.input.password")}...`}
              />
              <Text>{t("signup-screen.description")}</Text>
              <ul className='list'>
                <Li>
                  {checkPassword(profile.password).charsLengthPassed && (
                    <Icon src={app.darkMode ? CheckIcon : CheckDarkIcon} />
                  )}
                  {t("signup-screen.verify.characters")}
                </Li>
                <Li>
                  {checkPassword(profile.password).hasNumber && (
                    <Icon src={app.darkMode ? CheckIcon : CheckDarkIcon} />
                  )}
                  {t("signup-screen.verify.number")}
                </Li>
                <Li>
                  {checkPassword(profile.password).hasSpecialChars && (
                    <Icon src={app.darkMode ? CheckIcon : CheckDarkIcon} />
                  )}
                  {t("signup-screen.verify.special")}
                </Li>
              </ul>
            </Column>
          </Row>
          <Button
            disabled={profile.userEmail && !checkPassword(profile.password).verified}
            width='300px'
            error={!!error}
            icon={() =>
              error ? (
                <ErrorSvg fill={app.darkMode ? undefined : "#222"} />
              ) : (
                <RepeatSvg
                  fill={
                    !checkPassword(profile.password).verified ? undefined : "#222222"
                  }
                  className={undefined}
                />
              )
            }
            onClick={handleSubmit}
            label={
              error ? t("signup-screen.error") : t("signup-screen.button")
            }
          />
          {error && <Error>{error}</Error>}
        </Form>
      </ModalContainer>
      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <ImageCropperModalView
          onContinue={onContinue}
          darkMode={app.darkMode}
          onClose={() => setModalVisible(false)}
          src={photoUri}
        />
      </Modal>
    </ModalBackground>
  );
};

export default SignupForm;
