const MinusNavSvg = ({
  width = 11,
  height = 11,
  fillOpacity = 0.5,
  fill = "#fff",
  ...props
}) => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 11 11'
    width={width}
    height={height}
    {...props}
  >
    <path
      opacity={fillOpacity}
      d='M1.931 4.227a.632.632 0 00-.631.631v.632c0 .348.283.63.631.631H9.51a.632.632 0 00.631-.631v-.632a.632.632 0 00-.631-.631H1.93z'
      fill={fill}
    />
  </svg>
);

export default MinusNavSvg;
