/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
    invitation_status?: string;
    user_email: string;
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    profile_image: string | null;
    role: string;
    created_at: string;
    updated_at: string;
    theme: string;
    joined_at: string | null;
    team?: string;
}
export interface AuthSliceState {
    user: User;
}

const authInitialState: AuthSliceState = {
    user: {
        user_email: "",
        id: 0,
        username: "",
        first_name: "",
        last_name: "",
        profile_image: null,
        role: "",
        created_at: "",
        updated_at: "",
        theme: "",
        joined_at: null,
    },
};

const auth = createSlice({
    name: "auth",
    initialState: authInitialState,
    reducers: {
        saveUser(state, { payload }: PayloadAction<User>) {
            state.user = payload;
        },
    },
});

export const { saveUser } = auth.actions;

export default auth.reducer;
