import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { Fab } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";

import { Background, Container, FABContainer, Main } from "./styles";
import { Header } from "components/common/header";
import { Menu } from "components";
import { RootState } from "redux/reducers";
import ProfileModalView from "components/profileModalView";
import { useApi } from "hooks/useApi";
import { updateProfile } from "api/auth";
import TableNameModal from "../../components/tableNameModal";
import UploadCSVModal from "../../components/uploadCSVModal";

export default function MainLayout({
    children,
    styles,
    backgroundStyles,
    showFabButton = true,
}: {
    children: React.ReactNode;
    styles?: React.CSSProperties;
    backgroundStyles?: React.CSSProperties;
    showFabButton?: boolean;
}) {
    const { t } = useTranslation();
    const { app } = useSelector((state: RootState) => state.app);
    const { user } = useSelector((state: RootState) => state.auth);
    const { request, error } = useApi({ apiFunc: updateProfile });
    const [profileModalVisible, setProfileModalVisible] = useState(false);
    const [onHoverFab, setOnHoverFab] = useState(false);
    const [open, setOpen] = useState(false);
    const [tableName, setTableName] = useState('');
    const [openUploadCSVModal, setOpenUploadCSVModal] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenUploadCSVModal = (name: string) => { setTableName(name); setOpenUploadCSVModal(true); }
    const handleCloseUploadCSVModal = () => setOpenUploadCSVModal(false);

    return (
        <Background style={backgroundStyles}>
            {app.drawerOpen && <Container />}
            <Header
                user={user}
                drawerOpen={app.drawerOpen}
                darkMode={app.darkMode}
            />
            <Menu
                user={user}
                drawerOpen={app.drawerOpen}
                darkMode={app.darkMode}
                handleEditProfile={() => setProfileModalVisible(true)}
            />
            {profileModalVisible && (
                <Modal
                    open={profileModalVisible}
                    onClose={() => setProfileModalVisible((prev) => !prev)}
                >
                    <ProfileModalView
                        getInitialState={() => ({
                            currentPassword: "",
                            newPassword: "",
                            profileImage: user.profile_image,
                        })}
                        error={error}
                        onSubmit={request}
                        onClose={() => setProfileModalVisible(false)}
                    />
                </Modal>
            )}
            <Main className={app.drawerOpen ? "open" : ""} style={styles}>
                {children}
                {showFabButton && (
                    <FABContainer onHover={onHoverFab}>
                        <Fab
                            variant={!onHoverFab ? "circular" : "extended"}
                            color="primary"
                            aria-label="upload"
                            onMouseEnter={() => setOnHoverFab(true)}
                            onMouseLeave={() => setOnHoverFab(false)}
                            onClick={handleOpen}
                        >
                            <FileUploadIcon />
                            {onHoverFab && t("upload-csv.upload")}
                        </Fab>
                    </FABContainer>
                )}
            </Main>
            <TableNameModal
                onClickButton={(tableName) => {
                    handleClose();
                    handleOpenUploadCSVModal(tableName);
                }}
                open={open}
                handleClose={handleClose}
            />
            <UploadCSVModal
                open={openUploadCSVModal}
                tableName={tableName}
                handleClose={handleCloseUploadCSVModal}
            />
        </Background>
    );
}
