import { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import { Body, Dash, Header, Item, Picker, PickerColor, PickerColorWrapper, Subtitle, Title, Wrapper } from './styles';

const AdminControlColors = ({ formData, onChange }) => {
  const [pickerName, setPickerName] = useState('');
  const pickerRef = useRef(null);
  const { t } = useTranslation();
  const [colors, setColors] = useState({
    primary_color: formData.primary_color,
    secondary_color: formData.secondary_color,
    affirmative_color: formData.affirmative_color,
    attention_color: formData.attention_color,
    error_color: formData.error_color,
  });

  const handleClickOutside = (event) => pickerRef.current && !pickerRef.current.contains(event.target) && setPickerName('');

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handlePickerChange = (color, name) => setColors(prev => ({ ...prev, [name]: color.hex }));

  const handlePickerChangeComplete = (color, name) => {
    setColors(prev => ({ ...prev, [name]: color.hex }));
    onChange({ name, value: color.hex });
  };

  const renderItem = (name: string, title: string, subtitle: string) => (
    <Item ref={pickerRef}>
      <Dash />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <PickerColorWrapper>
        <PickerColor
          style={{ backgroundColor: colors[name] || formData[name] }}
          onClick={() => setPickerName(name)}
        />
        {pickerName === name && (
          <Picker onClick={(event: any) => event.stopPropagation()}>
            <ChromePicker
              disableAlpha
              color={colors[name]}
              onChange={color => handlePickerChange(color, name)}
              onChangeComplete={color => handlePickerChangeComplete(color, name)}
            />
          </Picker>
        )}
      </PickerColorWrapper>
    </Item>
  );

  return (
    <Wrapper>
      <Header>{t("admin-controls-colors-screen.title")}</Header>
      <Body>
        {renderItem('primary_color', t("admin-controls-colors-screen.colors.primary_color.label"), t("admin-controls-colors-screen.colors.primary_color.desc"))}
        {renderItem('secondary_color', t("admin-controls-colors-screen.colors.secondary_color.label"), t("admin-controls-colors-screen.colors.secondary_color.desc"))}
        {renderItem('affirmative_color', t("admin-controls-colors-screen.colors.affirmative_color.label"), t("admin-controls-colors-screen.colors.affirmative_color.desc"))}
        {renderItem('attention_color', t("admin-controls-colors-screen.colors.attention_color.label"), t("admin-controls-colors-screen.colors.attention_color.desc"))}
        {renderItem('error_color', t("admin-controls-colors-screen.colors.error_color.label"), t("admin-controls-colors-screen.colors.error_color.desc"))}
      </Body>
    </Wrapper>
  );
};

export default AdminControlColors;


