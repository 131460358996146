import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Background } from "./styles";
import { Button, LoginForm } from "components";
import { useApi } from "hooks/useApi";
import { signinUser } from "api/auth";
import { ROUTES } from "utils/routes";

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form, setForm] = useState({
    userEmail: "",
    password: "",
  });
  const { request, error }: any = useApi({ apiFunc: signinUser });

  return (
    <Background>
      <LoginForm
        title={t("login-screen.title")}
        inputs={[
          {
            value: form.userEmail,
            handleChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, userEmail: e.currentTarget.value }),
            label: t("login-screen.email"),
            required: true,
          },
          {
            value: form.password,
            handleChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, password: e.currentTarget.value }),
            label: t("login-screen.password"),
            required: true,
            type: "password",
          },
        ]}
        button={
          <Button
            label={t("login-screen.button")}
            width='232px'
            onClick={() => {
              request({
                ...form,
                callback: () => history.push(ROUTES.dashboard),
              });
            }}
            disabled={!form.userEmail || !form.password}
          />
        }
        link={{
          label: t("login-screen.link"),
          handleClick: () => history.push(ROUTES.forgotPassword),
        }}
        error={
          error?.passwordincorrect ||
          error?.userEmail ||
          error?.emailnotfound ||
          error?.password
        }
      />
    </Background>
  );
};
export default Login;
