import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AssignToContainer,
  ButtonsContainer,
  ButtonsRightContainer,
  CloseDiv,
  DropdownItem,
  Form,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
  UsersRow,
} from "./styles";
import CloseSvg from "assets/svgs/CloseSvg";
import { Button, FormSelect, Input } from "components";
import PlusSvg from "assets/svgs/PlusSvg";
import { RootState } from "redux/reducers";
import { BoardType, Collaborator, TaskType } from "components/kanbanBoard";
import { LetterCircle } from "components/modalCollaborator/styles";
import { useApi } from "hooks/useApi";
import { getCollaboratorsReq, updateTask } from "api/boards";

type TaskDetailsModalViewProps = {
  onClose: () => void;
  task: TaskType;
  hasNext: boolean;
  hasPrev: boolean;
  board: BoardType;
  onSuccess?: () => void;
};

const TaskDetailsModalView = ({
  onClose,
  task,
  hasPrev,
  hasNext,
  board,
  onSuccess,
}: TaskDetailsModalViewProps) => {
  const { app } = useSelector((state: RootState) => state.app);
  const { users } = useSelector((state: RootState) => state.users);
  const [name, setName] = useState<string>(task.name);
  const [description, setDescription] = useState<string>(task.description);
  const { t } = useTranslation();

  const {
    data: collaborators,
    request: fetchCollaborators,
  }: { data: Collaborator[]; request: any } = useApi({
    apiFunc: getCollaboratorsReq,
  });
  const { request: putTask }: any = useApi({
    apiFunc: updateTask,
    onSuccess: () => {
      onSuccess();
    },
  });

  const handleMoveTask = (option: "next" | "prev") => {
    const coef = option === "prev" ? -1 : 1;
    const currentColumn = board.columns.find(
      (col) => col.id === task.column_id
    );
    const column = board.columns.find(
      (col) => col.index === currentColumn.index + coef
    );
    putTask({ column_id: column.id }, board.id, task.id);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    putTask({ name, description }, board.id, task.id);
  };

  const handleAssign = (userId: string) => {
    putTask({ assigned_to: userId }, board.id, task.id);
  };

  const getUserName = (userId: string) => {
    const user = users?.find((u) => String(u.id) === String(userId));
    return user ? `${user.first_name} ${user.last_name}` : "-";
  };

  useEffect(
    () => {
      fetchCollaborators({ boardId: board.id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderItem = (item: any, onSelectItem: (item: any) => any) => (
    <DropdownItem
      key={item.id}
      onClick={() => {
        handleAssign(item.id);
        onSelectItem(item);
      }}
    >
      <UsersRow>
        <LetterCircle>{item.value.charAt(0)}</LetterCircle>
        {item.value}
      </UsersRow>
    </DropdownItem>
  );

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>{t("kanban-screen.board.modal-task-details.title")}</h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={app.darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <Input
            value={name}
            label={t("kanban-screen.board.modal-task-details.inputs.name")}
            handleChange={(e) => setName(e.target.value)}
            required
            placeholder={`${t(
              "kanban-screen.board.modal-task-details.inputs.name"
            )}...`}
          />
          <Input
            value={description}
            label={t(
              "kanban-screen.board.modal-task-details.inputs.description"
            )}
            handleChange={(e) => setDescription(e.target.value)}
            required
            placeholder={`${t(
              "kanban-screen.board.modal-task-details.inputs.description"
            )}...`}
          />
          <AssignToContainer>
            <FormSelect
              label={t(
                "kanban-screen.board.modal-task-details.assign-to.label"
              )}
              selected={{
                id: task.assigned_to,
                value: getUserName(task.assigned_to),
              }}
              placeholder={t(
                "kanban-screen.board.modal-task-details.assign-to.placeholder"
              )}
              items={collaborators
                ?.map((c) => ({
                  value: `${c.first_name} ${c.last_name}`,
                  id: c.collaborator_id,
                }))
                .filter((u) => u.id !== task.assigned_to)}
              renderItem={renderItem}
            />
          </AssignToContainer>
          <ButtonsContainer>
            <Button
              disabled={!hasPrev}
              width='150px'
              onClick={() => handleMoveTask("prev")}
              label={t("kanban-screen.board.modal-task-details.prev")}
            />
            <ButtonsRightContainer>
              <Button
                disabled={!(name?.trim() || description?.trim())}
                width='150px'
                icon={() => (
                  <PlusSvg
                    fill={
                      !(name?.trim() || description?.trim()) || !app.darkMode
                        ? undefined
                        : "#222"
                    }
                  />
                )}
                onClick={handleSubmit}
                label={t("kanban-screen.board.modal-task-details.edit")}
              />
              <Button
                disabled={!hasNext}
                width='150px'
                styles={{ marginLeft: "20px" }}
                onClick={() => handleMoveTask("next")}
                label={t("kanban-screen.board.modal-task-details.next")}
              />
            </ButtonsRightContainer>
          </ButtonsContainer>
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default TaskDetailsModalView;
