import styled from 'styled-components';

export const Wrapper = styled.div<{ isActive?: boolean }>`
  margin: 10px 0 15px 0;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  border-radius: 7px;
  padding: 15px 30px;
  box-shadow: 0 0 0px 2px ${({ theme }) => theme.palette.green};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  padding: 6px 0;
  align-items: center;
  justify-content: flex-start;
`;

export const Column = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding: 4px 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.buttonDisabled};

  &.center {
    font-size: 18px;
    margin-left: 10px;
    color: ${({ theme }) => theme.palette.link};
  }
`;