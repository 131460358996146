import classnames from 'classnames';

const CloseThinSvg = ({
  width = 14,
  height = 14,
  fill = '#fff',
  className = "",
  ...props
}) => (
  <div style={{width, height}} className={classnames("svg-wrapper", className)}>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M13.613 2.52a1 1 0 00-1.415-1.414l-4.17 4.17a1 1 0 01-1.415 0l-4.17-4.17A1 1 0 001.026 2.52l4.171 4.17a1 1 0 010 1.415l-4.17 4.17a1 1 0 001.414 1.415l4.171-4.17a1 1 0 011.414 0l4.171 4.17a1 1 0 001.415-1.415l-4.17-4.17a1 1 0 010-1.415l4.17-4.17z"
        fill={fill}
      />
    </svg>
  </div>
)

export default CloseThinSvg;
