import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px 20px;
  min-height: 400px;
`;

export const DropdownContainer = styled.div<{ fixed?: boolean }>`
  left: 0;
  right: 0;
  top: 100%;
  z-index: 5;
  margin: 8px 0 0 0;
  position: absolute;
  max-height: 405px;
  border-radius: 7px;
  background: ${props=> props.theme.palette.primary};
  box-shadow: 0 0 20px 0 ${props=> props.theme.palette.link};
  ${(props) => props.fixed && "height: 405px;"}
`;

export const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 8px 15px;
  color: ${props=> props.theme.palette.buttonDisabled};
  cursor: pointer;
  &:hover {
    color: ${props=> props.theme.palette.text};
  }
  &.active {
    color: ${props=> props.theme.palette.text};
  }
`;

export const DropdownItemNoMatch = styled(DropdownItem)`
  pointer-events: none;
`;

export const UsersRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
  justify-content: space-between;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.darkBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const CloseDiv = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.input};
  height: 100%;
  align-items: center;
  justify-content: end;
  align-content: center;
  display: flex;
  width: 80px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const ModalTopContainter = styled.div`
  height: 72px;
  min-height: 72px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0 0px 0 24px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.text};
  border-bottom: 1px solid ${({ theme }) => theme.palette.input};
`;

export const ModalContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 6px ${({ theme }) => theme.palette.darkBlack};
  width: 70%;
  max-width: 800px;
  color: white;
  background: ${({ theme }) => theme.palette.primaryHighlight};
`;

export const LetterCircle = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 16px;
  align-items: center;
  border: 1px solid ${props=> props.theme.palette.button};
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  margin-right: 6px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${props=> props.theme.palette.text};
  margin-top: 40px;
`;

export const Scroll = styled.div`
  max-height: 300px;
  overflow: scroll;
`;

export const Footer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${props=> props.theme.palette.input};
`;

export const Btn = styled.div`
  align-items: flex-end;
  display: flex;
`;

export const ButtonContainer = styled.div`
  padding: 15px;
`;

export const Line = styled.div`
  opacity: 0.5;
  border: 1px solid ${props=> props.theme.palette.secondary};
  width: 100%;
`;

export const Clickable = styled.div`
  cursor: pointer;
`;