import { Title, Container, Bar, ActionsDiv, Wrap } from "./styles";

export const TitleComponent = ({ title, actions }: { title: string; actions?: any }) => {
  return (
    <Container>
      <Wrap>
        <Bar />
        <Title>{title}</Title>
      </Wrap>
      <ActionsDiv>
        {actions}
      </ActionsDiv>
    </Container>
  );
};
