export const ROUTES = {
    login: "/sign-in",
    signup: "/sign-up/invitation",
    forgotPassword: "/forgot-password",
    home: "/",
    dashboard: "/dashboard",
    trainingModules: "/training/modules",
    announcements: "/announcements",
    manageTrainings: "/manage/trainings",
    manageTraining: "/manage/trainings/:id/module",
    manageUsers: "/manage/users",
    manageAdminControls: "/manage/admin-controls",
    reports: "/reports",
    notifications: "/notifications",
    chat: "/chat",
    kanban: '/kanban',
    kanbanBoards: '/kanban-boards',
    uploadCsv: "/chat/upload-csv",
    reviewTableStructure: "/chat/review-table-structure",
};
