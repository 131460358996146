import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CloseDiv,
  Form,
  FormRow,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
} from "./styles";
import CloseSvg from "assets/svgs/CloseSvg";
import { Button, Input } from "components";
import PlusSvg from "assets/svgs/PlusSvg";
import { CreateTrainingParams } from "api/trainings";
import { RootState } from "redux/reducers";

const TrainingModalView = ({ edit, getInitialState, onSubmit, onClose }) => {
  const { app } = useSelector((state: RootState) => state.app);
  const initialState = getInitialState();
  const [training, setTraining] = useState<CreateTrainingParams>(initialState);
  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(training);
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>
            {edit
              ? t("required-training-screen.modal.title.edit")
              : t("required-training-screen.modal.title.new")}
          </h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={app.darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <FormRow>
            <Input
              value={training.title}
              label={t("required-training-screen.modal.inputs.title")}
              handleChange={(e) =>
                setTraining({ ...training, title: e.target.value })
              }
              required
              placeholder={`${t(
                "required-training-screen.modal.inputs.title"
              )}...`}
            />
            <Input
              value={training.url}
              label={t("required-training-screen.modal.inputs.url")}
              handleChange={(e) =>
                setTraining({ ...training, url: e.target.value })
              }
              required
              placeholder={`${t(
                "required-training-screen.modal.inputs.url-placeholder"
              )}...`}
            />
          </FormRow>
          <Input
            value={training.description}
            label={t("required-training-screen.modal.inputs.description")}
            handleChange={(e) =>
              setTraining({ ...training, description: e.target.value })
            }
            multiline
            placeholder={`${t(
              "required-training-screen.modal.inputs.description-placeholder"
            )}...`}
          />
          <Button
            disabled={!(training.title?.trim() && training.url?.trim())}
            width='230px'
            icon={() => (
              <PlusSvg
                fill={
                  (!(training.title?.trim() && training.url?.trim()) || !app.darkMode)
                    ? undefined
                    : "#222"
                }
              />
            )}
            onClick={handleSubmit}
            label={
              edit
                ? t("required-training-screen.modal.button.edit")
                : t("required-training-screen.modal.button.new")
            }
          />
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default TrainingModalView;
