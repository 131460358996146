import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";

import { Button, Title } from "components";
import MainLayout from "layouts/main";
import { Section, Subtitle, Text, TitleContainer } from "./styles";
import { useApi } from "hooks/useApi";
import {
  CreateTrainingParams,
  archiveTraining,
  createTraining,
  getTrainingModules,
  updateTraining,
} from "api/trainings";
import { RequiredTrainingCard } from "components/requiredTrainingCard";
import TrainingModalView from "components/trainingModalView";
import { RootState } from "redux/reducers";

export type Module = {
  completed_by: {
    completed_at: string;
    first_name: string;
    id: number;
    last_name: string;
    profile_image: string;
    role: string;
  }[];
  created_at: string;
  description: string;
  id: number;
  is_active: boolean;
  title: string;
  url: string;
};

const ManageTrainings = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const [modalVisible, setModalVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(null);

  const { request, data }: any = useApi({ apiFunc: getTrainingModules });
  const { request: performPost } = useApi({
    apiFunc: createTraining,
    onSuccess: () =>
      request({
        skip: 0,
        limit: 30,
      }),
  });
  const { request: performUpdate } = useApi({
    apiFunc: updateTraining,
    onSuccess: () =>
      request({
        skip: 0,
        limit: 30,
      }),
  });
  const { request: performArchive } = useApi({
    apiFunc: archiveTraining,
    onSuccess: () =>
      request({
        skip: 0,
        limit: 30,
      }),
  });

  useEffect(() => {
    request({
      skip: 0,
      limit: 30,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (module: CreateTrainingParams) => {
    setItem(module);
    setEdit(true);
    setModalVisible(true);
  };

  const onSubmitHandler = async (formData: CreateTrainingParams) => {
    if (edit)
      performUpdate({
        ...formData,
        id: item?.id,
      });
    else performPost(formData);
    setModalVisible(false);
  };

  return (
    <MainLayout>
      <TitleContainer>
        <Title title={t("required-training-screen.title")} />
        <Subtitle>
          <div>{data?.total}</div>
          <Text>{t("required-training-screen.total")}</Text>
          <Button
            label={t("required-training-screen.button")}
            customStyle={{
              width: "fit-content",
              padding: 16,
            }}
            onClick={() => {
              setEdit(false);
              setItem(null);
              setModalVisible(true);
            }}
          />
        </Subtitle>
      </TitleContainer>
      <Section>
        {data?.modules?.map((module: Module) => (
          <RequiredTrainingCard
            module={module}
            key={module.id}
            onEditClick={() => handleEdit(module)}
            onArchiveClick={() =>
              performArchive({ id: module.id, user_id: user.id })
            }
          />
        ))}
      </Section>
      {modalVisible && (
        <Modal
          open={modalVisible}
          onClose={() => setModalVisible((prev) => !prev)}
        >
          <TrainingModalView
            edit={edit}
            getInitialState={() => ({
              title: item?.title || "",
              description: item?.description || "",
              url: item?.url || "",
            })}
            onSubmit={onSubmitHandler}
            onClose={() => setModalVisible((prev) => !prev)}
          />
        </Modal>
      )}
    </MainLayout>
  );
};
export default ManageTrainings;
