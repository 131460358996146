import styled from "styled-components";

export const Wrapper = styled.div`
  width: 450px;
  margin: 18vh auto 10vh;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.palette.secondary};
  background: ${({theme}) => theme.palette.secondary};
`;

export const Header = styled.div`
  height: 73px;
  display: flex;
  padding: 0 22px;
  align-items: center;
  justify-content: space-between;
  color: ${({theme}) => theme.palette.text};
  font-size: 34px;
`;

export const Form = styled.div`
  padding: 26px 22px;
  color: ${({theme}) => theme.palette.text};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Link = styled.div`
  color: ${({theme}) => theme.palette.link};
  text-decoration: underline;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  letter-spacing: 1.25px;
  cursor: pointer;
  margin-top: 20px;
`;

export const Error = styled.div`
  color: ${({theme}) => theme.palette.error};
  padding-bottom: 32px;
`;
