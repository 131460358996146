import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";

import { DarkTheme, LigthTheme } from "./theme";
import { RootState } from "redux/reducers";

const StyledThemeProvider = ({ children }: any) => {
  const { app } = useSelector((state: RootState) => state.app);
  const { company } = useSelector((state: RootState) => state.company);

  const themeColors = {
    primary_color: company.primary_color,
    secondary_color: company.secondary_color,
    affirmative_color: company.affirmative_color,
    attention_color: company.attention_color,
    error_color: company.error_color,
  };

  const theme = app.darkMode ? DarkTheme(themeColors) : LigthTheme(themeColors);

  return (
    <ThemeProvider theme={theme as any}>
      {children}
    </ThemeProvider>
  );
};

export default StyledThemeProvider;
