import api from ".";
import store from "../redux/store";
import {
  addNotifications,
  removeNotification,
		removeNotifications,
} from "../redux/slices/notifications";

const { dispatch } = store;

export const getNotifications = () =>
  api
    .get("/notifications")
    .then((res) => {
      dispatch(addNotifications(res?.data));
      return res;
    })
    .catch((e) => {
      console.log("Error: ", e);
    });

export const updateNotification = (id: number) =>
  api
    .put(`/notifications/${id}`)
    .then((res) => {
      dispatch(removeNotification({ id }));
      return res;
    })
    .catch((e) => {
      console.log("Error: ", e);
    });

export const markAllNotificationsRead = () =>
  api
    .put("/notifications/mark-all-read")
    .then((res) => {
      dispatch(removeNotifications());
      return res;
    })
    .catch((e) => {
      console.log("Error: ", e);
    });
