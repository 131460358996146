import { useTranslation } from "react-i18next";

import { Background, Subtitle, Title } from "./styles";
import MainLayout from "layouts/main";

const ComingSoon = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <Background>
        <Title>{t("coming-soon-screen.title")}</Title>
        <Subtitle>{t("coming-soon-screen.subtitle")}</Subtitle>
      </Background>
    </MainLayout>
  );
};
export default ComingSoon;
