import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 32px;
  color: ${({theme}) => theme.palette.text};
`;

export const Text = styled.div`
  font-size: 16px;
  color: ${({theme}) => theme.palette.subtitle};
  padding: 0px 16px;
  width: 170px;
`;

export const Section = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

