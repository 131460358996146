import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Event = styled.div`
  height: 36px;
  width: 36px;
  min-width: 36px;
  display: flex;
  border-radius: 10px 0px 0px 10px;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.palette.primaryButtonDark.background};
  transition: opacity 300ms;
  font-size: 14px;
  color: ${({theme}) => theme.palette.white};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

export const Text = styled.div`
  height: 36px;
  width: 179px;
  min-width: 36px;
  display: flex;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.palette.button};
  font-size: 14px;
  color: ${({theme}) => theme.palette.white};
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.25px;
`;
