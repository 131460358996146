import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CloseDiv,
  Form,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
} from "./styles";
import CloseSvg from "assets/svgs/CloseSvg";
import { Button, Input } from "components";
import PlusSvg from "assets/svgs/PlusSvg";
import { RootState } from "redux/reducers";
import { useApi } from "hooks/useApi";
import { createColumn } from "api/boards";
import { BoardType } from "components/kanbanBoard";

const ColumnModalView = ({
  onSuccess,
  onClose,
  board,
}: {
  onSuccess: () => void;
  onClose: () => void;
  board: BoardType;
}) => {
  const { app } = useSelector((state: RootState) => state.app);
  const [columnName, setColumnName] = useState<string>("");
  const { t } = useTranslation();

  const { request: postColumn }: any = useApi({
    apiFunc: createColumn,
    onSuccess,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postColumn({ name: columnName, index: board.columns.length + 1 }, board.id);
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>{t("kanban-screen.board.modal-column.title")}</h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={app.darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <Input
            value={columnName}
            label={t("kanban-screen.board.modal-column.input")}
            handleChange={(e) => setColumnName(e.target.value)}
            required
            placeholder={`${t("kanban-screen.board.modal-column.input")}...`}
          />
          <Button
            disabled={!columnName?.trim()}
            width='230px'
            icon={() => (
              <PlusSvg
                fill={!columnName?.trim() || !app.darkMode ? undefined : "#222"}
              />
            )}
            onClick={handleSubmit}
            label={t("kanban-screen.board.modal-column.button")}
          />
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default ColumnModalView;
