import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AssignToContainer,
  CloseDiv,
  DropdownItem,
  Form,
  LetterCircle,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
  UsersRow,
} from "./styles";
import CloseSvg from "assets/svgs/CloseSvg";
import { Button, FormSelect, Input } from "components";
import PlusSvg from "assets/svgs/PlusSvg";
import { RootState } from "redux/reducers";
import { BoardType, Collaborator } from "components/kanbanBoard";
import { useApi } from "hooks/useApi";
import { createTask, getCollaboratorsReq } from "api/boards";

const TaskModalView = ({
  board,
  onClose,
  boardId,
  onSuccess,
}: {
  board: BoardType;
  onClose: () => void;
  boardId: string;
  onSuccess: () => void;
}) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [assigned, setAssigned] = useState<{ id: number; value: string }>(null);
  const { app } = useSelector((state: RootState) => state.app);
  const { t } = useTranslation();
  const {
    data: collaborators,
    request: fetchCollaborators,
  }: { data: Collaborator[]; request: any } = useApi({
    apiFunc: getCollaboratorsReq,
  });
  const { request: postTask }: any = useApi({
    apiFunc: createTask,
    onSuccess,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postTask(
      {
        name,
        description,
        assigned_to: assigned ? assigned.id : undefined,
        column_id: board.columns.find((col) => col.index === 1)?.id,
      },
      boardId
    );
  };

  useEffect(
    () => {
      fetchCollaborators({ boardId });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderItem = (item: any, onSelectItem: (item: any) => any) => (
    <DropdownItem
      key={item.id}
      onClick={() => {
        setAssigned(item);
        onSelectItem(item);
      }}
    >
      <UsersRow>
        <LetterCircle>{item.value.charAt(0)}</LetterCircle>
        {item.value}
      </UsersRow>
    </DropdownItem>
  );

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>{t("kanban-screen.board.modal-task.title")}</h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={app.darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <Input
            value={name}
            label={t("kanban-screen.board.modal-task.inputs.name")}
            handleChange={(e) => setName(e.target.value)}
            required
            placeholder={`${t(
              "kanban-screen.board.modal-task.inputs.name"
            )}...`}
          />
          <Input
            value={description}
            label={t("kanban-screen.board.modal-task.inputs.description")}
            handleChange={(e) => setDescription(e.target.value)}
            required
            placeholder={`${t(
              "kanban-screen.board.modal-task.inputs.description"
            )}...`}
          />
          <AssignToContainer>
            <FormSelect
              label={t(
                "kanban-screen.board.modal-task-details.assign-to.label"
              )}
              selected={assigned}
              placeholder={t(
                "kanban-screen.board.modal-task-details.assign-to.placeholder"
              )}
              items={collaborators?.map((c) => ({
                value: `${c.first_name} ${c.last_name}`,
                id: c.collaborator_id,
              }))}
              renderItem={renderItem}
            />
          </AssignToContainer>
          <Button
            disabled={!(name?.trim() || description?.trim())}
            width='230px'
            icon={() => (
              <PlusSvg
                fill={
                  !(name?.trim() || description?.trim()) || !app.darkMode
                    ? undefined
                    : "#222"
                }
              />
            )}
            onClick={handleSubmit}
            label={t("kanban-screen.board.modal-task.button")}
          />
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default TaskModalView;
