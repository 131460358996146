import { Modal } from "@mui/material";

import UploadCSVComponent from "../uploadCSVComponent";
import { Container } from "./styles";

interface Props {
    handleClose: () => void;
    open: boolean;
    tableName: string
}

const UploadCSVModal = ({ handleClose, open, tableName }: Props) => (
    <Modal open={open} onClose={handleClose}>
        <Container>
            <UploadCSVComponent callback={handleClose} tableName={tableName}/>
        </Container>
    </Modal>
);

export default UploadCSVModal;
