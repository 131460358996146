import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.darkBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalTopContainter = styled.div`
  height: 72px;
  min-height: 72px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0 0px 0 24px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.text};
  border-bottom: 1px solid ${({ theme }) => theme.palette.input};
`;

export const CloseDiv = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.input};
  height: 100%;
  align-items: center;
  justify-content: end;
  align-content: center;
  display: flex;
  width: 80px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  box-shadow: 0 4px 6px ${({ theme }) => theme.palette.darkBlack};
  width: 70%;
  max-width: 800px;
  color: white;
  background: ${({ theme }) => theme.palette.modalProfile};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`;

export const Row = styled.div<{ container?: boolean }>`
  display: flex;
  width: 100%;
  padding: 20px;
  align-items: center;
  background-color: ${({ container, theme }) =>
    container ? theme.palette.modalProfileHighlight : "transparent"};
  justify-content: ${({ container }) => (container ? "center" : "start")};
  margin-bottom: ${({ container }) => (container ? "24px" : "0px")};
`;

export const Column = styled.div<{ photo?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ photo }) => (photo ? "40%" : "50%")};
  align-items: ${({ photo }) => (photo ? "center" : "start")};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 8px;
`;

export const Text = styled.div`
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  color: ${({ theme }) => theme.palette.subtitle};
`;

export const Li = styled.li`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.subtitle};
  margin: 0px 0px 4px -16px;
  position: relative;
`;

export const Icon = styled.img`
  position: absolute;
  top: 3px;
  left: -20px;
  background-color: ${({ theme }) => theme.palette.modalProfile};
`;

export const ToggleButton = styled.div<{ opacity?: number }>`
  opacity: ${({ opacity }) => opacity};
  display: flex;
  cursor: pointer;
  padding: 0px 16px;
  color: ${({ theme }) => theme.palette.text};
`;

export const FileInput = styled.input`
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const Image = styled.img<{avatar?: boolean}>`
  border-radius: ${({avatar}) =>  avatar ? "0px" : "60px" };
  width: 120px;
  height: 120px;
  object-fit: cover;
`;
