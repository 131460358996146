import styled from "styled-components";

export const Section = styled.div<{ training?: boolean }>`
  position: relative;
  border-radius: 7px;
  background: ${({theme}) => theme.palette.primaryHighlight};
  padding: 20px 31px;
  margin: 8px 4px 0px 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ training }) => (training ? "space-between" : "start")};
`;

export const SectionRow = styled.div`
  width: 100%;
  display: flex;
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 20px;
  padding: 0 0 10px 0;
  color: ${({theme}) => theme.palette.text};
`;

export const Subtitle = styled.div`
  font-size: 16px;
  color: ${({theme}) => theme.palette.subtitle};
  padding-bottom: 20px;
`;

export const Dash = styled.div`
  height: 5px;
  width: 21px;
  background: ${({theme}) => theme.palette.primarySoft};
  border-radius: 4px;
  margin-right: 16px;
`;

export const Line = styled.div`
  background-color: ${({theme}) => theme.palette.secondary};
  height: 0.5px;
`;

export const Announcement = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-top: 16px;
  color: ${({theme}) => theme.palette.subtitle};
`;
