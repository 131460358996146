import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.palette.text};
    font-size: 24px;
`;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.palette.text};
    font-size: 16px;
`;

export const BoldText = styled.div`
    color: ${({ theme }) => theme.palette.text};
    font-size: 16px;
    font-family: ${({ theme }) => theme.typography.semiBold.inter};
    font-weight: 500;
`;

export const InfoIconStyled = styled(InfoIcon)`
    && {
        color: ${({ theme }) => theme.palette.text};
    }
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const SelectFileButton = styled.button`
    background-color: inherit;
    box-shadow: none;
    color: ${({ theme }) => theme.palette.white};
    border-width: 0px;
    font-family: ${({ theme }) => theme.typography.normal.inter};
    font-size: 16px;
    text-decoration: underline;
`;

export const DropzoneContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.palette.lightGrey};
    border-style: dashed;
    width: 100%;
    height: 35vh;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette.black};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentContainer = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const UploadFileIconStyled = styled(UploadFileIcon)`
    && {
        color: ${({ theme }) => theme.palette.white};
        font-size: 32px;
    }
`;

export const DropzoneText = styled.div`
    color: ${({ theme }) => theme.palette.white};
    font-size: 16px;
`;
