import styled from "styled-components";

export const Text = styled.div`
  color: ${({theme}) => theme.palette.white};
  font-size: 12px;
  font-family: ${({theme}) => theme.typography.normal.inter};
  font-weight: 600;
  letter-spacing: 1.25px;
  text-transform: capitalize;
`;

export const Icon = styled.img<{ radius: number }>`
  opacity: 0.6;
  resizeMode: cover;
  border-radius: ${({ radius }) => `${radius}px`};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
