import { TextField } from "@mui/material";
import styled from "styled-components";
import SendIcon from "@mui/icons-material/Send";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 10vh;
    padding-right: 32px;
    padding-left: 32px;
    margin-bottom: 20px;
    max-width: calc(100vw - 250px);
    width: calc(100vw - 250px);
    min-width: calc(100vw - 250px);
`;

export const InputAutosize = styled(TextField)`
    &.MuiTextField-root {
        border: 1px solid;
        height: 10vh;
        font-family: ${({ theme }) => theme.typography.normal.inter};
        color: ${({ theme }) => theme.palette.text};
        -webkit-text-fill-color: ${({ theme }) => theme.palette.text};
        outline: none;
        width: 100%;
        font-size: 18px;
        border-radius: 3px;
        caret-color: ${({ theme }) => theme.palette.text} !important;
        & .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }
`;

export const Icon = styled(SendIcon)`
    && {
        color: ${({ theme }) => theme.palette.text};
    }
`;
