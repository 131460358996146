import styled from "styled-components";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";

export const ArrowUpButton = styled(ArrowCircleUpOutlinedIcon)`
    && {
        color: ${({ theme }) => theme.palette.text};
        width: 32px;
        height: 32px;
    }
`;

export const ArrowDownButton = styled(ArrowCircleDownOutlinedIcon)`
    && {
        color: ${({ theme }) => theme.palette.text};
        width: 32px;
        height: 32px;
    }
`;
