import React, { useState, useCallback, useRef } from 'react';

import { StyledForm } from './styles';

interface FormProps<T> {
  initialState: T;
  onSubmit: (data: T) => void;
  onDataChange: () => void;
  formKey: string;
  children: (props: {
    formData: T;
    handleChange: ({ name, value }: { name: string, value: string }) => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  }) => React.ReactNode;
}

function Form<T>({ initialState, formKey, onDataChange, onSubmit, children }: FormProps<T>) {
  const [formData, setFormData] = useState<T>(initialState);
  const formRef = useRef<HTMLFormElement>(null);
  
  const handleChange = ({ name, value }: { name: string, value: string }) => {
    onDataChange();
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formData);
  }, [formData, onSubmit]);

  return (
    <StyledForm ref={formRef} onSubmit={handleSubmit} key={formKey}>
      {children({ formData, handleChange, handleSubmit })}
    </StyledForm>
  );
}

export default Form;


