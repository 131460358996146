import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { Background, ModalBackground, ModalTopContainter, Text, Container } from "./styles";
import { useApi } from "hooks/useApi";
import { signUpInvitation } from "api/auth";
import SignupForm from "components/signupForm ";
import { ModalContainer } from "components/signupForm /styles";
import { Button } from "components";
import { ROUTES } from "utils/routes";

const SignupInvitation = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { request, error, isSuccess, loading } = useApi({ apiFunc: signUpInvitation });
  const location = useLocation();
  const getParamsFromURL = (search) => {
    const params = new URLSearchParams(search);
    return {
      email: params.get('email') || '',
      token: params.get('token') || '',
    };
  };
  const [state] = useState(() => getParamsFromURL(location.search));

  return (
    <Background>
      {isSuccess && !loading ?
        <ModalBackground>
          <ModalContainer>
            <ModalTopContainter>
              <h2>{t("signup-screen.title-success")}</h2>
            </ModalTopContainter>
            <Container>
              <Text>{t("signup-screen.success")}</Text>
              <Button
                width='300px'
                onClick={() => history.push(ROUTES.login)}
                label={t("signup-screen.button-success")}
              />
            </Container>
          </ModalContainer>
        </ModalBackground>
        : <SignupForm
          getInitialState={() => ({
            userEmail: state.email,
            password: "",
            photoUri: "",
          })}
          error={error}
          onSubmit={(data: { userEmail: string, password: string, photoUri: string }) => request({ ...data, token: state.token })}
        />}
    </Background>
  );
};
export default SignupInvitation;
