import styled from "styled-components";

export const DataTableColumn = styled.div<{
  dim: { flex?: boolean; width?: string; height?: string };
}>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 20px;
  flex-wrap: nowrap;
  flex: ${({ dim }) =>
    dim.flex ? `1 1 ${dim.width || "0%"}` : `0 0 ${dim.width}`};
  height: ${({ dim }) => dim.height}px;
  width: ${({ dim }) => dim.width};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:last-child {
    border-right: none;
  }
`;

export const DataTableRow = styled.div<{
  height?: string;
  noHover?: boolean;
  error?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  border-radius: 7px;
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.text};
  margin: 0 0 8px 0;
  height: ${(props) => props.height}px;
  box-shadow: ${({ theme }) => theme.palette.primaryButtonPlain.shadow};
  cursor: ${(props) => (props.noHover ? "default" : "pointer")};

  &:hover {
    background-color: ${(props) =>
      props.noHover
        ? props.theme.palette.primaryHighlight
        : props.theme.palette.hover};
  }

  ${(props) =>
    props.error &&
    `
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.red};
  `}
`;

export const ErrorPopup = styled.div`
  top: 50%;
  left: 50%;
  opacity: 0;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.white};
  position: absolute;
  pointer-events: none;
  padding: 0 6px 1px 6px;
  background-color: ${({ theme }) => theme.palette.error};
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px 0 ${({ theme }) => theme.palette.darkerBlack};

  ${DataTableRow}:hover & {
    opacity: 1;
    transition: opacity 300ms ease 400ms;
  }
`;

export const DataTableHeader = styled.div<{
  error?: boolean;
  noHairlineTop?: boolean;
  noHairlineBottom?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 17px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.subtitle};

  ${(props) =>
    props.error &&
    `
  box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.red};
  `}

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
  }
  &:before {
    top: 0;
    background-color: ${(props) =>
      props.noHairlineTop ? "transparent" : props.theme.palette.black};
  }
  &:after {
    bottom: 0;
    background-color: ${(props) =>
      props.noHairlineBottom ? "transparent" : props.theme.palette.black};
  }
`;

export const DataTableContainer = styled.div`
  .DataTableHeader__root,
  .DataTableRow__root {
    [data-name="name"] {
      padding-left: 0;
    }
  }
  .AvatarItem__wrapper img {
    margin: auto;
    display: block;
  }
`;
