import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  MenuHeader,
  MenuItemStyled,
  MenuSmallText,
  MenuStyled,
  MenuTitle,
  Text,
} from "./styles";
import { NotificationType } from "../../redux/slices/notifications";
import { useApi } from "../../hooks/useApi";
import {
  markAllNotificationsRead,
  updateNotification,
} from "../../api/notifications";

interface Props {
  anchorEl: HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement>>;
  notifications?: NotificationType[];
}

const NotificationsMenu = ({ anchorEl, setAnchorEl, notifications }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const open = Boolean(anchorEl);
  const { request }: any = useApi({
    apiFunc: updateNotification,
  });
  const { request: markAllNotificationsReadCall }: any = useApi({
    apiFunc: markAllNotificationsRead,
  });

  const onClickNotification = ({ id, navigation_route }: NotificationType) => {
    setAnchorEl(null);
    request(id);
    history.push(`${navigation_route}`);
  };

  const onClickMarkAllRead = () => {
    setAnchorEl(null);
    if (!!notifications.length) markAllNotificationsReadCall();
  };

  return (
    <MenuStyled
      id="long-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
    >
      <MenuHeader>
        <MenuTitle>{t("notifications.title")}</MenuTitle>
        <MenuSmallText onClick={onClickMarkAllRead}>
          {t("notifications.button")}
        </MenuSmallText>
      </MenuHeader>
      {!!notifications.length ? (
        notifications?.map((notification) => (
          <MenuItemStyled
            key={notification.id}
            onClick={() => onClickNotification(notification)}
          >
            {notification.description}
          </MenuItemStyled>
        ))
      ) : (
        <Text>{t("notifications.empty-text")}</Text>
      )}
    </MenuStyled>
  );
};

export default NotificationsMenu;
