import { Input } from "components";
import { Wrapper, Header, Form, Footer, Link, Error } from "./styles";

export const LoginForm = ({
  title,
  inputs,
  button,
  link,
  error
}: {
  title: string;
  inputs: {
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    required?: boolean;
    type?: string;
  }[];
  button: JSX.Element;
  error?: string;
  link?: {
    label: string;
    handleClick: () => void;
  };
}) => {
  return (
    <Wrapper>
      <Header>{title}</Header>
      <Form>
        {inputs.map(({ value, handleChange, label, required, type }) => (
          <Input
            key={label}
            value={value}
            handleChange={handleChange}
            label={label}
            required={required}
            type={type}
          />
        ))}
        <Error>{error}</Error>
        <Footer>
          {button}
          {link && <Link onClick={link.handleClick}>{link.label}</Link>}
        </Footer>
      </Form>
    </Wrapper>
  );
};
