import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Background } from "./styles";
import { Button, LoginForm } from "components";
import { useApi } from "hooks/useApi";
import { forgotPassowrd } from "api/auth";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    email: "",
  });
  const { request, error }: any = useApi({ apiFunc: forgotPassowrd });

  return (
    <Background>
      <LoginForm
        title={t("forgot-password.title")}
        inputs={[
          {
            value: form.email,
            handleChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, email: e.currentTarget.value }),
            label: t("forgot-password.input"),
            required: true,
          },
        ]}
        button={
          <Button
            label={t("forgot-password.button")}
            onClick={() => {
              request(form);
            }}
            disabled={!form.email}
          />
        }
        error={error}
      />
    </Background>
  );
};
export default ForgotPassword;
