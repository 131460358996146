import dayjs from "dayjs";

export const passValidation = new RegExp(
    /^(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/
);

export const emailValidation = new RegExp(
    /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
);

export const formatDate = (date: string) => dayjs(date).format("MM/DD/YYYY");
export const formatDateTime = (date: string) =>
    dayjs(date).format("MM/DD/YYYY - h:mma");

export const isObject = (o) =>
    o !== null && typeof o === "object" && !Array.isArray(o);

export enum userRoleType {
    ADMINISTRATOR = "administrator",
    COLLABORATOR = "collaborator",
}

export class Roles {
    private static roleNames = new Map<userRoleType, string>([
        [userRoleType.ADMINISTRATOR, "Administrator"],
        [userRoleType.COLLABORATOR, "Collaborator"],
    ]);

    static getRoleName(roleKey: userRoleType): string {
        return this.roleNames.get(roleKey) || "Unknown";
    }

    static getRoles(): string[] {
        return Array.from(this.roleNames.keys());
    }
}

const level2 = [userRoleType.ADMINISTRATOR];
const level1 = level2.concat([userRoleType.COLLABORATOR]);

export const permissions = {
    level1,
    level2,
};

export const NAV_ROUTES = [
    {
        title: "Dashboard",
        route: "/dashboard",
        permissions: permissions.level1,
        inner: [
            {
                title: "All",
                route: "/dashboard",
                permissions: permissions.level2,
            },
            {
                title: "Manage Announcements",
                route: "/announcements",
                permissions: permissions.level2,
            },
            {
                title: "Manage Required Training",
                route: "/manage/trainings",
                permissions: permissions.level2,
            },
            {
                title: "Kanban",
                route: "/kanban-boards",
                permissions: permissions.level1,
            },
        ],
    },
    {
        title: "Reports",
        route: "/reports",
        permissions: permissions.level2,
        inner: [
            {
                title: "All",
                route: "/reports",
                permissions: permissions.level2,
            },
        ],
    },
    {
        title: "Notifications",
        route: "/notifications",
        permissions: permissions.level2,
        inner: [
            {
                title: "All",
                route: "/notifications",
                permissions: permissions.level2,
            },
        ],
    },
    {
        title: "Chat",
        route: "/chat",
        permissions: permissions.level2,
        inner: [
            {
                title: "All",
                route: "/chat",
                permissions: level2,
            },
        ],
    },
    {
        title: "Settings",
        route: "/manage",
        permissions: permissions.level2,
        inner: [
            {
                title: "User Managment",
                route: "/manage/users",
                permissions: permissions.level2,
            },
            {
                title: "Customize Mission Control",
                route: "/manage/admin-controls",
                permissions: permissions.level2,
            },
        ],
    },
];

export const getPermissions = (permissions = [], role: string) => {
    return (
        !permissions || !permissions.length || permissions.indexOf(role) > -1
    );
};

export function stripToFormatterWord(str) {
    return (str || "").replace(/[_-]+/, " ");
}

export function toTitleCase(str) {
    return String(str || "").replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
}

export function capitalize(text) {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const EXPIRY_SECOND = 72 * 3600;

export const checkPassword = (password: string) => {
    const special = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const number = /[0-9]/;
    const verified = {
        charsLengthPassed: false,
        hasNumber: false,
        hasSpecialChars: false,
        verified: false,
    };

    if (!password) return verified;

    if (password.length >= 8) verified.charsLengthPassed = true;
    if (number.test(password)) verified.hasNumber = true;
    if (special.test(password)) verified.hasSpecialChars = true;

    verified.verified =
        verified.charsLengthPassed &&
        verified.hasNumber &&
        verified.hasSpecialChars;

    return verified;
};

export const uploadFile = (e, fileInputRef, setPhotoUri) => {
    const input = e.currentTarget;
    const files = input.files;
    const file = files?.[0];

    if (!file && fileInputRef.current) {
        fileInputRef.current.value = "";
        return;
    }

    const fileCopy = new File(
        [file],
        file.name
            .trim()
            .replace(/[^a-z0-9.]/gi, "_")
            .toLowerCase(),
        { type: file.type }
    );
    const reader = new FileReader();

    reader.onload = (e) => {
        const base64 = e.target.result;
        setPhotoUri(base64);
    };
    reader.readAsDataURL(fileCopy);

    if (fileInputRef.current) fileInputRef.current.value = "";
    setPhotoUri("");
};

export const objectToString = (obj) => {
    return Object.entries(obj)
        .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
        .join(", ");
};
