import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Container, Item, ItemContainer, List, Marker } from "./styles";
import { useState } from "react";
import MinusNavSvg from "assets/svgs/MinusNavSvg";
import PlusNavSvg from "assets/svgs/PlusNavSvg";
import { User } from "redux/slices/auth";
import { handleDrawer } from "redux/slices/app";

type AccordionItem = {
  title: string;
  route: string;
  permissions: string[];
  inner: {
    title: string;
    route: string;
    permissions: string[];
  }[];
};
export const Accordion = ({
  item,
  user,
  darkMode,
}: {
  item: AccordionItem;
  user: User;
  darkMode: boolean;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { title, inner, permissions } = item;
  const location = window.location.pathname;

  const hasPermission = (permission) =>
    !permission || !permission.length || permission.indexOf(user.role) > -1;

    
  return (
    hasPermission(permissions) && (
      <>
        <Container onClick={() => setIsOpen(!isOpen)}>
          {title}
          <Button>
            {isOpen ? (
              <MinusNavSvg fill={darkMode ? undefined : "#222222"} />
            ) : (
              <PlusNavSvg fill={darkMode ? undefined : "#222222"} />
            )}
          </Button>
        </Container>
        {isOpen && (
          <List>
            {inner.map((innerItem) => hasPermission(innerItem.permissions) ? (
              <ItemContainer key={innerItem.route}>
                {location === innerItem.route && <Marker />}
                <Item
                  onClick={() => {
                    history.push(innerItem.route);
                    dispatch(handleDrawer(false));
                  }}
                >
                  {innerItem.title}
                </Item>
              </ItemContainer>
            ) : <></>)}
          </List>
        )}
      </>
    )
  );
};
