import api from ".";

export const getBoards = () =>
  api
    .get(`/boards`)
    .then((res) => res)
    .catch((e) => {
      console.log("Error: ", e);
    });

export const getBoard = ({ id }: { id: string }) =>
  api
    .get(`/boards/${id}`)
    .then((res) => res)
    .catch((e) => {
      console.log("Error: ", e);
    });

export const createBoard = (params: { name: string }) =>
  api
    .post(`/boards`, params)
    .then((res) => res)
    .catch((e) => {
      console.log("Error: ", e);
    });

export const createColumn = (
  params: { name: string; index: number },
  id: number
) =>
  api
    .post(`/boards/${id}/columns`, params)
    .then((res) => res)
    .catch((e) => {
      console.log("Error: ", e);
    });

export const updateColumnName = (
  params: { name: string },
  boardId: number,
  columnId: number
) =>
  api
    .put(`/boards/${boardId}/columns/${columnId}`, params)
    .then((res) => res)
    .catch((e) => {
      console.log("Error: ", e);
    });

export const createTask = (
  params: { name: string; description: string },
  id: number
) =>
  api
    .post(`/boards/${id}/tasks`, params)
    .then((res) => res)
    .catch((e) => {
      console.log("Error: ", e);
    });

export const updateTask = (
  params: { name?: string; description?: string; column_id?: number },
  boardId: number,
  taskId: number
) =>
  api
    .put(`/boards/${boardId}/tasks/${taskId}`, params)
    .then((res) => res)
    .catch((e) => {
      console.log("Error: ", e);
    });

export const getCollaboratorsReq = ({ boardId }: { boardId: string }) => api.get(`/boards/${boardId}/collaborators`).then((res) => res)
  .catch((e) => {
    console.log("Error: ", e);
  });

export const inviteCollaboratorsReq = (boardId: string, data: { boardId: string; users: any[] }) => api.post(`/boards/${boardId}/collaborators`, data).then((res) => res)
  .catch((e) => {
    console.log("Error: ", e);
  });

export const inviteNewCollaboratorsReq = (boardId: string, data: { boardId: string; users: any[] }) => api.post(`/boards/${boardId}/collaborators/invite`, data).then((res) => res)
  .catch((e) => {
    console.log("Error: ", e);
  });

export const deleteCollaboratorReq = ({ id }: { id: string }) => api.delete(`/boards/collaborators/${id}`).then((res) => res)
  .catch((e) => {
    console.log("Error: ", e);
  });
