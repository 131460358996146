import styled from "styled-components";
import { Select } from "@mui/material";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 80%;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.palette.text};
    font-size: 24px;
`;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.palette.text};
    font-size: 16px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;
`;

export const HeaderText = styled.div`
    color: ${({ theme }) => theme.palette.text};
    flex-grow: 1;
`;

export const Cell = styled.div`
    width: 20%;
`;

export const StyledSelect = styled(Select)`
    && {
        border: 1px solid ${({ theme }) => theme.palette.white};
        width: 100%;
        height: 40px;
    }
    .MuiSelect-icon {
        color: ${({ theme }) => theme.palette.text};
    }
    & .MuiInputBase-input {
        color: ${({ theme }) => theme.palette.text};
        font-size: 14px;
    }
`;

export const TableContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
    flex-direction: column;
    gap: 10px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 24px;
    margin-bottom: 24px;
`;
