import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Badge } from "@mui/material";

export const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  position: fixed;
  display: flex;
  margin: 0px 8px 0;
  border-radius: 7px;
  align-items: center;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  padding: 0 8px 0 8px;
  justify-content: space-between;
  height: calc(58px - 5px);
`;

export const LeftContainer = styled.div`
  width: 25vw;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const RightContainer = styled.div`
  width: 25vw;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.text};
`;

export const Button = styled.button`
  height: 48px;
  min-width: 48px;
  margin-right: 16px;
  transition: opacity 300ms;
  user-select: none;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
`;

export const Arrow = styled(ArrowDropUpIcon)`
  && {
    position: absolute;
    right: 15%;
    top: 35px;
    font-size: 50px;
    color: ${({ theme }) => theme.palette.primaryHighlight};
  }
`;

export const BadgeStyled = styled(Badge)`
  .MuiBadge-badge {
    left: -1px;
    top: 12px;
    background-color: ${({ theme }) => theme.palette.red};
    padding: 0 4px;
    border: 2px solid ${({ theme }) => theme.palette.primaryHighlight};
    color: ${({ theme }) => theme.palette.white};
  }
`;
