import api from ".";

interface TrainingParams {
  skip: number;
  limit: number;
  is_active: boolean;
  with_incomplete_total: boolean;
  user_id: number;
}

export interface CreateTrainingParams {
  title: string;
  url: string;
  description: string;
}

export interface UpdateTrainingParams {
  title: string;
  url: string;
  description: string;
  id: number;
}

export const getTrainingModules = (params: TrainingParams) =>
  api.get("/training-module", { params }).then((res) => {
    return res;
  });

export const getTrainingById = (params: { id: number }) =>
  api.get(`/training-module/by-id/${params.id}`, { params }).then((res) => {
    return res;
  });

export const createTraining = (params: CreateTrainingParams) =>
  api.post("/training-module/create-module", params).then((res) => {
    return res;
  });

export const archiveTraining = (params: { id: number; user_id: number }) =>
  api.post("/training-module/archive-module", params).then((res) => {
    return res;
  });


export const updateTraining = (params: UpdateTrainingParams) =>
  api.post("/training-module/update-module", params).then((res) => {
    return res;
  });

export const markTrainingAsComplete = (params: {
  user_id: number;
  training_module_id: number;
}) =>
  api.post("/training-module/mark-as-complete", params).then((res) => {
    return res;
  });
