import { useRef } from 'react';

import { DropzoneWrapper, HiddenInput } from './styles';

const Dropzone = ({ name, onChange, children }) => {
  const fileInputRef = useRef(null);

  const handleDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      processFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = (e: any) => (e.target.files && e.target.files.length > 0) && processFile(e.target.files[0]);

  const processFile = (file: Blob) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(name, { uri: reader.result, file });
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileDialog = () => fileInputRef.current.click();

  return (
    <DropzoneWrapper
      onClick={openFileDialog}
      onDragOver={(e: { preventDefault: () => any; }) => e.preventDefault()}
      onDrop={handleDrop}
    >
      {children}
      < HiddenInput
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        onClick={(e: { stopPropagation: () => any; }) => e.stopPropagation()}
      />
    </DropzoneWrapper >
  );
};

export default Dropzone;
