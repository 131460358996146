/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useLocation } from "react-router-dom";

import MainLayout from "../../layouts/main";
import { ROUTES } from "../../utils/routes";
import UploadCSVComponent from "../../components/uploadCSVComponent";
import { useEffect } from "react";

const UploadCsv = () => {
    const history = useHistory();
    const location = useLocation<{ tableName: string }>();

    useEffect(() => { if (!location?.state?.tableName) history.push(ROUTES.chat) }, [])

    return (
        <MainLayout
            styles={{ width: "100%", padding: "24px 48px 24px 48px" }}
            showFabButton={false}
        >
            <UploadCSVComponent
                callback={() => history.push(ROUTES.chat)}
                tableName={location?.state?.tableName}
            />
        </MainLayout>
    );
};

export default UploadCsv;
