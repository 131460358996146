/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Title } from 'components';
import { Container, SectionContent, FlexWrapper, Spacer, CenteredFlex, PanelContainer } from './styles';
import AdminControlDetails from 'components/adminControlsDetails';
import AdminControlColors from 'components/adminControlsColors';
import Form from 'components/common/form';
import { useApi } from 'hooks/useApi';
import { getCompanyInfo, updateCompany } from 'api/company';
import { RootState } from 'redux/reducers';
import MainLayout from 'layouts/main';
import RepeatSvg from 'assets/svgs/RepeatSvg';
import ErrorSvg from 'assets/svgs/ErrorSvg';

const AdminControls = () => {
  //TODO: handle error
  const [errors, setErrors] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const { app } = useSelector((state: RootState) => state.app);
  const { company } = useSelector((state: RootState) => state.company);
  const { request: fetchCompanyInfo }: any = useApi({ apiFunc: getCompanyInfo });
  const { request, isSuccess, loading } = useApi({
    apiFunc: updateCompany
  });

  const validateFormData = (formData: any) => {
    if (!formData.name.trim()) { setErrors(true); return false }
    setErrors(false);
    return true;
  };

  useEffect(() => fetchCompanyInfo(), [])

  const handleSubmit = async (formData: any) => {
    if (!validateFormData(formData)) return;
    try {
      await request(formData);
    } catch (error) {
      setErrors(true);
    }
  };

  const formKey = useMemo(() => {
    return `${company.name}-${company.dark_logo_url}-${company.light_logo_url}-${Date.now()}`;
  }, [company]);

  return (
    <MainLayout>
      <Title title={t("admin-controls-screen.title")} />
      <Container>
       {company.primary_color && <Form initialState={{
          name: company.name,
          dark_logo_url: company.dark_logo_url || '',
          light_logo_url: company.light_logo_url || '',
          primary_color: company.primary_color,
          secondary_color: company.secondary_color,
          affirmative_color: company.affirmative_color,
          attention_color: company.attention_color,
          error_color: company.error_color,
        }} onSubmit={handleSubmit}
          onDataChange={() => setDisabled(false)}
          formKey={formKey}
        >
          {({ formData, handleChange, handleSubmit }) => (
            <>
              <SectionContent>
                <FlexWrapper>
                  <PanelContainer>
                    <AdminControlDetails formData={formData} onChange={handleChange} isLoading={loading} />
                  </PanelContainer>
                  <Spacer />
                  <PanelContainer>
                    <AdminControlColors formData={formData} onChange={handleChange} />
                  </PanelContainer>
                </FlexWrapper>
                <CenteredFlex>
                  <Button
                    width='175px'
                    icon={() => errors ? <ErrorSvg fill={app.darkMode ? undefined : "#222"}/> : <RepeatSvg fill={app.darkMode ? undefined : "#222"} />}
                    onClick={handleSubmit}
                    error={!!errors}
                    success={isSuccess}
                    disabled={disabled || loading}
                    label={isSuccess ? t("admin-controls-screen.button.success") : t("admin-controls-screen.button.label")}
                  />
                </CenteredFlex>
              </SectionContent>
            </>)}
        </Form>}
      </Container>
    </MainLayout>
  );
};

export default AdminControls;
