import { useTranslation } from "react-i18next";

import {  Title } from "components";
import MainLayout from "layouts/main";
import { Section, Subtitle, Text, TitleContainer } from "./styles";
import { useApi } from "hooks/useApi";
import { getTrainingModules } from "api/trainings";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { TrainingModule } from "components/trainingModule";

export type Module = {
  completed_by: {
    completed_at: string;
    first_name: string;
    id: number;
    last_name: string;
    profile_image: string;
    role: string;
  }[];
  archived_on?: string;
  created_at: string;
  description: string;
  id: number;
  is_active: boolean;
  title: string;
  url: string;
};

const TrainingModules = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { request, data }: any = useApi({ apiFunc: getTrainingModules });

  useEffect(() => {
    if (user.id !== 0) {
      request({
        skip: 0,
        limit: 30,
        is_active: true,
        with_incomplete_total: true,
        user_id: user.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <MainLayout>
      <TitleContainer>
        <Title title={t("training-screen.title")} />
        <Subtitle>
          <div>{data?.incomplete_total}</div>
          <Text>
            <div>{t("training-screen.subtitle.incomplete")}</div>
            <div>{t("training-screen.subtitle.training")}</div>
          </Text>
        </Subtitle>
      </TitleContainer>
      <Section>
        {data?.modules.map((module: Module) => (
          <TrainingModule module={module} userId={user.id}/>
        ))}
      </Section>
    </MainLayout>
  );
};
export default TrainingModules;
