const SignOutSvg = ({
  width = 34,
  height = 25,
  fillOpacity = 0.5,
  fill = "#fff",
  ...props
}) => (
  <div style={{ width, height }}>
    <svg
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 34 25'
      width={width}
      height={height}
      {...props}
    >
      <g opacity={fillOpacity} fill={fill}>
        <path d='M11.348 5.724h5.444c.86 0 1.556.696 1.556 1.555v2.334a.778.778 0 11-1.555 0V8.279a1 1 0 00-1-1h-3.445a1 1 0 00-1 1v8.89a1 1 0 001 1h3.444a1 1 0 001-1v-1.334a.778.778 0 011.556 0v2.333c0 .86-.696 1.556-1.555 1.556h-5.445c-.86 0-1.555-.697-1.555-1.556V7.28c0-.86.696-1.555 1.555-1.555z' />
        <path d='M20.131 14.962a.778.778 0 101.1 1.1l2.632-2.631a1 1 0 000-1.414l-2.631-2.632a.778.778 0 00-1.1 1.1l.038.04a.833.833 0 01-.59 1.42h-4.732a.778.778 0 100 1.556h4.733a.833.833 0 01.589 1.422l-.039.039z' />
      </g>
    </svg>
  </div>
);

export default SignOutSvg;
