import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { OutlineButton, Title } from "components";
import MainLayout from "layouts/main";
import {
  Announcement,
  Dash,
  Line,
  Name,
  Section,
  SectionRow,
  Subtitle,
} from "./styles";
import { useApi } from "hooks/useApi";
import { getTrainingModules } from "api/trainings";
import { RootState } from "redux/reducers";
import { getAnnouncements } from "api/announcements";
import { ROUTES } from "utils/routes";
import { getCompanyInfo } from "api/company";
import { userRoleType } from "utils";

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.auth);
  const { request, data }: any = useApi({ apiFunc: getTrainingModules });
  const { request: fetchCompanyInfo }: any = useApi({
    apiFunc: getCompanyInfo,
  });
  const { request: announcementsRequest, data: announcementsData }: any =
    useApi({
      apiFunc: getAnnouncements,
    });

  useEffect(() => {
    if (user.id !== 0) {
      request({
        skip: 0,
        limit: 30,
        is_active: true,
        with_incomplete_total: true,
        user_id: user.id,
      });
      announcementsRequest({
        skip: 0,
        limit: 30,
        is_active: true,
        with_incomplete_total: true,
        user_id: user.id,
      });
      fetchCompanyInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user?.role === userRoleType.COLLABORATOR) history.push(ROUTES.kanbanBoards)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <MainLayout>
      <Title title={t("dashboard-screen.title")} />
      <SectionRow>
        <Section training>
          <div>
            <Name>{t("dashboard-screen.training.title")}</Name>
            <Subtitle>{t("dashboard-screen.training.subtitle")}</Subtitle>
          </div>
          <OutlineButton
            label={t("dashboard-screen.training.button")}
            secondary={data?.incomplete_total}
            onClick={() => history.push(ROUTES.trainingModules)}
          />
        </Section>
        <Section>
          <Name>{t("dashboard-screen.announcements.title")}</Name>
          <Line />
          {announcementsData?.announcements?.map((announcement) => (
            <Announcement>
              <Dash />
              {announcement.announcement}
            </Announcement>
          ))}
        </Section>
      </SectionRow>
    </MainLayout>
  );
};
export default Dashboard;
