/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Message {
  id: string;
  sender: "Chatbot" | "User";
  text?: string;
  response?: any[];
}

interface Chat {
  tableName: string;
  messages: Message[];
}

interface Chats { chats: Record<string, Chat> }

const chatInitialState: Chats = {
  chats: {
    "Mission Control AI": {
      tableName: "Mission Control AI",
      messages: []
    }
  }
};

const chats = createSlice({
  name: "chats",
  initialState: chatInitialState,
  reducers: {
    addMessage(state, { payload }: PayloadAction<{ tableName: string; message: Message }>) {
      const { tableName, message } = payload;
      if (!state?.chats?.[tableName]) state.chats[tableName] = { tableName, messages: [] };
      state?.chats?.[tableName].messages.push(message);
    },
    addTab(state, { payload }: PayloadAction<{ tableName: string; }>) {
      const { tableName } = payload;
      if (!state?.chats?.[tableName]) state.chats[tableName] = { tableName, messages: [] };
    },
    clearMessages(state, { payload }: PayloadAction<string>) {
      if (state?.chats?.[payload]) state.chats[payload].messages = [];
    },
    clearTab(state, { payload }: PayloadAction<{ tableName: string; }>) {
      const { tableName } = payload;
      if (state?.chats?.[tableName]) delete state.chats[tableName];
    },
    resetInitialState(state) {
      return chatInitialState;
    },
  },
});

export const { addMessage, clearMessages, addTab, clearTab, resetInitialState } = chats.actions;
export default chats.reducer;

