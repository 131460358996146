import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Header = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  font-size: 24px;
  padding: 0 20px;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.text};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primarySoft};
`;

export const Body = styled.div`
  width: 100%;
`;

export const Item = styled.div`
  position: relative;
  padding: 20px 34px 20px 48px;
`;

export const Dash = styled.div`
  top: 31px;
  left: 17px;
  width: 21px;
  height: 5px;
  position: absolute;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.secondary};
`;

export const PickerColorWrapper = styled.div`
  top: 31px;
  right: 34px;
  position: absolute;
`;

export const PickerColor = styled.div`
  width: 60px;
  height: 10px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text};
`;

export const Subtitle = styled.div`
  opacity: 0.80;
  font-size: 18px;
  font-style: italic;
  margin-top: 5px;
  color: ${({ theme }) => theme.palette.text};

`;

export const Picker = styled.div`
  top: 20px;
  right: 0;
  z-index: 5;
  position: absolute;
`;