import styled from "styled-components";
import TableChartIcon from "@mui/icons-material/TableChart";
import GridOnIcon from "@mui/icons-material/GridOn";
import { Typography } from "@mui/material";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 220px;
    background-color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.border};
    box-shadow: 24;
    padding: 16px;
    border-radius: 20px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
    justify-content: space-between;
`;

export const ChartIcon = styled(TableChartIcon)<{ darkMode?: boolean }>`
    && {
        color: ${({ theme, darkMode }) =>
            darkMode ? theme.palette.white : theme.palette.black};
        margin-right: 8px;
    }
`;

export const LabelRow = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 12px;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
`;

export const GridIcon = styled(GridOnIcon)`
    && {
        color: ${({ theme }) => theme.palette.white};
        font-size: 16px;
        margin-left: 4px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Text = styled(Typography)<{ darkMode?: boolean }>`
    &.MuiTypography-root {
        color: ${({ theme, darkMode }) =>
            darkMode ? theme.palette.white : theme.palette.black};
    }
`;

export const Label = styled.div<{ darkMode?: boolean }>`
    font-size: 12px;
    color: ${({ theme, darkMode }) =>
        darkMode ? theme.palette.white : theme.palette.black};
`;
