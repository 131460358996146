import styled from "styled-components";

export const Background = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  padding: 8px 16px 0px 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const Board = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 24px;
  overflow-x: scroll;
  padding-top: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 300px;
  max-width: 400px;
  width: 100%;
  overflow-y: scroll;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.black};
  padding: 16px;
  gap: 16px;
`;

export const Space = styled.div`
  margin: 10px;
`

export const Title = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.palette.white};
  padding-bottom: 8px;
  display: flex;
  height: 64px;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.input};
`;

export const Task = styled.div`
  background-color: ${({ theme }) => theme.palette.input};
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.subtitle};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

export const Icon = styled.img`
  opacity: 0.6;
  resizeMode: cover;
  border-radius: 100px;
  width: 28px;
  height: 28px;
`;

export const TaskName = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.text};
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  margin-bottom: 16px;
`;

export const PlusButton = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.palette.subtitle};
  border-radius: 15px;
  color: ${({ theme }) => theme.palette.text};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
  cursor: pointer;
`;

export const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: -26px;
  right: 0px;
`;

export const ActionButton = styled.div`
  background-color: ${({ theme }) => theme.palette.input};
  border: .5px solid ${({ theme }) => theme.palette.text};
  margin-left: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.subtitle};
  }
`;

export const ColumnActionButtonRight = styled.div`
  background-color: ${({ theme }) => theme.palette.input};
  border: .5px solid ${({ theme }) => theme.palette.text};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  z-index: 1000;
`;

export const ColumnActionButtonLeft = styled.div`
  background-color: ${({ theme }) => theme.palette.input};
  border: .5px solid ${({ theme }) => theme.palette.text};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: -10px;
`;


