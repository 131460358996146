import classnames from 'classnames';

const FolderSvg = ({
  width = 22,
  height = 20,
  stroke = '#fff',
  strokeWidth = 2,
  className = "",
  ...props
}) => (
  <div style={{width, height}} className={classnames("svg-wrapper", className)}>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 20"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M21 17a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2h5l2 3h9a2 2 0 012 2v11z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)

export default FolderSvg;