import api from ".";

interface TrainingParams {
  skip: number;
  limit: number;
  is_active: boolean;
  with_incomplete_total: boolean;
  user_id: string;
}

interface AnnouncementParams {
  announcement: number;
  created_by: boolean;
}

interface UpdateAnnouncementParams {
  id: number;
  announcement: number;
  user_id: string;
}

export const getAnnouncements = (params: TrainingParams) =>
  api.get("/announcements", { params }).then((res) => {
    return res;
  });

export const postAnnouncement = (params: AnnouncementParams) =>
  api.post(`/announcements`, params).then((res) => {
    return res;
  });

export const updateAnnouncement = (params: UpdateAnnouncementParams) =>
  api.put(`/announcements/by-id/${params.id}`, params).then((res) => {
    return res;
  });

export const archiveAnnouncement = (params: { id: string }) =>
  api.post(`/announcements/archive`, params).then((res) => {
    return res;
  });
