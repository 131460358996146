const RepeatSvg = ({
  width = 17,
  height = 16,
  fill = '#fff',
  ...props
}) => (
  <div style={{width, height}}>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      {...props}
    >
      <path
        d="M1.15 3.001v3.9h3.9M15.448 13.4V9.5h-3.9"
        stroke={fill}
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.204 6.468a.65.65 0 001.226-.434l-1.226.434zm-9.038-2.4l.445.473a.652.652 0 00.015-.014l-.46-.46zm-3.46 2.36a.65.65 0 10.89.947l-.89-.948zm15.188 3.546a.65.65 0 00-.89-.947l.89.947zm-3.461 2.36l-.445-.473a.393.393 0 00-.015.014l.46.46zm-9.039-2.4a.65.65 0 10-1.225.433l1.225-.433zm11.036-3.9a6.5 6.5 0 00-4.693-4.17L9.45 3.13a5.2 5.2 0 013.755 3.337l1.225-.434zm-4.693-4.17a6.5 6.5 0 00-6.03 1.744l.919.919A5.2 5.2 0 019.45 3.13l.287-1.268zm-6.016 1.73L.705 6.427l.89.948L4.611 4.54l-.89-.947zm11.283 5.433l-3.016 2.834.89.947 3.016-2.834-.89-.947zm-3.03 2.848a5.2 5.2 0 01-4.826 1.395l-.286 1.268a6.499 6.499 0 006.03-1.744l-.919-.92zM7.147 13.27a5.2 5.2 0 01-3.754-3.336l-1.225.433a6.499 6.499 0 004.693 4.171l.286-1.268z"
        fill={fill}
      />
    </svg>
  </div>
)

export default RepeatSvg;