import { saveUser } from "redux/slices/auth";
import api from ".";
import store from "../redux/store";
import socket from "../socket";

interface LoginParams {
  email: string;
  password: string;
  callback: () => void;
}

interface ProfileProps {
  currentPassword?: string;
  newPassword?: string;
  photoUri?: string;
}

const { dispatch } = store;

export const signinUser = (params: LoginParams) =>
  api.post("/users/login", params).then((res) => {
    localStorage.setItem("accessToken", res.data.token);
    localStorage.setItem("userId", res.data.user.id);
    dispatch(saveUser(res.data.user));

    socket.emit("register", res.data.user.id);

    params.callback();
    return res;
  });

export const forgotPassowrd = (params: { email: string }) =>
  api.post("/users/forgot-password", params).then((res) => {
    return res;
  });

export const signoutUser = () =>
  api.get("/users/logout").then((res) => {
    localStorage.removeItem("accessToken");
    return res;
  });

export const updateProfile = (params: ProfileProps) =>
  api.patch("/users/me", params).then((res) => {
    dispatch(saveUser(res.data));
    return res;
  });

export const signUpInvitation = (data: { token: string; userEmail: string; password: string; photoUri: string }) => {
  return api.post(`/users/sign-up/invitation`, data).then((res) => {
    return res;
  });
}
