import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  color: ${({ theme }) => theme.palette.subtitle};
`;
