import styled from "styled-components";

export const Button = styled.button<{
  disabled?: boolean;
  secondary?: boolean;
}>`
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette.buttonDisabled
      : props.secondary
      ? props.theme.palette.secondaryButton
      : props.theme.palette.button};
  height: 36px;
  width: 100%;
  min-width: 36px;
  border-radius: 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
  border: none;
  align-items: center;
  position: relative;
`;

export const Text = styled.div`
  color: ${({theme}) => theme.palette.white};
  font-size: 14px;
  font-family: ${({theme}) => theme.typography.semiBold.inter};
  font-weight: 600;
  letter-spacing: 1.25px;
`;

export const Icon = styled.img`
  top: 10px;
  left: 12px;
  position: absolute;
`;
