import { Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CloseDiv,
  Column,
  Form,
  Text,
  ModalBackground,
  ModalContainer,
  ModalTopContainter,
  Row,
  Image,
  Li,
  ToggleButton,
  Icon,
  FileInput,
  ImageContainer,
} from "./styles";
import CloseSvg from "assets/svgs/CloseSvg";
import { Button, Input } from "components";
import MoonSvg from "assets/svgs/MoonSvg";
import { RootState } from "redux/reducers";
import { setTheme } from "redux/slices/app";
import SunSvg from "assets/svgs/SunSvg";
import { CheckDarkIcon, CheckIcon, UploadPhotoIcon } from "assets";
import RepeatSvg from "assets/svgs/RepeatSvg";
import { checkPassword, uploadFile } from "utils";
import ImageCropperModalView from "components/ImageCropperModalView";
import ErrorSvg from "assets/svgs/ErrorSvg";

interface ProfileParams {
  currentPassword: string;
  newPassword: string;
  photoUri: string;
  error: any;
}

const ProfileModalView = ({ getInitialState, onSubmit, onClose, error }) => {
  const initialState = getInitialState();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const { app } = useSelector((state: RootState) => state.app);
  const [profile, setProfile] = useState<ProfileParams>(initialState);
  const [photoUri, setPhotoUri] = useState<string | ArrayBuffer>("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (photoUri) setModalVisible(true);
  }, [photoUri]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(profile);
  };

  const onFileChange = (e) => uploadFile(e, fileInputRef, setPhotoUri);

  const onContinue = (uri) => {
    setModalVisible(false);
    setProfile({ ...profile, photoUri: uri });
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalTopContainter>
          <h2>{t("profile-screen.title")}</h2>
          <CloseDiv onClick={onClose}>
            <CloseSvg fill={app.darkMode ? undefined : "#222"} />
          </CloseDiv>
        </ModalTopContainter>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Column photo>
              <ImageContainer>
                <FileInput
                  ref={fileInputRef}
                  type='file'
                  accept='image/*'
                  onChange={onFileChange}
                />
                {profile.photoUri ? (
                  <Image src={profile.photoUri} />
                ) : (
                  <Image avatar src={UploadPhotoIcon} />
                )}
              </ImageContainer>
              <Text>{t("profile-screen.input.photo")}</Text>
            </Column>
            <Column>
              <Input
                value={profile.currentPassword}
                type='password'
                label={t("profile-screen.input.current")}
                handleChange={(e) =>
                  setProfile({ ...profile, currentPassword: e.target.value })
                }
                placeholder={`${t("profile-screen.input.current")}...`}
              />
              <Input
                value={profile.newPassword}
                type='password'
                label={t("profile-screen.input.new")}
                handleChange={(e) =>
                  setProfile({ ...profile, newPassword: e.target.value })
                }
                placeholder={`${t("profile-screen.input.new")}...`}
              />
              <Text>{t("profile-screen.description")}</Text>
              <ul className='list'>
                <Li>
                  {checkPassword(profile.newPassword).charsLengthPassed && (
                    <Icon src={app.darkMode ? CheckIcon : CheckDarkIcon} />
                  )}
                  {t("profile-screen.verify.characters")}
                </Li>
                <Li>
                  {checkPassword(profile.newPassword).hasNumber && (
                    <Icon src={app.darkMode ? CheckIcon : CheckDarkIcon} />
                  )}
                  {t("profile-screen.verify.number")}
                </Li>
                <Li>
                  {checkPassword(profile.newPassword).hasSpecialChars && (
                    <Icon src={app.darkMode ? CheckIcon : CheckDarkIcon} />
                  )}
                  {t("profile-screen.verify.special")}
                </Li>
              </ul>
            </Column>
          </Row>
          <Row container>
            <Text>{t("profile-screen.theme")}</Text>
            <ToggleButton
              onClick={() => dispatch(setTheme(true))}
              opacity={app.darkMode ? 1 : 0.5}
            >
              <MoonSvg fill={app.darkMode ? undefined : "#222"} />
              &nbsp;&nbsp;Dark
            </ToggleButton>
            <ToggleButton
              onClick={() => dispatch(setTheme(false))}
              opacity={app.darkMode ? 0.5 : 1}
            >
              <SunSvg />
              &nbsp;&nbsp;Light
            </ToggleButton>
          </Row>
          <Button
            disabled={profile.newPassword && !checkPassword(profile.newPassword).verified}
            width='300px'
            error={!!error}
            icon={() =>
              error ? (
                <ErrorSvg fill={app.darkMode ? undefined : "#222"} />
              ) : (
                <RepeatSvg
                  fill={
                    !checkPassword(profile.newPassword).verified ? undefined : "#222222"
                  }
                  className={undefined}
                />
              )
            }
            onClick={handleSubmit}
            label={
              error ? t("profile-screen.error") : t("profile-screen.button")
            }
          />
        </Form>
      </ModalContainer>
      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <ImageCropperModalView
          onContinue={onContinue}
          darkMode={app.darkMode}
          onClose={() => setModalVisible(false)}
          src={photoUri}
        />
      </Modal>
    </ModalBackground>
  );
};

export default ProfileModalView;
