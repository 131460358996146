import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Body, CustomerName, Header, LogoZone, Placeholder, RemoveButton, Title, Wrapper, Text } from './styles';
import { Input } from 'components';
import FolderSvg from 'assets/svgs/FolderSvg';
import CloseThinSvg from 'assets/svgs/CloseThinSvg';
import Dropzone from 'components/common/dropzone';
import Spinner from 'components/common/spinner';
import { capitalize } from 'utils';

interface AdminProps {
  formData: any;
  isLoading: boolean;
  onChange: ({ name, value }: { name: string, value: string }) => void;
}


const AdminControlDetails = ({ formData, onChange, isLoading }: AdminProps) => {
  const [fileState, setFileState] = useState({
    dark: {},
    light: {}
  });
  const { t } = useTranslation();

  const handleFileChange = (type: string, data: any) => {
    setFileState(prev => ({
      ...prev,
      [type]: { url: data.uri }
    }));
    onChange({ name: `${type}_logo_url`, value: data.uri });
  };

  const handleRemoveFile = (e: any, type: string) => {
    e.stopPropagation();
    setFileState(prev => ({
      ...prev,
      [type]: {}
    }));
    onChange({ name: `${type}_logo_url`, value: null });
  };

  return (
    <Wrapper>
      <Header>{t("admin-controls-details-screen.title")}</Header>
      <Body>
        {['dark', 'light'].map(type => (
          <div key={type}>
            <Title>{`${capitalize(type)} ${t("admin-controls-details-screen.subtitle")}`}</Title>
            <Dropzone name={type} onChange={handleFileChange}>
              <LogoZone darkMode={type === 'dark'}>
                {isLoading ? (
                  <Spinner size={40} color={type === 'dark' ? "#ffffff" : "#222222"} loading={true} />
                ) : fileState[type].url || formData[`${type}_logo_url`] ? (
                  <img src={fileState[type].url || formData[`${type}_logo_url`]} alt="logo" />
                ) : (
                  <Placeholder darkMode={type === 'dark'}>
                    <FolderSvg stroke={type === 'dark' ? '#fff' : '#222'} />
                    <Text>{t("admin-controls-details-screen.drop")}</Text>
                  </Placeholder>
                )}
                {!!(fileState[type].url || formData[`${type}_logo_url`]) && (
                  <RemoveButton onClick={(e: any) => handleRemoveFile(e, type)}>
                    <CloseThinSvg fill="#ff0000" />
                  </RemoveButton>
                )}
              </LogoZone>
            </Dropzone>
          </div>
        ))}
        <CustomerName>
          <Input
            label={t("admin-controls-details-screen.input.label")}
            placeholder={t("admin-controls-details-screen.input.label")}
            value={formData.name}
            handleChange={(e) => onChange({ name: 'name', value: e.target.value })}
          />
        </CustomerName>
      </Body>
    </Wrapper>
  );
};

export default AdminControlDetails;
