import styled from 'styled-components';

export const DropzoneWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: italic;
  margin-bottom: 20px;
`;

export const HiddenInput = styled.input`
  display: none;
`;