/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "redux/reducers";
import Modal from '@mui/material/Modal';

import { Button, Title } from "components";
import MainLayout from "layouts/main";
import { Section } from "./styles";
import { useApi } from "hooks/useApi";
import { useEffect, useState } from "react";
import { AnnouncementItem } from "components/announcementItem";
import { getPermissions, userRoleType } from "utils";
import { archiveAnnouncement, getAnnouncements, postAnnouncement, updateAnnouncement } from "api/announcements";
import AnnouncementModalView from "components/announcementModalView";

const Announcements = () => {
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(null);
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const hasPermission = getPermissions([
    userRoleType.ADMINISTRATOR
  ], user.role)

  const {
    request: announcementsRequest,
    data,
  }: any = useApi({ apiFunc: getAnnouncements });
  const {
    request: performPost
  } = useApi({
    apiFunc: postAnnouncement, onSuccess: () => announcementsRequest({
      skip: 0,
      limit: 30,
      user_id: user.id,
    })
  });
  const {
    request: performUpdate
  } = useApi({
    apiFunc: updateAnnouncement, onSuccess: () => announcementsRequest({
      skip: 0,
      limit: 30,
      user_id: user.id,
    })
  });
  const {
    request: performArchive
  }: any = useApi({
    apiFunc: archiveAnnouncement, onSuccess: () => announcementsRequest({
      skip: 0,
      limit: 30,
      user_id: user.id,
    })
  });


  useEffect(() => {
    if (user.id !== 0) {
      announcementsRequest({
        skip: 0,
        limit: 30,
        user_id: user.id,
      });
    }
  }, [user]);

  const onSubmitHandler = async (formData) => {
    if (edit) performUpdate({
      ...formData,
      id: item?.id,
      user_id: item?.user_id
    })
    else performPost({
      ...formData,
      created_by: user?.id
    })
    setModalVisible(false);
  };

  const onEditClickHandler = item => () => {
    setEdit(true);
    setItem(item);
    setModalVisible(true);
  };

  return (
    <MainLayout>
      <Title title={t("announcements-screen.title")} actions={
        hasPermission && (
          <Button
            width="230px"
            onClick={() => {
              setEdit(false);
              setItem(null);
              setModalVisible(true);
            }}
            label={t("announcements-screen.button")} />
        )
      } />
      <Section>
        {data?.announcements?.map(item => {
          return (
            <AnnouncementItem
              key={item.id}
              item={item}
              onEditClick={onEditClickHandler(item)}
              onArchiveClick={()=> performArchive({ id: item.id })}
            />
          );
        })}
      </Section>
      {modalVisible && (
        <Modal open={modalVisible} onClose={() => setModalVisible(prev => !prev)}>
          <AnnouncementModalView
            edit={edit}
            getInitialState={() => ({
              announcement: item?.announcement || '',
            })}
            requestId={edit ? 'update_announcement' : 'post_announcement'}
            onSubmit={onSubmitHandler} onClose={() => setModalVisible(prev => !prev)} />
        </Modal>
      )}
    </MainLayout>
  );
};

export default Announcements;
