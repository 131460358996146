import styled from "styled-components";

export const Background = styled.div`
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px 0px;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.darkBlack};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
`;

export const ModalTopContainter = styled.div`
  height: 72px;
  min-height: 72px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0 0px 0 24px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.text};
  border-bottom: 1px solid ${({ theme }) => theme.palette.input};
`;

export const Text = styled.div`
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  color: ${({ theme }) => theme.palette.subtitle};
  margin-bottom: 20px;
`;
