import {
  DataTableContainer,
  DataTableHeader,
  DataTableRow,
  DataTableColumn,
} from "./styles";

const DataTable = ({
  labels = {},
  fields = [],
  rows = [],
  data,
  maxWidth,
  rowHeight = 40,
  headerHeight = 30,
  columnWidth,
  stripes = false,
  formatter,
  dimColumnIterator,
  onRowClick,
  errorMessageIterator,
}) => {
  return (
    <DataTableContainer>
      <DataTableHeader>
        {fields.map((key, index) => (
          <DataTableColumn
            key={index}
            dim={dimColumnIterator({
              key,
              width: columnWidth,
              height: headerHeight,
            })}
          >
            {labels[key]}
          </DataTableColumn>
        ))}
      </DataTableHeader>
      {rows.map((item, index) => {
        const error = errorMessageIterator && errorMessageIterator(item, index);
        return (
          <DataTableRow
            error={error}
            key={item.id}
            onClick={() => onRowClick && onRowClick(item)}
          >
            {fields.map((field, fi) => {
              const dim = dimColumnIterator({
                key: field,
                width: columnWidth,
                height: rowHeight,
              });
              const value = formatter
                ? formatter(item[field], field, item)
                : item[field];
              return (
                <DataTableColumn key={fi} dim={dim}>
                  {value}
                </DataTableColumn>
              );
            })}
          </DataTableRow>
        );
      })}
    </DataTableContainer>
  );
};

export default DataTable;
