import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import auth from "redux/slices/auth";
import app from "redux/slices/app";
import users from "./slices/users";
import invitations from "./slices/invitations";
import company from "./slices/company";
import chat from "./slices/chat";
import notifications from "./slices/notifications";

const persistConfig = {
  key: "root-admin",
  storage,
};

const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth,
    app,
    users,
    invitations,
    company,
    chat,
    notifications,
  })
);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
