import styled from "styled-components";

export const Image = styled.img`
    max-height: 300px;
`;

export const Background = styled.div`
    background-color: ${({ theme }) => theme.palette.primary};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 20px 0px;
`;

export const Contact = styled.div`
    color: ${({ theme }) => theme.palette.subtitle};
    text-decoration: underline;
    font-weight: 400;
    font-size: 14px;
    font-family: ${({ theme }) => theme.typography.normal.inter};
    cursor: pointer;
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Section = styled.div`
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
`;
