import styled from "styled-components";
import { Menu, MenuItem } from "@mui/material";

export const MenuStyled = styled(Menu)`
  .MuiPaper-root {
    max-height: 80vh;
    overflow-y: auto;
    width: 342px;
    background-color: ${({ theme }) => theme.palette.primaryHighlight};
    margin-top: 13px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.buttonDisabled};
      border-radius: 4px;
      border: 2px solid transparent;
    }
  }
`;

export const MenuTitle = styled.div`
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.semiBold};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.text};
`;

export const MenuSmallText = styled.button`
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.normal};
  color: ${({ theme }) => theme.palette.text};
  border: none;
  background: none;
  transition: opacity 300ms;
  user-select: none;
  cursor: pointer;
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border};
  padding: 16px;
`;

export const MenuItemStyled = styled(MenuItem)`
  &.MuiButtonBase-root {
    color: ${({ theme }) => theme.palette.text};
    font-family: ${({ theme }) => theme.typography.normal};
    font-size: 16px;
    width: 342px;
    height: 120px;
    white-space: normal;
    border-bottom: 1px solid ${({ theme }) => theme.palette.border};
  }
`;

export const Text = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.normal};
  color: ${({ theme }) => theme.palette.text};
  padding: 16px;
`;
