import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";

import { Container } from "./styles";
import MainLayout from "layouts/main";
import { Title } from "components";
import { useEffect, useState } from "react";
import BoardModalView from "components/modalViews/boardModalView";
import KanbanBoard from "components/kanbanBoard";
import { createBoard, getBoard } from "api/boards";
import { useApi } from "hooks/useApi";

const KanbanPage = () => {
  const { t } = useTranslation();
  let { id } = useParams<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const { request, data, loading }: any = useApi({ apiFunc: getBoard });
  const { request: postBoard }: any = useApi({
    apiFunc: createBoard,
    onSuccess: () => {
      setModalVisible(false);
      request();
    },
  });

  useEffect(() => {
    request({ id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <MainLayout>
      {!loading && (
        <Title
          title={`${t("kanban-screen.title")}: ${data?.name || '-'}`}
        />
      )}
      {!loading && data ? (
        <KanbanBoard board={data} request={request} />
      ) : (
        !loading && <Container>{t("kanban-screen.subtitle")}</Container>
      )}
      {modalVisible && (
        <Modal
          open={modalVisible}
          onClose={() => setModalVisible((prev) => !prev)}
        >
          <BoardModalView
            onSubmit={(name) => postBoard({ name })}
            onClose={() => setModalVisible(false)}
          />
        </Modal>
      )}
    </MainLayout>
  );
};
export default KanbanPage;
