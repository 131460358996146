/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Modal } from "@mui/material";

import MainLayout from "layouts/main";
import { RootState } from "../../redux/reducers";
import {
    EXPIRY_SECOND,
    formatDate,
    permissions,
    stripToFormatterWord,
    toTitleCase,
    userRoleType,
} from "../../utils";
import { useApi } from "../../hooks/useApi";
import { deleteProfile, editUserProfile, getProfiles } from "../../api/profile";
import {
    cancelUserInvitation,
    getInvitations,
    postUserInvitation,
    resendUserInvitation,
} from "../../api/invitations";
import PageTitle from "../../components/pageTitle";
import DataTable from "components/common/dataTable";
import { Avatar } from "components/Avatar";
import { Button, Title } from "components";
import { Section } from "./styles";
import repeatSvg from "../../assets/repeat.svg";
import UserModalView from "components/userModalView";

const UserManagment = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const [editingUser, setEditingUser] = useState({ user: null, action: "" });
    const [modalVisible, setModalVisible] = useState(false);
    const userManagmentPermissions = permissions.level2;
    const { t } = useTranslation();
    const hasPermission = useMemo(() => {
        return (
            !userManagmentPermissions ||
            !userManagmentPermissions.length ||
            userManagmentPermissions.includes(user.role as userRoleType)
        );
    }, [userManagmentPermissions, user]);

    const { request: getProfilesApiCall } = useApi({
        apiFunc: getProfiles,
    });
    const { request: getInvitationsApiCall } = useApi({
        apiFunc: getInvitations,
    });

    const {
        request: resendUserInvitationRequest,
        isSuccess: isResentSuccess,
        clear: clearResent,
    } = useApi({
        apiFunc: resendUserInvitation,
        onSuccess: () => hasPermission && getInvitationsApiCall(),
    });
    const {
        request: removeInvitation,
        isSuccess: isCancelSuccess,
        clear: clearCancel,
    } = useApi({
        apiFunc: cancelUserInvitation,
        onSuccess: () => hasPermission && getInvitationsApiCall(),
    });
    const {
        request: removeProfile,
        isSuccess: isDeleteSuccess,
        clear: clearDelete,
    } = useApi({
        apiFunc: deleteProfile,
        onSuccess: () => hasPermission && getProfilesApiCall(0),
    });
    const {
        request: changeUserProfile,
        isSuccess: isEditSuccess,
        clear: clearEdit,
    } = useApi({
        apiFunc: editUserProfile,
        onSuccess: () => hasPermission && getProfilesApiCall(0),
    });
    const {
        request: inviteUser,
        isSuccess: isInviteSuccess,
        clear,
        error
    } = useApi({
        apiFunc: postUserInvitation,
        onSuccess: () => {
            if (hasPermission) {
                getInvitationsApiCall();
                getProfilesApiCall(0);
            }
        },
    });
    const { users } = useSelector((state: RootState) => state.users);
    const { invitations } = useSelector(
        (state: RootState) => state.invitations
    );

    const activeUsers = useMemo(() => {
        return users.filter((user) => user.invitation_status === "verified");
    }, [users]);

    const pendingInvitations = useMemo(() => {
        return (
            invitations?.filter(
                (invitation) => invitation.status === "pending"
            ) || []
        );
    }, [invitations]);

    const handleRemove = ({ isPending, item }) => {
        if (isPending) removeInvitation({ id: item.id, user_id: item.user_id });
        else removeProfile({ id: item.user_id });
    };

    const onSubmit = ({ isEditing, isPending, item, data }) => {
        if (isEditing && isPending)
            resendUserInvitationRequest({
                id: item.id,
                user_id: item.user_id,
            });
        else if (isEditing)
            changeUserProfile({
                ...data,
                user_id: item.user_id,
            });
        else
            inviteUser({
                ...data,
                invited_by: user.id,
            });
    };

    const onClose = () => {
        setEditingUser({ user: null, action: "" });
        clearEdit();
        clear();
        clearResent();
        clearDelete();
        clearCancel();
        setModalVisible((prev) => !prev);
    };

    useEffect(() => {
        if (hasPermission) {
            getProfilesApiCall(0);
            getInvitationsApiCall();
        }
    }, []);

    return (
        <MainLayout>
            <Title
                title={t("user-management-screen.title")}
                actions={
                    hasPermission && (
                        <Button
                            width="230px"
                            onClick={() => {
                                setEditingUser({
                                    user: null,
                                    action: "create",
                                });
                                setModalVisible(true);
                            }}
                            label={t("user-management-screen.button")}
                        />
                    )
                }
            />
            {pendingInvitations?.length && (
                <Section>
                    <PageTitle
                        title={t("user-management-screen.subtitle.pending")}
                        active={false}
                    />
                    <DataTable
                        columnWidth={null}
                        labels={{
                            profile_image: "",
                            name: t("user-management-screen.item.title.name"),
                            user_email: t(
                                "user-management-screen.item.title.email"
                            ),
                            role: t("user-management-screen.item.title.role"),
                            invited_by: t(
                                "user-management-screen.item.title.invited_by"
                            ),
                            invited_on: t(
                                "user-management-screen.item.title.invited"
                            ),
                        }}
                        rowHeight={65}
                        headerHeight={65}
                        rows={pendingInvitations}
                        data={invitations}
                        errorMessageIterator={(data) =>
                            dayjs().diff(dayjs(data.updated_on), "second") >=
                            EXPIRY_SECOND
                        }
                        fields={[
                            "profile_image",
                            "name",
                            "user_email",
                            "role",
                            "invited_by",
                            "invited_on",
                        ]}
                        formatter={(value, key, data) => {
                            const user = users.find(
                                (u) => u.id === data.user_id
                            );
                            const isExpired =
                                dayjs().diff(
                                    dayjs(data.updated_on),
                                    "second"
                                ) >= EXPIRY_SECOND;
                            if (key === "profile_image")
                                return (
                                    <Avatar
                                        width={35}
                                        height={35}
                                        radius={35}
                                        src={user?.profile_image}
                                    />
                                );
                            if (key === "name")
                                return `${user?.first_name || ""} ${
                                    user?.last_name || ""
                                }`.trim();
                            else if (key === "user_email")
                                return user?.user_email;
                            else if (key === "role")
                                return toTitleCase(
                                    stripToFormatterWord(user?.role)
                                );
                            else if (key === "invited_by") {
                                const inviter = users.find(
                                    (u) => u.id === data.invited_by
                                );
                                return isExpired ? (
                                    <Button
                                        width="180px"
                                        icon={() => (
                                            <img
                                                src={repeatSvg}
                                                alt="repeat"
                                                width={14}
                                                height={14}
                                            />
                                        )}
                                        onClick={() =>
                                            resendUserInvitationRequest({
                                                id: data.id,
                                                user_id: data.user_id,
                                            })
                                        }
                                        label={t(
                                            "user-management-screen.resend-button"
                                        )}
                                    />
                                ) : (
                                    `${inviter?.first_name || ""} ${
                                        inviter?.last_name || "-"
                                    }`.trim()
                                );
                            } else if (key === "invited_on")
                                return isExpired ? (
                                    <span style={{ color: "rgb(255, 0, 0)" }}>
                                        Expired
                                    </span>
                                ) : (
                                    formatDate(data.updated_on)
                                );

                            return value;
                        }}
                        dimColumnIterator={(dim) => {
                            if (dim.key === "profile_image") dim.width = "76px";
                            else if (dim.key === "name") dim.width = "21%";
                            else if (dim.key === "invited_on")
                                dim.width = "150px";
                            else if (dim.key === "invited_by")
                                dim.width = "250px";
                            else if (dim.key === "role") dim.width = "200px";
                            else dim.flex = true;
                            return dim;
                        }}
                        onRowClick={(item) => {
                            setEditingUser({
                                user: {
                                    ...item.user,
                                    id: item.id,
                                    user_id: item.user_id,
                                },
                                action: "edit_pending_user",
                            });
                            setModalVisible(true);
                        }}
                        maxWidth={undefined}
                    />
                </Section>
            )}
            <Section>
                <PageTitle
                    title={t("user-management-screen.subtitle.active")}
                    active
                />
                <DataTable
                    columnWidth={null}
                    labels={{
                        profile_image: "",
                        name: t("user-management-screen.item.title.name"),
                        user_email: t(
                            "user-management-screen.item.title.email"
                        ),
                        role: t("user-management-screen.item.title.role"),
                        invited_by: t(
                            "user-management-screen.item.title.invited_by"
                        ),
                        joined_on: t(
                            "user-management-screen.item.title.joined"
                        ),
                    }}
                    rowHeight={65}
                    headerHeight={65}
                    rows={activeUsers}
                    data={users}
                    fields={[
                        "profile_image",
                        "name",
                        "user_email",
                        "role",
                        "invited_by",
                        "joined_on",
                    ]}
                    formatter={(value, key, data) => {
                        if (key === "profile_image")
                            return <Avatar
                                width={35}
                                height={35}
                                radius={35}
                                src={data?.profile_image}
                            />
                        else if (key === "name")
                            return `${data.first_name || ""} ${
                                data.last_name || ""
                            }`.trim();
                        else if (key === "role")
                            return toTitleCase(stripToFormatterWord(value));
                        else if (key === "invited_by") {
                            const inviter = users.find(
                                (u) => u.id === data.invited_by
                            );
                            return `${inviter?.first_name || ""} ${
                                inviter?.last_name || ""
                            }`.trim();
                        } else if (key === "joined_on") {
                            if (data.joined_at)
                                return formatDate(data.joined_at);
                            else return formatDate(data.created_at);
                        }
                        return value;
                    }}
                    dimColumnIterator={(dim) => {
                        if (dim.key === "profile_image") dim.width = "76px";
                        else if (dim.key === "name") dim.width = "21%";
                        else if (dim.key === "joined_on") dim.width = "150px";
                        else if (dim.key === "invited_by") dim.width = "250px";
                        else if (dim.key === "role") dim.width = "200px";
                        else dim.flex = true;
                        return dim;
                    }}
                    onRowClick={(item) => {
                        setEditingUser({
                            user: { ...item, user_id: item.id },
                            action: "edit_user",
                        });
                        setModalVisible(true);
                    }}
                    maxWidth={undefined}
                    errorMessageIterator={undefined}
                />
            </Section>
            {modalVisible && (
                <Modal open={modalVisible} onClose={onClose}>
                    <UserModalView
                        getInitialState={() => ({
                            user: {
                                id: editingUser?.user?.id || null,
                                firstName: editingUser?.user?.first_name || "",
                                lastName: editingUser?.user?.last_name || "",
                                userEmail: editingUser?.user?.user_email || "",
                                role: editingUser?.user?.role || "",
                            },
                        })}
                        onSubmit={(userData) =>
                            onSubmit({
                                isEditing: !!editingUser.user,
                                isPending: editingUser.action !== "edit_user",
                                item: editingUser.user,
                                data: userData.user,
                            })
                        }
                        error={error}
                        onClose={onClose}
                        edit={!!editingUser.user}
                        action={editingUser.action}
                        updateSuccess={isEditSuccess}
                        resentSuccess={isResentSuccess}
                        createSuccess={isInviteSuccess}
                        cancelSuccess={isDeleteSuccess || isCancelSuccess}
                        handleRemove={() =>
                            handleRemove({
                                isPending: editingUser.action !== "edit_user",
                                item: editingUser.user,
                            })
                        }
                    />
                </Modal>
            )}
        </MainLayout>
    );
};
export default UserManagment;
