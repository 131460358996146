import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme }) => theme.palette.text};
  background: ${({ theme }) => theme.palette.menu};
  height: 50px;
  display: flex;
  font-size: 24px;
  padding: 0 23px;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  cursor: pointer;
`;

export const Button = styled.div`
  height: 24px;
  width: 28px;
  background: ${({ theme }) => theme.palette.primarySoft};
  border-radius: 3px;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const List = styled.div`
  margin: 0;
  list-style: none;
  position: relative;
`;

export const ItemContainer = styled.div`
  position: relative;
  padding: 0 8px 0 25px;
`;

export const Item = styled.a`
  height: 50px;
  display: flex;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 600;
  padding: 0 13px;
  margin: 0 0 9px 0;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.text};
  background: ${({ theme }) => theme.palette.primarySoft};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.palette.primarySoftHover};
  }
`;

export const Marker = styled.div`
  top: 0;
  bottom: 0;
  width: 4px;
  content: "";
  height: 50px;
  left: 0px;
  position: absolute;
  background: ${({ theme }) => theme.palette.button};
`;
