import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.darkBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalTopContainter = styled.div`
  height: 72px;
  min-height: 72px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0 0px 0 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.input};
  color: ${({ theme }) => theme.palette.text};
`;

export const CloseDiv = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.input};
  height: 100%;
  align-items: center;
  justify-content: end;
  align-content: center;
  display: flex;
  width: 80px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 6px ${({ theme }) => theme.palette.darkBlack};
  width: 70%;
  max-width: 800px;
  color: white;
  background: ${({ theme }) => theme.palette.primaryHighlight};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
`;
