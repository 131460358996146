import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.subtitle};
`;

export const SText = styled.div<{ big?: boolean; }>`
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.semiBold.inter};
  padding-left: ${({ big }) => (big ? "0px" : "16px")};
`;

export const Role = styled.div`
  font-size: 12px;
  padding: 1px 0 0 8px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.subtitle};
`;

export const CardsContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

export const Wrapper = styled.div<{ active?: boolean; archived?: boolean }>`
  display: flex;
  flex-direction: ${({ active }) => (active ? "row" : "column")};
  justify-content: space-between;
  border-radius: 7px;
  background: ${({ theme }) => theme.palette.primaryHighlight};
  padding: 24px;
  margin-bottom: 8px;
  width: 100%;
  box-shadow: ${({ active, archived, theme }) =>
    active && !archived
      ? `0 0 0 2px ${theme.palette.secondaryButton}`
      : "none"};
  color: ${({ active, theme }) =>
    active ? theme.palette.subtitle : theme.palette.text};
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const Circle = styled.div<{ isActive?: boolean }>`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.palette.secondaryButton : "transparent"};
  border: ${({ isActive, theme }) =>
    isActive ? "none" : `1px solid ${theme.palette.border}`};
  height: 16px;
  width: 16px;
  margin-right: 8px;
  border-radius: 25px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.palette.text};
  font-size: 20px;
`;

export const Row = styled.div<{
  start?: boolean;
  name?: boolean;
  wrap?: boolean;
}>`
  display: flex;
  justify-content: ${({ start }) => (start ? "start" : "flex-end")};
  align-items: center;
  margin: ${({ name }) => (name ? "0px" : "16px 32px 16px 0px")};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "unset")};
`;

export const Date = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.subtitle};
  margin-top: 2px;
  margin-left: 16px;
`;

export const Amount = styled.span<{ white?: boolean }>`
  font-size: 44px;
  color: ${({ white, theme }) =>
    white ? theme.palette.text : theme.palette.subtitle};
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
`;
