const PlusSvg = ({
  width = 15,
  height = 15,
  fillOpacity = 0.8,
  fill = '#fff',
  className = undefined,
  ...props
}) => (
  <div style={{ width, height }}>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.477 8.61h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  </div>
)

export default PlusSvg;