import { Redirect, Route } from "react-router-dom";
import { ROUTES } from "utils/routes";

export const PublicRoute = ({
  path,
  element: Element,
}: {
  path: string;
  element: () => JSX.Element;
}) => {
    const accessToken = localStorage.getItem("accessToken");
  if (accessToken) return <Redirect to={ROUTES.dashboard} />;

  return (
    <Route path={path}>
      <Element />
    </Route>
  );
};
