/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "./auth";

export interface UserInvitation {
    id: string;
    user_id: string;
    invited_by: string;
    user_email: string;
    created_at: string;
    updated_at: string;
    status: string;
    jwt_token: string;
    user: User;
}

export interface UsersSliceState {
    invitations: UserInvitation[];
}

const authInitialState: UsersSliceState = {
    invitations: [],
};

const invitations = createSlice({
    name: "invitations",
    initialState: authInitialState,
    reducers: {
        addUsersInvitations(
            state,
            { payload }: PayloadAction<UserInvitation[]>
        ) {
            state.invitations = payload;
        },
    },
});

export const { addUsersInvitations } = invitations.actions;

export default invitations.reducer;
