import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Background, Container, Subtitle, Title } from "./styles";
import { Button } from "components";
import { ROUTES } from "utils/routes";

const NotFoundPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Background>
      <Container>404</Container>
      <Container>
        <Title>{t("not-found-screen.title")}</Title>
        <Subtitle>{t("not-found-screen.subtitle")}</Subtitle>
        <Button
          label={t("not-found-screen.button")}
          width='200px'
          onClick={() => history.push(ROUTES.dashboard)}
        />
      </Container>
    </Background>
  );
};
export default NotFoundPage;
