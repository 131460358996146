import React from "react";
import { IconButton } from "@mui/material";

import { ArrowDownButton, ArrowUpButton } from "./styles";

interface ChatScrollButtonsProps {
    isAtTop: boolean;
    isAtBottom: boolean;
    isOverflowing: boolean;
    scrollToTop: () => void;
    scrollToBottom: () => void;
}

const ChatScrollButtons = ({
    isAtTop,
    isAtBottom,
    isOverflowing,
    scrollToTop,
    scrollToBottom,
}: ChatScrollButtonsProps) => {
    return (
        <>
            {!isAtTop && isOverflowing && (
                <IconButton onClick={scrollToTop}>
                    <ArrowUpButton />
                </IconButton>
            )}
            {!isAtBottom && isOverflowing && (
                <IconButton onClick={scrollToBottom}>
                    <ArrowDownButton />
                </IconButton>
            )}
        </>
    );
};

export default ChatScrollButtons;
