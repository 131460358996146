import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  Body,
  Button,
  CopyRight,
  Drawer,
  FeedbackButton,
  Footer,
  Header,
  Icon,
  Link,
  Navigation,
  Signout,
} from "./styles";
import { handleDrawer } from "redux/slices/app";
import CloseSvg from "assets/svgs/CloseSvg";
import { Profile } from "./profile";
import { User } from "redux/slices/auth";
import SignOutSvg from "assets/svgs/SignOutSvg";
import { useApi } from "hooks/useApi";
import { signoutUser } from "api/auth";
import { ROUTES } from "utils/routes";
import { WhiteMailIcon } from "assets";
import { Accordion } from "./accordion";
import { NAV_ROUTES } from "utils";

export const Menu = ({
  drawerOpen,
  darkMode,
  user,
  handleEditProfile,
}: {
  drawerOpen: boolean;
  darkMode: boolean;
  user: User;
  handleEditProfile: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { request }: any = useApi({
    apiFunc: signoutUser,
    onSuccess: () => history.push(ROUTES.login),
  });
  const year = new Date().getFullYear();

  const handleClick = () => {
    const email = process.env.REACT_APP_CONTACT_EMAIL;
    const subject = "Mission Control User Feedback";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Drawer className={drawerOpen ? "open" : ""}>
      <Header>
        <Button onClick={() => dispatch(handleDrawer(false))}>
          <CloseSvg
            fill={darkMode ? undefined : "#222222"}
            style={{ transform: "scale(0.8125)" }}
          />
        </Button>
      </Header>
      <Body>
        <Profile user={user} handleEditProfile={handleEditProfile} />
        <Navigation>
          {NAV_ROUTES.map((route) => (
            <Accordion
              darkMode={darkMode}
              user={user}
              key={route.route}
              item={route}
            />
          ))}
        </Navigation>
        <Footer>
          <Signout onClick={request}>
            {t("menu.signout")}
            <SignOutSvg fill={darkMode ? undefined : "#222222"} />
          </Signout>
          <FeedbackButton onClick={handleClick}>
            <Icon src={WhiteMailIcon} />
            {t("menu.feedback")}
          </FeedbackButton>
          <CopyRight>
            <span>&#169;</span>&nbsp;&nbsp;{year}, All Rights
            Reserved&nbsp;|&nbsp;
            <Link target='_blank' href='https://www.apollo21.io/'>
              Apollo 21
            </Link>
          </CopyRight>
        </Footer>
      </Body>
    </Drawer>
  );
};
