import { useTranslation } from "react-i18next";

import { Container, Content, Image, Label, Link, Title } from "./styles";
import { UserIcon } from "assets";
import { User } from "redux/slices/auth";

export const Profile = ({
  user,
  handleEditProfile,
}: {
  user: User;
  handleEditProfile: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Image
        src={user.profile_image || UserIcon}
        avatar={!user.profile_image}
      />
      <Content>
        <Label>{user.role}</Label>
        <Title>
          {user.first_name} {user.last_name}
        </Title>
        <Link onClick={handleEditProfile}>{t("menu.edit")}</Link>
      </Content>
    </Container>
  );
};
